import { useToast } from '@chakra-ui/react';
import BranchesPage from '../../presentation/branches/BranchesPage';
import PaymentPlansPage from '../../presentation/payment_plans/PaymentPlansPage';
import DepartmentsPage from '../../presentation/settings/pages/departments/DepartmentsPage';
import RolesPage from '../../presentation/settings/pages/employee_roles/RolesPage';
import SubscriptionPage from '../../presentation/settings/pages/subscription/SubscriptionPage';
import WorkingHours from '../../presentation/settings/pages/working_hours/WorkingHours';
import EmploymentTypePage from '../../presentation/settings/pages/employment_type/EmploymentTypePage';

export const PF = process.env.REACT_APP_PUBLIC_FOLDER;
export const STREAM_API_KEY = process.env.REACT_APP_STREAM_API_KEY;
export const STREAM_APP_ID = process.env.REACT_APP_STREAM_APP_ID;
export const STREAM_API_SECRET = process.env.REACT_APP_STREAM_API_SECRET;

export const ToastUtil = ({ title, description, status }) => {
  const toast = useToast();
  toast({
    position: 'bottom-left',
    title: title,
    description: description,
    status: status,
    duration: 5000,
    isClosable: true,
  });
};

export const steps = [
  {
    selector: '.home-tour',
    content: 'Organisation reports will show up here',
  },
  {
    selector: '.rota-tour',
    content: 'You can create rotas and book shifts here',
  },
  {
    selector: '.records-tour',
    content: 'You can manage procedures and patient records here',
  },
  {
    selector: '.tasks-tour',
    content: 'View and manage your tasks',
  },
  {
    selector: '.chat-tour',
    content: 'Send messages and attachments with other organisation members',
  },
  {
    selector: '.timesheet-tour',
    content: 'View a summary of worked hours by your members',
  },
  {
    selector: '.members-tour',
    content: 'Invite and manage your members',
  },
  {
    selector: '.organisation-tour',
    content: 'View your registered organisations',
  },
  {
    selector: '.settings-tour',
    content:
      'Create or manage working hours, departments, branches and employee roles',
  },
];

export const settings = (organisation, roles, isPlanPaid, openPlans) => {
  return [
    {
      title: 'Working hours',
      description: 'Set up working hours',
      component: <WorkingHours />,
      show: roles?.[organisation?.id]?.includes('ShiftHours.list'),
    },
    {
      title: 'Departments',
      description: 'Manage your departments',
      component: <DepartmentsPage />,
      show: roles?.[organisation?.id]?.includes('Departments.list'),
    },
    {
      title: 'Branches',
      description: 'Organisation branches',
      component: <BranchesPage />,
      show: roles?.[organisation?.id]?.includes('Branches.list'),
    },
    {
      title: 'Employee roles',
      description: 'Create and manage roles and permissions',
      component: <RolesPage />,
      show:
        organisation?.organisation_type !== 'INDIVIDUAL' &&
        roles?.[organisation?.id]?.includes('Roles.list'),
    },
    {
      title: 'Employment type',
      description: 'Setup employee working hours and earnings',
      component: <EmploymentTypePage />,
      show: organisation?.id !== undefined || organisation?.id !== '',
      // organisation?.organisation_type !== 'INDIVIDUAL' &&
      // roles?.[organisation?.id]?.includes('EmploymentType.list'),
    },
    {
      title: 'Subscription',
      description: 'Manage your plans and payments',
      component:
        !isPlanPaid || openPlans ? <PaymentPlansPage /> : <SubscriptionPage />,
      show: true,
    },
  ];
};

export const employeeOrConsultant = ['EMPLOYEE', 'CONSULTANT'];

export const employmentType = ['PERMANENT', 'CONTRACT', 'LOCUM'];

export const paymentTypeList = ['CASH', 'INSURANCE'];

export const specialitiesList = [
  'General practitioner',
  'OBS & Gyna',
  'Psychiatric',
  'Surgical group',
  'Paramedic',
  'Dentist',
  'Pharmacist',
  'Physiotherapist',
  'Nutritionist',
  'Ambulance worker',
  'Optometrist',
  'Other',
];

export const personelList = [
  { name: 'Doctor', type: 'MEDICAL' },
  { name: 'Nurse', type: 'MEDICAL' },
  { name: 'Laboratory Staff', type: 'MEDICAL' },
  { name: 'Radiologist', type: 'MEDICAL' },
  { name: 'Physiotherapist', type: 'MEDICAL' },
  { name: 'Pharmacist', type: 'MEDICAL' },
  { name: 'Front office', type: 'NON_MEDICAL' },
  { name: 'Human resource', type: 'NON_MEDICAL' },
  { name: 'Finance', type: 'NON_MEDICAL' },
];

export const stringValPatternValidation = (stringVal) => {
  return /\s/g.test(stringVal);
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
