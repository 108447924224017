import { Box, Button, Flex, Spacer } from '@chakra-ui/react';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  registerPageOneDescriptionString,
  registerPageOneTitleString,
} from '../../application/services/app_strings';
import { useDispatch, useSelector } from 'react-redux';
import { addUserRegistration } from '../../redux/user/userRegistration';
import { ratibaLogoGreenIconSvgPath } from '../../application/services/asset_strings';
import {
  stringValPatternValidation,
  validateEmail,
} from '../../application/services/utils';
import InputField from '../core/components/input_field/InputField';
import './RegisterPage.scss';

const RegisterPageOne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { inviteObj, isInvited } = useSelector((state) => state.invite);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [otherName, setOtherName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState(isInvited ? inviteObj?.email : '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (firstName.trim().length < 3) {
      setError('Firstname should be at least 3 characters long.');
    } else if (lastName.trim().length < 3) {
      setError('Lastname should be at least 3 characters long.');
    } else if (!validateEmail(email)) {
      setError('Enter a valid email.');
    } else if (phoneNumber.length < 8 || phoneNumber.length > 12) {
      setError('Enter a valid phone number');
    } else if (
      password.trim().length < 6 ||
      confirmPassword.trim().length < 6
    ) {
      setError('Password should be at least 6 characters long.');
    } else if (
      stringValPatternValidation(password) ||
      stringValPatternValidation(confirmPassword)
    ) {
      setError('Password should not contain a white space.');
    } else if (password !== confirmPassword) {
      setError('Passwords do not match.');
    } else {
      setError('');

      const data = {
        firstName: firstName,
        lastName: lastName,
        // otherName: otherName,
        email: email,
        phoneNumber: phoneNumber,
        gender: gender,
        password: password,
      };

      dispatch(addUserRegistration(data));
      navigate('/register-docs');
    }
  };
  return (
    <div className='register-container'>
      <img alt='logo' src={ratibaLogoGreenIconSvgPath} />
      <div className='register-header'>
        <h2 className='title'>{registerPageOneTitleString}</h2>
        <p className='desc'>{registerPageOneDescriptionString}</p>
      </div>
      <div className='register-prog-indicator'>
        <div className='first active'></div>
        <div className='second'></div>
      </div>
      <form onSubmit={handleSubmit}>
        <Flex align='center'>
          <InputField
            mr={2}
            title='First name'
            isRequired={true}
            placeholder='Enter first name'
            type='text'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Box w={8}></Box>
          <InputField
            title='Last name'
            isRequired={true}
            placeholder='Enter last name'
            type='text'
            onChange={(e) => setLastName(e.target.value)}
          />
          {/* <InputField
            title='Other names'
            isRequired={true}
            placeholder='Enter other names'
            type='text'
            onChange={(e) => setOtherName(e.target.value)}
          /> */}
        </Flex>

        <Flex align='center'>
          {/* phone  */}
          <InputField
            inputStyle={{
              height: '45px',
              width: '100%',
              backgroundColor: 'transparent',
              borderColor: '#E2E8F0',
              fontFamily: 'Nunito',
              fontSize: '16px',
              borderRadius: 10,
            }}
            title='Phone number'
            isRequired={true}
            type='phone'
            country={'ke'}
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
          />
          <Box w={8}></Box>
          {/* gender */}
          <InputField
            title='Gender'
            isRequired={true}
            placeholder='Select gender'
            type='gender'
            selected={gender}
            onChange={(e) => setGender(e.target.value)}
          />
        </Flex>

        {/* email */}
        <InputField
          title='Personal email address'
          isRequired={true}
          placeholder='Enter email address'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <InputField
          title='Create password'
          isRequired={true}
          placeholder='Enter your password'
          type='password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputField
          title='Confirm password'
          isRequired={true}
          placeholder='Repeat password'
          type='password'
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <div style={{ color: 'red' }}>{error}</div>}

        <Flex align='center'>
          <p className='sign-in'>
            Got an account?{' '}
            <NavLink exact to='/' key='login' className='link-btn'>
              <span style={{ color: '#ef9920' }}>Sign in</span>
            </NavLink>
          </p>

          <Spacer />

          <Button mt={4} colorScheme='teal' type='submit'>
            Next
          </Button>
        </Flex>
      </form>
    </div>
  );
};

export default RegisterPageOne;
