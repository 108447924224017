import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { MdAdd, MdClose, MdDownload, MdRestore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import {
  acceptInviteUrl,
  branchMemberUrl,
  bulkInviteUrl,
  getBranchesUrl,
  getMembersUrl,
  memberLogsUrl,
  rolesUrl,
  sendInviteUrl,
  templateUrl,
  usersUrl,
} from '../../application/services/http_endpoints';
import useFetch, { handleError } from '../../application/services/http_service';
import { UsersMembers } from '../../dummydata';
import { resetOrg } from '../../redux/organisation/organisation';
import {
  setIsOrgRegOpen,
  setIsViewMap,
} from '../../redux/organisation/organisationRegistration';
import { resetRoles } from '../../redux/roles/roles';
import { resetUser, setLoggedIn } from '../../redux/user/user';
import FileSelector from '../core/components/file_sector/FileSelector';
import MemberRequestsTable from '../core/tables/MemberRequestsTable';
import UserEventsTable from '../core/tables/UserEventsTable';
import UsersTable from '../core/tables/UsersTable';
import './MembersPage.scss';

const MembersPage = () => {
  const [query, setQuery] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const activeSearchKeys = [
    'first_name',
    'last_name',
    'employee_role',
    'user_role',
    'email',
    'phone',
  ];

  const requestSearchKeys = [
    'first_name',
    'last_name',
    'employee_role',
    'status',
    'email',
    'phone',
  ];

  const search = (data, isActive = true) => {
    return isActive
      ? data.filter((item) =>
          activeSearchKeys.some((key) =>
            item[key].toLowerCase().includes(query.toLocaleLowerCase())
          )
        )
      : data.filter((item) =>
          requestSearchKeys.some((key) =>
            item[key].toLowerCase().includes(query.toLocaleLowerCase())
          )
        );
  };

  const toast = useToast();
  const { user, access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { decodedRoles, roles } = useSelector((state) => state.roles);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedMember, setSelectedMember] = useState({});
  const [loadingBranchMember, setLoadingBranchMember] = useState('');

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
    'organisation-id': organisation?.id ?? '',
  };
  const params = { org_id: organisation?.id ?? '' };

  // fetch members
  const {
    data: members,
    loading: loadingMembers,
    fetchDataByRole: fetchMembers,
  } = useFetch({
    url: getMembersUrl,
    params: params,
    headers: headers,
    role: 'OrganisationMembers.list',
    errMsg: 'Failed to fetch members.',
  });

  // fetch invited members
  const {
    data: membersInvited,
    loading: loadingInvitedMembers,
    fetchDataByRole: fetchInvitedMembers,
  } = useFetch({
    url: acceptInviteUrl,
    params: params,
    headers: headers,
    role: 'Invites.list',
    errMsg: 'Failed to fetch invited members.',
  });

  // fetch branches
  const { data: branches, fetchDataByRole: fetchBranches } = useFetch({
    url: getBranchesUrl,
    params: params,
    headers: headers,
    role: 'Branches.list',
    errMsg: 'Failed to fetch branches.',
  });

  // fetch roles
  const { data: rolesList, fetchDataByRole: fetchRoles } = useFetch({
    url: rolesUrl,
    params: params,
    headers: headers,
    role: 'Roles.list',
    errMsg: 'Failed to fetch roles.',
  });

  // fetch logs
  const {
    data: memberLogs,
    loading: loadingMemberLogs,
    fetchDataByRole: fetchMemberLogs,
  } = useFetch({
    url: memberLogsUrl,
    headers: headers,
    role: 'Events.list',
    errMsg: 'Failed to fetch member logs.',
  });

  // create branch member
  const createBranchMember = async (branch_id) => {
    setLoadingBranchMember(branch_id);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .post(
        branchMemberUrl,
        {
          branch_id: branch_id,
          org_member_id: selectedMember?.id,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoadingBranchMember('');
        onEditClose();
        fetchMembers();
        fetchBranches();
        toast({
          position: 'bottom-left',
          title: 'Branch member added successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoadingBranchMember('');
        handleError(
          err,
          'Failed to add branch member.',
          createBranchMember,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  // update member branch
  const updateMemberBranch = async (branch_id, is_active, branch_member_id) => {
    setLoadingBranchMember(branch_id);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .patch(
        `${branchMemberUrl}${branch_member_id}/`,
        {
          branch_id: branch_id,
          org_member_id: selectedMember?.id,
          is_active: !is_active,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoadingBranchMember('');
        onEditClose();
        fetchMembers();
        fetchBranches();
        toast({
          position: 'bottom-left',
          title: `Branch member ${
            is_active ? 'deactivated' : 'reactivated'
          } successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoadingBranchMember('');
        handleError(
          err,
          'Failed to update branch member.',
          updateMemberBranch,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const [loadingMemberRole, setLoadingMemberRole] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState('');

  // update member role
  async function updateMemberRole() {
    setLoadingMemberRole(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(
        `${getMembersUrl}${selectedMember?.id}/`,
        {
          role_id: selectedRoleId,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoadingMemberRole(false);
        setSelectedRoleId('');
        onEditClose();
        fetchMembers();
        fetchBranches();

        // log out user to access new features
        if (user.user.id === selectedMember?.user.id) {
          toast({
            position: 'bottom-left',
            title: 'Your member role has been updated successfuly.',
            description: 'Log in to access new features',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          dispatch(setLoggedIn(false));
          dispatch(resetUser());
          dispatch(resetOrg());
          dispatch(resetRoles());
          dispatch(setIsOrgRegOpen(false));
          dispatch(setIsViewMap(''));
          navigate('/');
        } else {
          toast({
            position: 'bottom-left',
            title: 'Member role updated successfuly.',
            description:
              'They have to log in again to access the updated features',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        setLoadingMemberRole(false);
        handleError(
          err,
          'Failed to update member role.',
          updateMemberRole,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  const [isSendingInvite, setIsSendingInvite] = useState('');

  // send member invite
  const sendMemberInvite = async (item) => {
    setIsSendingInvite(item.id);
    const email =
      item?.user_contacts?.[0]?.contact?.contact_type === 'EMAIL'
        ? item?.user_contacts?.[0]?.contact?.contact_value
        : item?.user_contacts?.[1]?.contact?.contact_value;
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .post(
        sendInviteUrl,
        {
          // user_id: item.id,
          email: email,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setIsSendingInvite('');
        setFilterMemberData([]);
        onClose();
        fetchMembers();
        fetchInvitedMembers();
        fetchBranches();
        toast({
          position: 'bottom-left',
          title: `${item.first_name} invited successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setIsSendingInvite('');
        handleError(
          err,
          `Failed to invite ${item.first_name}.`,
          sendMemberInvite,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const [file, setFile] = useState();
  const removeFile = (filename) => {
    setFile(undefined);
  };
  const [loadingBulkInvite, setLoadingBulkInvite] = useState(false);

  // bulk invite members
  const bulkInviteMembers = async () => {
    setLoadingBulkInvite(true);

    let bodyFormData = new FormData();
    bodyFormData.append('file', file);

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .post(bulkInviteUrl, bodyFormData, {
        headers: headers,
      })
      .then((response) => {
        setLoadingBulkInvite(false);
        onClose();
        fetchMembers();
        fetchBranches();
        fetchInvitedMembers();
        setFile(undefined);
        toast({
          position: 'bottom-left',
          title: 'Members invited sucessfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoadingBulkInvite(false);
        handleError(
          err,
          'Failed to invite members.',
          bulkInviteMembers,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  useEffect(() => {
    // Trigger the fetch
    fetchMembers();
    fetchBranches();
    fetchRoles();
    fetchInvitedMembers();
    fetchMemberLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function branchMemberIndex(index, member_id) {
    return (
      branches?.items?.[index].branch_members
        ?.map(function (e) {
          return e.org_member?.id;
        })
        .indexOf(member_id) ?? -1
    );
  }

  // search member
  const [searchMember, setSearchMember] = useState('');
  const [filterMemberData, setFilterMemberData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const searchUser = () => {
    setSearchLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .get(usersUrl, {
        params: { branch_id: branch ?? '', email: searchMember },
        headers: headers,
      })
      .then((res) => {
        setSearchLoading(false);
        setFilterMemberData(res.data.items);
      })
      .catch((err) => {
        setSearchLoading(false);
        handleError(
          err,
          'Failed to search user.',
          searchUser,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const [downloadLoading, setDownloadLoading] = useState(false);
  const fileDownload = require('js-file-download');

  // download invite template
  const downloadTemplate = async () => {
    setDownloadLoading(true);
    const headers = {
      ' Content-Disposition': 'attachment;filename=report.xls',
      'Content-Type': 'application/vnd.ms-excel',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .get(templateUrl, {
        params: { organisation: organisation?.id ?? '' },
        headers: headers,
        responseType: 'blob',
      })

      .then((response) => {
        setDownloadLoading(false);
        fileDownload(response.data, 'member-invite.xlsx');
      })
      .catch((err) => {
        setDownloadLoading(false);
        handleError(
          err,
          'Failed to download template.',
          downloadTemplate,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  return (
    <Box m={8}>
      <Flex mb={4}>
        <Box ml={4}>
          <Heading as='h4' size='md' mb={2}>
            Members
          </Heading>
          <Text fontSize='sm'>
            Create new users, customize user permissions and remove users from
            your account
          </Text>
        </Box>

        <Spacer />

        <Button
          variant='outline'
          backgroundColor='#107e76'
          textColor='white'
          onClick={onOpen}
          isDisabled={
            organisation?.organisation_type === 'INDIVIDUAL' ||
            !roles?.[organisation?.id]?.includes(
              'OrganisationMembers.create'
            ) ||
            !roles?.[organisation?.id]?.includes('Users.list') ||
            !roles?.[organisation?.id]?.includes('Invites.list')
          }
        >
          Invite
        </Button>
      </Flex>
      <div className='members-header-action'>
        {/* <div
              className={`searchbar ${
                isSearchActive || query.length > 0 ? 'active-searchbar' : ''
              }`}
            >
              <AiOutlineSearch className='search-icon' />
              <input
                placeholder='Search members'
                className='search-input'
                onChange={(e) => setQuery(e.target.value)}
                onFocus={() => setSearchActive(true)}
                onBlur={() => setSearchActive(false)}
              />
            </div> */}

        {/* search team member */}
        <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Container mt={8}>
                {/* via email */}
                <Text as='b' fontSize='md' mb={2}>
                  Search Team Member
                </Text>
                <Text noOfLines={1} fontSize='sm' mb={6}>
                  Enter user email to invite them to your organization
                </Text>

                <InputGroup size='md' mb={4}>
                  <Input
                    type={'email'}
                    pr='4.5rem'
                    placeholder='Enter email'
                    focusBorderColor='#107e76'
                    onChange={(e) => setSearchMember(e.target.value)}
                  />
                  <InputRightElement width='4.5rem'>
                    <Button
                      variant={'unstyled'}
                      textColor='#107e76'
                      size='md'
                      isDisabled={searchMember === ''}
                      isLoading={searchLoading}
                      onClick={() => searchUser()}
                    >
                      Search
                    </Button>
                  </InputRightElement>
                </InputGroup>

                {/* invite member */}
                {filterMemberData?.map((item) => (
                  <Box key={item.id} align='center'>
                    {/* user email */}
                    <Tag
                      mr={3}
                      mt={4}
                      size={'lg'}
                      borderRadius='full'
                      variant='outline'
                      textColor='black'
                    >
                      <Flex align='center'>
                        <Avatar
                          size={'xs'}
                          name={`${item.first_name} ${item.last_name}`}
                          fontWeight={'bold'}
                          mr={2}
                        />
                      </Flex>

                      <TagLabel>
                        {`${item?.user_contacts?.[0]?.contact?.contact_value}, 
                            ${item?.user_contacts?.[1]?.contact?.contact_value}`}
                      </TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          setFilterMemberData(
                            filterMemberData?.filter((a) => a.id !== item.id)
                          );
                        }}
                      />
                    </Tag>

                    <Button
                      mt={4}
                      w={'80%'}
                      backgroundColor={'#107e76'}
                      textColor='white'
                      placement={'right'}
                      isLoading={isSendingInvite}
                      onClick={() => sendMemberInvite(item)}
                    >
                      Invite
                    </Button>
                  </Box>
                ))}
                <Box mt={8}></Box>
                <Text as='b' fontSize='md'>
                  Or
                </Text>

                <Box mt={8}>
                  {/* bulk upload */}
                  <FileSelector
                    title='Bulk invite'
                    isRequired={true}
                    file={file}
                    setFile={setFile}
                    removeFile={removeFile}
                    accept={'.xlsx, .xls, .csv'}
                    desc='Excel and CSV'
                  />

                  {/* bulk invite */}
                  <Flex alignContent='center' mt={4}>
                    <Button
                      mr={2}
                      variant='outline'
                      backgroundColor={'#107e76'}
                      textColor='white'
                      w={'80%'}
                      isLoading={loadingBulkInvite}
                      isDisabled={!file}
                      onClick={() => bulkInviteMembers()}
                    >
                      Bulk invite
                    </Button>

                    {/* download template */}
                    <Button
                      rightIcon={<MdDownload />}
                      colorScheme='blue'
                      variant='outline'
                      isLoading={downloadLoading}
                      onClick={() => downloadTemplate()}
                    >
                      Template
                    </Button>
                  </Flex>
                </Box>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='outline'
                mr={3}
                onClick={() => {
                  setFilterMemberData([]);
                  setFile(undefined);
                  onClose();
                }}
              >
                Cancel
              </Button>
              {/* <Button
                    backgroundColor={'#107e76'}
                    textColor='white'
                    placement={'right'}
                  >
                    Invite
                  </Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* invite team member */}
        <Modal size={'lg'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invite Team Members</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container>
                {/* via email */}
                <Text as='b' fontSize='md' mb={2}>
                  Invite via email
                </Text>
                <Text noOfLines={1} fontSize='sm' mb={8}>
                  Enter user emails to invite them to your organization
                </Text>

                <InputGroup size='md' mb={10}>
                  <Input
                    type={'email'}
                    pr='4.5rem'
                    placeholder='Enter email'
                    focusBorderColor='#107e76'
                  />
                  <InputRightElement width='4.5rem'>
                    {/* <Button h='1.75rem' size='sm' onClick=''></Button> */}
                    {/* <IconButton icon={<MdAdd />} /> */}
                    <Button
                      variant={'ghost'}
                      background='#107e76'
                      textColor='white'
                      size='md'
                    >
                      Add
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <Tag
                  mr={3}
                  mb={3}
                  size={'lg'}
                  borderRadius='full'
                  variant='outline'
                  colorScheme='yellow'
                >
                  <TagLabel>jane@email.co.ke</TagLabel>
                  <TagCloseButton />
                </Tag>

                <Tag
                  mr={3}
                  mb={3}
                  size={'lg'}
                  borderRadius='full'
                  variant='outline'
                  colorScheme='yellow'
                >
                  <TagLabel>alex@email.co.ke</TagLabel>
                  <TagCloseButton />
                </Tag>

                <Tag
                  size={'lg'}
                  borderRadius='full'
                  variant='outline'
                  colorScheme='yellow'
                >
                  <TagLabel>juma@email.co.ke</TagLabel>
                  <TagCloseButton />
                </Tag>

                {/* invite      */}
                <Flex align='right' justify='right'>
                  <Button
                    background='#107e76'
                    textColor='white'
                    mb={10}
                    placement={'right'}
                  >
                    Invite
                  </Button>
                </Flex>

                {/* via link */}
                <Text as='b' fontSize='md' mb={2}>
                  Invite via link
                </Text>
                <Text noOfLines={1} fontSize='sm'>
                  Team mates will create an account or join using this link
                </Text>

                <Container maxW='md' bg='#DCF3F1' p={2} mt={5} borderRadius={8}>
                  <Text fontSize='sm' color='#107e76'>
                    ratiba.co/invite/7uydetshbqwe
                  </Text>
                </Container>

                {/* copy link */}
                <Flex align='right' justify='right'>
                  <Button
                    background='#107e76'
                    textColor='white'
                    mt={10}
                    mb={10}
                    placement='right'
                  >
                    Copy link
                  </Button>
                </Flex>
              </Container>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>

      {/* members tabs */}
      <Tabs variant='solid-rounded'>
        <TabList>
          <Tab _selected={{ color: 'white', bg: '#107e76' }}>All</Tab>
          <Tab
            _selected={{ color: 'white', bg: '#107e76' }}
            isDisabled={organisation?.organisation_type === 'INDIVIDUAL'}
            onClick={() => fetchInvitedMembers()}
          >
            Invited
          </Tab>
          <Tab
            _selected={{ color: 'white', bg: '#107e76' }}
            isDisabled={
              organisation?.organisation_type === 'INDIVIDUAL' ||
              !roles?.[organisation?.id]?.includes('Events.list')
            }
          >
            Logs
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {loadingMembers ? (
              <Center>
                <BounceLoader
                  color={'#107e76'}
                  loading={loadingMembers}
                  size={80}
                />
              </Center>
            ) : (
              <UsersTable
                data={search(UsersMembers)}
                members={members?.items}
                setSelectedMember={setSelectedMember}
                onEditOpen={onEditOpen}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loadingInvitedMembers ? (
              <Center>
                <BounceLoader
                  color={'#107e76'}
                  loading={loadingInvitedMembers}
                  size={80}
                />
              </Center>
            ) : (
              <MemberRequestsTable
                members={membersInvited?.items}
                setSelectedMember={setSelectedMember}
                onEditOpen={onEditOpen}
              />
            )}
          </TabPanel>
          {/* Logs */}
          <TabPanel>
            {loadingMemberLogs ? (
              <Center>
                <BounceLoader
                  color={'#107e76'}
                  loading={loadingMemberLogs}
                  size={80}
                />
              </Center>
            ) : (
              <UserEventsTable logs={memberLogs?.items} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* edit member */}
      <Modal
        isOpen={isEditOpen}
        onClose={() => {
          onEditClose();
          setSelectedMember({});
          setSelectedRoleId('');
        }}
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Container mt={8}>
              <Text as='b' fontSize='lg'>
                Edit member
              </Text>

              <Flex align='center' mt={8}>
                {/* avatar */}
                <Avatar
                  name={`${selectedMember?.user?.first_name} ${selectedMember?.user?.last_name}`}
                  size='sm'
                  mr={5}
                />
                <Box>
                  {/* name */}
                  <Flex align='center'>
                    <Text fontSize='sm'>
                      {`${selectedMember?.user?.first_name} ${selectedMember?.user?.last_name}`}
                    </Text>
                    {user?.user?.id === selectedMember?.user?.id ? (
                      <Tag
                        fontSize='xs'
                        fontWeight='bold'
                        borderRadius='full'
                        backgroundColor='#EF99204D'
                        textColor='#EF9920'
                        variant='solid'
                        ml={2}
                      >
                        You
                      </Tag>
                    ) : null}
                  </Flex>
                  {/* email */}
                  <span className='user-email'>
                    <Text fontSize='sm' fontWeight={200}>
                      {selectedMember?.user?.user_contacts?.[0]?.contact
                        .contact_type === 'EMAIL'
                        ? selectedMember?.user?.user_contacts?.[0]?.contact
                            .contact_value
                        : selectedMember?.user?.user_contacts?.[1]?.contact
                            .contact_value}
                    </Text>
                  </span>
                </Box>
              </Flex>

              {/* roles */}
              <Flex mt={8} ml={4}>
                <Text>Role</Text>
                <Text textColor='red'>*</Text>
              </Flex>
              <Tooltip
                label='You cannot update your own role'
                isDisabled={user.user.id !== selectedMember?.user?.id}
              >
                <Select
                  style={{ 'text-transform': 'capitalize' }}
                  placeholder='Select role'
                  focusBorderColor='#C6DDDB'
                  mt={2}
                  mb={8}
                  size='md'
                  isDisabled={user.user.id === selectedMember?.user?.id}
                  defaultValue={
                    user.user.id === selectedMember?.user?.id
                      ? decodedRoles[organisation?.id]
                      : selectedMember?.roles?.[0]?.id
                  }
                  onChange={(e) => {
                    setSelectedRoleId(e.target.value);
                  }}
                >
                  {rolesList?.items?.map((role, index) => {
                    return (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    );
                  })}
                </Select>
              </Tooltip>

              {/* employment type */}
              {/* <Flex mt={8} ml={4}>
                <Text>Employment type</Text>
                <Text textColor='red'>*</Text>
              </Flex>

              <Select
                style={{ 'text-transform': 'capitalize' }}
                placeholder='Select employment type'
                focusBorderColor='#C6DDDB'
                mt={2}
                mb={8}
                size='md'
                isDisabled={user.user.id === selectedMember?.user?.id}
                defaultValue={
                  user.user.id === selectedMember?.user?.id
                    ? decodedRoles[organisation?.id]
                    : selectedMember?.roles?.[0]?.id
                }
                onChange={(e) => {
                  setSelectedRoleId(e.target.value);
                }}
              >
                {rolesList?.items?.map((role, index) => {
                  return (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  );
                })}
              </Select> */}

              {/* branches */}
              <Flex mt={8} ml={4} mb={2}>
                <Text>Branches</Text>
              </Flex>
              {branches?.items?.map((branch, index) =>
                branchMemberIndex(index, selectedMember?.id) !== -1 ? (
                  <ButtonGroup
                    ml={3}
                    mb={3}
                    size='sm'
                    isAttached
                    variant='outline'
                    colorScheme='yellow'
                  >
                    <Button>{branch.name}</Button>
                    <IconButton
                      icon={
                        branches?.items?.[index].branch_members[
                          branchMemberIndex(index, selectedMember?.id)
                        ].is_active ? (
                          <MdClose />
                        ) : (
                          <MdRestore />
                        )
                      }
                      isLoading={loadingBranchMember === branch?.id}
                      onClick={() => {
                        updateMemberBranch(
                          branch.id,
                          branches?.items?.[index].branch_members[
                            branchMemberIndex(index, selectedMember?.id)
                          ].is_active,
                          branches?.items?.[index].branch_members[
                            branchMemberIndex(index, selectedMember?.id)
                          ].id
                        );
                      }}
                    />
                  </ButtonGroup>
                ) : (
                  <ButtonGroup
                    ml={3}
                    mb={3}
                    size='sm'
                    isAttached
                    // variant='outline'
                    colorScheme='teal'
                  >
                    <Button>{branch.name}</Button>
                    <IconButton
                      icon={<MdAdd />}
                      isLoading={loadingBranchMember === branch?.id}
                      onClick={() => {
                        createBranchMember(branch.id);
                      }}
                    />
                  </ButtonGroup>
                )
              )}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onEditClose();
                setSelectedMember({});
                setSelectedRoleId('');
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={'#107e76'}
              textColor='white'
              isLoading={loadingMemberRole}
              isDisabled={!selectedRoleId || selectedRoleId === ''}
              onClick={() => updateMemberRole()}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MembersPage;
