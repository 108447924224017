import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import {
  createAnOrganizationString,
  createOrganizationDescriptionString,
  createOrganizationString,
  joinOrganizationDescriptionString,
  joinOrganizationString,
} from '../../application/services/app_strings';
import {
  addUserIconSvgPath,
  createOrganizationIconSvgPath,
} from '../../application/services/asset_strings';
import { getOrganisationsUrl } from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import { settings } from '../../application/services/utils';
import {
  setActivePath,
  setSettingsPath,
} from '../../redux/navigation/navigation';
import {
  setIsOrgRegOpen,
  setIsViewMap,
} from '../../redux/organisation/organisationRegistration';
import { setIsPlanPaid } from '../../redux/organisation/subscription';

const OrganizationsPage = () => {
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);
  const { isPlanPaid } = useSelector((state) => state.subscription);

  const [organisations, setOrganisations] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { user, access_token } = useSelector((state) => state.user);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .get(getOrganisationsUrl, {
          params: { created_by: user?.user?.id ?? '' },
          headers: headers,
        })
        .then((response) => {
          setLoading(false);
          setOrganisations(response.data.items);
        })
        .catch((err) => {
          setLoading(false);
          handleError(
            err,
            'Failed to fetch organisations.',
            fetchData,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }

    // Trigger the fetch
    if (roles?.[organisation?.id]?.includes('Organisations.list')) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscriptionIndex =
    settings(organisation, roles, isPlanPaid)?.filter(
      (item) => item.show === true
    ).length - 1;

  return (
    <div>
      <Box mt={10} />

      {isLoading ? (
        <Center>
          <BounceLoader color={'#107e76'} loading={isLoading} size={80} />
        </Center>
      ) : organisations.length > 0 ? (
        <div>
          <Flex m={8} align='center'>
            <Container ml={4}>
              <Heading as='h4' size='md' mb={2}>
                Organization status
              </Heading>
              <Text fontSize='sm'>
                Follow up on the status of your organization registration
              </Text>
            </Container>

            <Spacer />
            <Button
              noOfLines={1}
              backgroundColor='#107e76'
              textColor='white'
              isDisabled={
                organisation?.id === undefined ||
                organisation?.id === '' ||
                !roles?.[organisation?.id]?.includes('Organisations.create')
              }
              onClick={() => {
                dispatch(setIsOrgRegOpen(true));
                dispatch(setIsViewMap(''));
                dispatch(setIsPlanPaid(true));
                // if (isPlanPaid) {
                //   dispatch(setIsOrgRegOpen(true));
                //   dispatch(setIsViewMap(''));
                // } else {
                //   dispatch(setSettingsPath(subscriptionIndex));
                //   dispatch(setActivePath('/settings'));
                //   navigate('/settings');
                // }
              }}
            >
              Register
            </Button>
          </Flex>

          <TableContainer m={12} backgroundColor={'white'}>
            <Table size='md'>
              <Thead>
                <Tr>
                  <Th>Organization</Th>
                  <Th>Status</Th>
                  <Th>Date registered</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {organisations.length > 0 ? (
                  organisations.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {item?.organisation_type !== 'INDIVIDUAL'
                            ? item.name
                            : 'Personal Account'}
                        </Text>
                      </Td>

                      {/* status */}
                      <Td>
                        <Tag
                          borderRadius='full'
                          backgroundColor={
                            item?.status === 'VERIFIED' ? '#107e76' : '#EF9920'
                          }
                          variant='solid'
                        >
                          {item.status ?? 'N/A'}
                        </Tag>
                      </Td>
                      {/* created */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {moment(item.created_at).format('DD-MM-YYYY')}
                        </Text>
                      </Td>
                      {/* assign role */}
                      <Td>
                        <Button
                          mr={5}
                          textColor='#107e76'
                          variant='outline'
                          backgroundColor={'#DAF2F0'}
                          size='sm'
                          onClick={() => navigate(`/organizations/${item.id}`)}
                        >
                          Details
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr className='no-record'>
                    <Td colSpan='5'>
                      <Text fontSize='sm'>No records found</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        // organisations doesn't exist

        <Center p={12}>
          {organisation?.branches === undefined ||
          organisation?.branches?.length === 0 ||
          roles?.[organisation?.id]?.includes('Organisations.create') ? (
            <Box align='center' w={'50%'} m={4}>
              <img
                src={createOrganizationIconSvgPath}
                alt='create organisation'
              />
              <h4>{createAnOrganizationString}</h4>
              <p>{createOrganizationDescriptionString}</p>
              <Button
                mt={8}
                backgroundColor='#107e76'
                textColor='white'
                onClick={() => {
                  dispatch(setIsOrgRegOpen(true));
                  dispatch(setIsViewMap(''));
                  dispatch(setIsPlanPaid(true));
                  // if (isPlanPaid) {
                  //   dispatch(setIsOrgRegOpen(true));
                  //   dispatch(setIsViewMap(''));
                  // } else {
                  //   dispatch(setSettingsPath(subscriptionIndex));
                  //   dispatch(setActivePath('/settings'));
                  //   navigate('/settings');
                  // }
                }}
              >
                {createOrganizationString}
              </Button>
            </Box>
          ) : null}

          <Box align='center' w={'50%'} m={4}>
            <img src={addUserIconSvgPath} alt='add user' />
            <h4>{joinOrganizationString}</h4>
            <p>{joinOrganizationDescriptionString}</p>
          </Box>
        </Center>
      )}
    </div>
  );
};

export default OrganizationsPage;
