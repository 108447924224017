import TopBar from '../core/TopBar/TopBar';
import './ReportsPage.scss';

const ReportsPage = () => {
  return (
    <div className='reports'>
      <TopBar />
      <div className='reportsHeader'>
        <div className='reportsHeaderLeading'>
          <h1>Reports</h1>
          <p>Track organization members</p>
        </div>

      </div>
      <div className='reportsBody'>
        <div className="reportsBodyItem">

          <h1 className="reportsBodyItemHeader">Today, 14 Sept, 2022</h1>

          <div className="reportsBodyItemBody">
            <div className="reportsBodyItemBodyItem">
              <div className="reportsBodyItemBodyItemHeader">
                <h5>MS</h5>
                <p><span className='name'>Maria Sela</span> requested to join <span className='rota'>Accident and Emergency</span> that has <span className='count'>15 other people</span></p></div>
            </div>
            <div className="reportsBodyItemBodyItem">
              <div className="reportsBodyItemBodyItemHeader">
                <h5>MS</h5>
                <p><span className='name'>Maria Sela</span> requested to join <span className='rota'>Accident and Emergency</span> that has <span className='count'>15 other people</span></p></div>
            </div>
          </div>
        </div>
        <div className="reportsBodyItem">

          <h1 className="reportsBodyItemHeader">Today, 14 Sept, 2022</h1>

          <div className="reportsBodyItemBody">
            <div className="reportsBodyItemBodyItem">
              <div className="reportsBodyItemBodyItemHeader">
                <h5>MS</h5>
                <p><span className='name'>Maria Sela</span> requested to join <span className='rota'>Accident and Emergency</span> that has <span className='count'>15 other people</span></p></div>
            </div>
            <div className="reportsBodyItemBodyItem">
              <div className="reportsBodyItemBodyItemHeader">
                <h5>MS</h5>
                <p><span className='name'>Maria Sela</span> requested to join <span className='rota'>Accident and Emergency</span> that has <span className='count'>15 other people</span></p></div>
            </div>
          </div>
        </div>
        <div className="reportsBodyItem">

          <h1 className="reportsBodyItemHeader">Today, 14 Sept, 2022</h1>

          <div className="reportsBodyItemBody">
            <div className="reportsBodyItemBodyItem">
              <div className="reportsBodyItemBodyItemHeader">
                <h5>MS</h5>
                <p><span className='name'>Maria Sela</span> requested to join <span className='rota'>Accident and Emergency</span> that has <span className='count'>15 other people</span></p></div>
            </div>
            <div className="reportsBodyItemBodyItem">
              <div className="reportsBodyItemBodyItemHeader">
                <h5>MS</h5>
                <p><span className='name'>Maria Sela</span> requested to join <span className='rota'>Accident and Emergency</span> that has <span className='count'>15 other people</span></p></div>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
}

export default ReportsPage