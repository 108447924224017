import {
  Button,
  Container,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { employeeTypeUrl } from '../../../../../../application/services/http_endpoints';
import { handleError } from '../../../../../../application/services/http_service';
import {
  employeeOrConsultant,
  employmentType,
} from '../../../../../../application/services/utils';

const EmploymentTypeModal = ({
  fetchEmploymentType,
  selectedEmploymentType,
  isEdit,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access_token } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);

  const [employeeCategory, setEmployeeCategory] = useState();
  const [employeeType, setEmployeeType] = useState();
  const [workingHours, setWorkingHours] = useState();
  const [hourlyRate, setHourlyRate] = useState();

  const [isLoading, setLoading] = useState(false);

  async function clearState() {
    setEmployeeCategory();
    setEmployeeType();
    setWorkingHours();
    setHourlyRate();
  }

  const createEmploymentType = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .post(
        employeeTypeUrl,
        {
          org_id: organisation?.id,
          employee_category: employeeCategory,
          employee_type: employeeType,
          expected_monthly_work_hours: workingHours,
          hourly_rate: hourlyRate,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);
        clearState();
        onClose();
        fetchEmploymentType();
        toast({
          position: 'bottom-left',
          title: 'Employment type created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to create employment type.',
          createEmploymentType,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  async function updateEmploymentType(payload) {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(`${employeeTypeUrl}${selectedEmploymentType.id}/`, payload, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        clearState();
        onClose();
        fetchEmploymentType();
        toast({
          position: 'bottom-left',
          title: 'Employment type updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update employment type.',
          updateEmploymentType,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    isEdit
      ? updateEmploymentType({
          org_id: organisation?.id,
          employee_category: employeeCategory,
          employee_type: employeeType,
          expected_monthly_work_hours: workingHours,
          hourly_rate: hourlyRate,
        })
      : createEmploymentType();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        clearState();
      }}
      size={'lg'}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Container mt={8}>
              {/* via email */}
              <Text as='b' fontSize='lg'>
                {isEdit ? 'Edit employment type' : 'Create employment type'}
              </Text>

              {/* employee category*/}
              <Flex mt={8}>
                <Text fontSize={'sm'}>Employee category</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <Select
                required={true}
                focusBorderColor='#107e76'
                placeholder='Select employee category'
                defaultValue={selectedEmploymentType?.employee_category}
                onChange={(e) => setEmployeeCategory(e.target.value)}
                mb={8}
                mt={2}
              >
                {employeeOrConsultant.map((emp, index) => {
                  return (
                    <option key={index} value={emp}>
                      {emp}
                    </option>
                  );
                })}
              </Select>

              {/* employment type */}
              <Flex>
                <Text fontSize={'sm'}>Employment type</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <Select
                required={true}
                focusBorderColor='#107e76'
                placeholder='Select employment type'
                defaultValue={selectedEmploymentType?.employee_type}
                onChange={(e) => setEmployeeType(e.target.value)}
                mb={8}
                mt={2}
              >
                {employmentType.map((empType, index) => {
                  return (
                    <option key={index} value={empType}>
                      {empType}
                    </option>
                  );
                })}
              </Select>

              {/* work hours per month */}
              <Flex>
                <Text fontSize={'sm'}>Work hours per month</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  required={true}
                  type={'number'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  placeholder='Enter work hours'
                  defaultValue={
                    selectedEmploymentType?.expected_monthly_work_hours
                  }
                  onChange={(e) => setWorkingHours(e.target.value)}
                />
              </InputGroup>

              {/* earnings per hour */}
              <Flex>
                <Text fontSize={'sm'}>Earnings per hour</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <InputLeftAddon children='KES' />
                <Input
                  required={true}
                  type={'number'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  placeholder='Enter earnings'
                  defaultValue={selectedEmploymentType?.hourly_rate}
                  onChange={(e) => setHourlyRate(e.target.value)}
                />
              </InputGroup>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onClose();
                clearState();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={'#107e76'}
              textColor='white'
              isLoading={isLoading}
              isDisabled={
                isEdit &&
                !employeeCategory &&
                !employeeType &&
                !workingHours &&
                !hourlyRate
              }
              type='submit'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EmploymentTypeModal;
