import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import './App.scss';
import AuthVerify from './application/services/auth-verify';
import ChatPage from './presentation/chat/ChatPage';
import AuthScaffold from './presentation/core/auth_scaffold/AuthScaffold';
import SidebarWithHeader from './presentation/core/dashboard_scaffold/SidebarWithHeader';
import ForgotPasswordPageOne from './presentation/forgot_password/ForgotPasswordPageOne';
import ForgotPasswordPageTwo from './presentation/forgot_password/ForgotPasswordPageTwo';
import OtpPage from './presentation/forgot_password/OtpPage';
import HomePage from './presentation/home/HomePage';
import AcceptInvitePage from './presentation/invite_member/AcceptInvitePage';
import InviteMemberPage from './presentation/invite_member/InviteMemberPage';
import LoginPage from './presentation/login/LoginPage';
import MapContainer from './presentation/maps/MapContainer';
import MembersPage from './presentation/members/MembersPage';
import OrganizationDetailsPage from './presentation/organizations/OrganizationDetails';
import OrganizationsPage from './presentation/organizations/OrganizationsPage';
import OrganizationRegistrationPageOne from './presentation/organization_registration/OrganizationRegistrationPageOne';
import OrganizationRegistrationPageTwo from './presentation/organization_registration/OrganizationRegistrationPageTwo';
import RegisterPageOne from './presentation/register/RegisterPageOne';
import RegisterPageTwo from './presentation/register/RegisterPageTwo';
import SuccessPage from './presentation/register/SuccessPage';
import ReportsPage from './presentation/reports/ReportsPage';
import Rota from './presentation/rota/Rota';
import CreateRolePage from './presentation/settings/pages/employee_roles/CreateRolePage';
import EmployeeRoles from './presentation/settings/pages/employee_roles/EmployeeRoles';
import Reports from './presentation/settings/pages/reports/Reports';
import WorkingHours from './presentation/settings/pages/working_hours/WorkingHours';
import SettingsWrapperPage from './presentation/settings/SettingsWrapper';
import TasksPage from './presentation/tasks/TasksPage';
import TimeSheetPage from './presentation/timesheet/TimeSheetPage';
import RecordsPage from './presentation/records/RecordsPage';

function App() {
  const [loading, setLoading] = useState(true);
  // const [choosePlan, setChoosePlan] = useState(false);
  const { isOrgRegOpen } = useSelector(
    (state) => state.organisationRegistration
  );
  const { isPlanPaid } = useSelector((state) => state.subscription);
  const { loggedIn } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);

  useEffect(() => {
    const onPageLoad = () => {
      setLoading(false);
    };
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
  return (
    <div className='app'>
      {loading ? (
        <div className='loader'>
          <BounceLoader color={'#107e76'} loading={loading} size={80} />
        </div>
      ) : // user not logged in
      !loggedIn ? (
        <Router>
          <AuthScaffold>
            <Routes>
              <Route path='/' element={<LoginPage />} />
              <Route path='/register' element={<RegisterPageOne />} />
              <Route
                path='/register-docs'
                element={<RegisterPageTwo loggedIn={loggedIn} />}
              />
              <Route path='/forgot-pass' element={<ForgotPasswordPageOne />} />
              <Route path='/otp' element={<OtpPage />} />
              <Route path='/reset-pass' element={<ForgotPasswordPageTwo />} />
              <Route path='/successPage' element={<SuccessPage />} />
              <Route path='/maps' element={<MapContainer />} />
              <Route path='/accept-invitation' element={<AcceptInvitePage />} />
            </Routes>
          </AuthScaffold>
        </Router>
      ) : // user logged in and hasn't started org reg
      loggedIn && !isOrgRegOpen ? (
        <Router>
          <SidebarWithHeader>
            <Routes>
              <Route path='/' element={<HomePage />} />

              <Route path='/settings'>
                <Route index element={<SettingsWrapperPage />} />
                <Route path='reports' element={<Reports />} />
                <Route path='shiftHours' element={<WorkingHours />} />
                <Route path='employeeRoles' element={<EmployeeRoles />} />
                {organisation?.organisation_type !== 'INDIVIDUAL' &&
                roles?.[organisation?.id]?.includes('Roles.create') ? (
                  <Route path='create_role' element={<CreateRolePage />} />
                ) : null}
              </Route>

              <Route path='/maps' element={<MapContainer />} />

              {/* organisations */}
              {organisation?.id === undefined ||
              organisation?.id === '' ||
              roles?.[organisation?.id]?.includes('Organisations.list') ? (
                <Route path='/organizations'>
                  <Route index element={<OrganizationsPage />} />
                  <Route path=':id' element={<OrganizationDetailsPage />} />
                </Route>
              ) : null}
              <Route path='/maps' element={<MapContainer />} />
              <Route
                path='/org-reg-one'
                element={<OrganizationRegistrationPageOne />}
              />
              <Route
                path='/org-reg-two'
                element={<OrganizationRegistrationPageTwo />}
              />

              {/* rota */}
              {organisation?.id === undefined ||
              organisation?.id === '' ||
              roles?.[organisation?.id]?.includes('Rota.list') ||
              roles?.[organisation?.id]?.includes('Shifts.list') ? (
                <Route path='/rota' element={<Rota />} />
              ) : null}

              {/* records */}
              {/* {roles?.[organisation?.id]?.includes('Procedures.list') ||
              roles?.[organisation?.id]?.includes('Patients.list') ? (
                <Route path='/records' element={<RecordsPage />} />
              ) : null} */}
              <Route path='/records' element={<RecordsPage />} />

              {/* org members */}
              {
                // organisation.organisation_type !== 'INDIVIDUAL' &&
                roles?.[organisation?.id]?.includes(
                  'OrganisationMembers.list'
                ) ? (
                  <Route path='/members' element={<MembersPage />} />
                ) : null
              }

              {/* org member invite */}
              {organisation?.organisation_type !== 'INDIVIDUAL' &&
              roles?.[organisation?.id]?.includes(
                'OrganisationMembers.update'
              ) ? (
                <Route path='/invite_member' element={<InviteMemberPage />} />
              ) : null}

              {/* chat */}
              {organisation?.organisation_type !== 'INDIVIDUAL' &&
              roles?.[organisation?.id]?.includes('Chats.list') ? (
                <Route path='/chat' element={<ChatPage />} />
              ) : null}

              {/* timesheet */}
              {/* {roles?.[organisation?.id]?.includes('Timesheets.list') ? (
                <Route path='/timesheet' element={<TimeSheetPage />} />
              ) : null} */}

              {/* timesheet */}
              {organisation?.organisation_type !== 'INDIVIDUAL' &&
              (roles?.[organisation?.id]?.includes(
                'TimeSheet.view_branch_timesheet'
              ) ||
                roles?.[organisation?.id]?.includes(
                  'TimeSheet.view_branch_shift_hours'
                ) ||
                roles?.[organisation?.id]?.includes(
                  'TimeSheet.view_personal_timesheet'
                ) ||
                roles?.[organisation?.id]?.includes('Chats.list')) ? (
                <Route path='/timesheet' element={<TimeSheetPage />} />
              ) : null}

              {/* tasks */}
              {organisation?.organisation_type !== 'INDIVIDUAL' &&
              roles?.[organisation?.id]?.includes('Admin.update_user') ? (
                <Route path='/tasks' element={<TasksPage />} />
              ) : null}

              {/* reports */}
              <Route path='/reports' element={<ReportsPage />} />

              {/* accept invite */}
              <Route path='/accept-invitation' element={<AcceptInvitePage />} />
            </Routes>
          </SidebarWithHeader>
          <AuthVerify />
        </Router>
      ) : (
        //  : !choosePlan ? (
        //   <PaymentPlansPage setChoosePlan={setChoosePlan} />
        // ) : isOrgRegOpen && !isPlanPaid ? (
        //   <PaymentActionPage />
        // )

        // user logged in and has started org reg
        <Router>
          <AuthScaffold>
            <Routes>
              {loggedIn && isOrgRegOpen && isPlanPaid && (
                <Route
                  path='/organizations'
                  element={<OrganizationRegistrationPageOne />}
                />
              )}

              <Route
                path='/register-docs'
                element={<RegisterPageTwo loggedIn={loggedIn} />}
              />
              <Route path='/register' element={<RegisterPageOne />} />
              <Route path='/forgot-pass' element={<ForgotPasswordPageOne />} />
              <Route path='/otp' element={<OtpPage />} />
              <Route path='/reset-pass' element={<ForgotPasswordPageTwo />} />
              <Route path='/successPage' element={<SuccessPage />} />
              <Route
                path='/org-reg-one'
                element={<OrganizationRegistrationPageOne />}
              />
              <Route
                path='/org-reg-two'
                element={<OrganizationRegistrationPageTwo />}
              />
              <Route path='/maps' element={<MapContainer />} />
            </Routes>
          </AuthScaffold>
        </Router>
      )}
    </div>
  );
}

export default App;
