import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const OrganisationReport = ({ orgReport, departments }) => {
  const { organisation } = useSelector((state) => state.organisation);

  return (
    <Card m={4} w={'100%'}>
      <CardHeader>
        <Text as='b'>
          {organisation?.organisation_type === 'INDIVIDUAL'
            ? 'My Account'
            : 'Organisation Summary'}
        </Text>
      </CardHeader>
      <CardBody>
        <Stack divider={<StackDivider />} spacing='4' pr={4} pl={4} pb={4}>
          {/* Departments */}
          <Flex align='center'>
            <Text pt='2' fontSize='sm'>
              Departments
            </Text>
            <Spacer />
            <Heading size='xs' textTransform='uppercase'>
              {departments?.items?.length ?? '-'}
            </Heading>
          </Flex>

          {/* Roles */}
          <Flex align='center'>
            <Text pt='2' fontSize='sm'>
              Roles
            </Text>
            <Spacer />
            <Heading size='xs' textTransform='uppercase'>
              {orgReport?.organisation_summary?.no_of_roles ?? '-'}
            </Heading>
          </Flex>

          {/* Members */}
          <Flex align='center'>
            <Text pt='2' fontSize='sm'>
              Members
            </Text>
            <Spacer />
            <Heading size='xs' textTransform='uppercase'>
              {orgReport?.organisation_summary?.staff ?? '-'}
            </Heading>
          </Flex>

          {/* Hours done */}
          <Flex align='center'>
            <Text pt='2' fontSize='sm'>
              Hours done
            </Text>
            <Spacer />
            <Heading size='xs' textTransform='uppercase'>
              {orgReport?.organisation_working_hrs_summary?.this_month
                ?.total_hrs_done ?? '-'}
            </Heading>
          </Flex>

          {/* Hours pending */}
          <Flex align='center'>
            <Text pt='2' fontSize='sm'>
              Hours pending
            </Text>
            <Spacer />
            <Heading size='xs' textTransform='uppercase'>
              {orgReport?.organisation_working_hrs_summary?.this_month
                ?.total_hrs_pending ?? '-'}
            </Heading>
          </Flex>

          {/* Total hours this month */}
          <Flex align='center'>
            <Text pt='2' fontSize='sm'>
              Total hours this month
            </Text>
            <Spacer />
            <Heading size='xs' textTransform='uppercase'>
              {orgReport?.organisation_working_hrs_summary?.this_month
                ?.total_hrs ?? '-'}
            </Heading>
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default OrganisationReport;
