import {
  Button,
  Center,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

const PatientsTable = ({
  patients,
  openModal,
  setSelectedPatient,
  onOpenDeactivateModal,
}) => {
  const { roles } = useSelector((state) => state.roles);
  const { organisation } = useSelector((state) => state.organisation);

  return (
    <TableContainer backgroundColor={'white'} m={12}>
      <Table>
        <Thead>
          <Tr>
            <Th>Record no</Th>
            <Th>No of patients</Th>
            <Th>Doctor's name</Th>
            <Th>Date created</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {patients?.length > 0 ? (
            patients.map((item) => (
              <Tr key={item.id}>
                {/* record no */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {item.record_no ?? '-'}
                  </Text>
                </Td>
                {/* no of patients */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {item.no_of_patients_seen}
                  </Text>
                </Td>
                {/* doctor's name */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {`${item.doctor.org_member.user.first_name} ${item.doctor.org_member.user.last_name} `}
                  </Text>
                </Td>
                {/* status */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {item.status ?? '-'}
                  </Text>
                </Td>
                {/* created */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {moment(item.created_at).format('MMM DD, YYYY')}
                  </Text>
                  <Text fontSize='sm'>
                    {moment.utc(item.created_at).local().format('HH:mm a')}
                  </Text>
                </Td>
                {/* actions */}
                <Td>
                  <Button
                    backgroundColor='#DAF2F0'
                    textColor='#107e76'
                    size='sm'
                    // isDisabled={
                    //   !roles?.[organisation?.id]?.includes('Procedures.update')
                    // }
                    onClick={() => {
                      setSelectedPatient(item);
                      openModal(true);
                    }}
                    mr={4}
                  >
                    Edit Patient
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className='no-record'>
              <Td colSpan='5'>
                <Text fontSize='sm'>No records found</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PatientsTable;
