import './UsersTable.css';

import {
  Avatar,
  Button,
  Flex,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const UsersTable = ({ data, members, setSelectedMember, onEditOpen }) => {
  const { user } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.roles);
  const { organisation } = useSelector((state) => state.organisation);

  return (
    <TableContainer backgroundColor={'white'}>
      <Table size='md'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Created at</Th>
            {/* <Th>Role</Th> */}
            <Th>Account</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {members?.length > 0 ? (
            members.map((item) => (
              <Tr key={item.user.id}>
                <Td>
                  <Flex align='center'>
                    {/* avatar */}
                    <Avatar
                      name={`${item.user.first_name} ${item.user.last_name}`}
                      size='sm'
                      mr={5}
                    />
                    <div>
                      {/* name */}
                      <Flex align='center'>
                        <Text textColor='black' fontSize='sm'>
                          {item.user.first_name} {item.user.last_name}
                        </Text>
                        {user.user.id === item.user.id ? (
                          <Tag
                            fontSize='xs'
                            fontWeight='bold'
                            borderRadius='full'
                            backgroundColor='#EF99204D'
                            textColor='#EF9920'
                            variant='solid'
                            ml={2}
                          >
                            You
                          </Tag>
                        ) : null}
                      </Flex>
                      {/* email */}
                      <span className='user-email'>
                        <Text fontSize='sm' fontWeight={200}>
                          {item.user.user_contacts?.[0]?.contact
                            ?.contact_type === 'EMAIL'
                            ? item.user.user_contacts?.[0]?.contact
                                ?.contact_value
                            : item.user.user_contacts?.[1]?.contact
                                ?.contact_value}
                        </Text>
                      </span>
                    </div>
                  </Flex>
                </Td>
                {/* created */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {moment(item.created_at).format('MMM DD, YYYY')}
                  </Text>
                  <Text fontSize='sm'>
                    {moment.utc(item.created_at).local().format('HH:mm a')}
                  </Text>
                </Td>
                {/* role */}
                {/* <Td>
                  <Text fontSize='sm'>
                    {item.member_type.member_type_name ?? 'Not assigned'}
                  </Text>
                </Td> */}
                {/* status */}
                <Td>
                  {item.is_active ? (
                    <Tag
                      borderRadius='full'
                      backgroundColor='#53A24C'
                      textColor='white'
                    >
                      Active
                    </Tag>
                  ) : (
                    <Tag
                      borderRadius='full'
                      backgroundColor='#C80024'
                      textColor='white'
                    >
                      Deactivated
                    </Tag>
                  )}
                </Td>
                {/* assign role */}
                <Td>
                  <Button
                    size='sm'
                    mr={5}
                    backgroundColor='#DAF2F0'
                    textColor='#107e76'
                    isDisabled={
                      !roles?.[organisation?.id]?.includes(
                        'OrganisationMembers.update'
                      )
                    }
                    onClick={() => {
                      setSelectedMember(item);
                      onEditOpen();
                    }}
                  >
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className='no-record'>
              <Td colSpan='5'>
                <Text fontSize='sm'>No records found</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
