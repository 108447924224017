import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdCheck, MdCheckCircle, MdOutlineCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import {
  billingPlansUrl,
  checkoutUrl,
} from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import {
  setIsPlanPaid,
  setOpenPlans,
  setPlanAmount,
  setPlanCode,
  setPlanCurrency,
  setPlanDueDate,
  setPlanInterval,
  setPlanName,
  setPlanPeriod,
} from '../../redux/organisation/subscription';
import './PaymentPlansPage.scss';

const PaymentPlansPage = ({ setChoosePlan }) => {
  const proceed = () => {
    setChoosePlan(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const { user, access_token } = useSelector((state) => state.user);
  const { planCode, isPlanPaid } = useSelector((state) => state.subscription);

  const [isLoading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [plansFilter, setPlansFilter] = useState('monthly');
  const [isCheckoutLoading, setCheckoutLoading] = useState('');

  // fetchbillig plans
  async function fetchBillingPlans() {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(billingPlansUrl, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        setPlans(response.data.plans);
        // setMembers(response.data.items);
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to fetch plans.',
          fetchBillingPlans,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  const email =
    user?.user_contacts?.[0]?.contact?.contact_type === 'EMAIL'
      ? user?.user_contacts?.[0]?.contact?.contact_value
      : user?.user_contacts?.[1]?.contact?.contact_value;

  const phone =
    user?.user_contacts?.[0]?.contact?.contact_type === 'PHONE'
      ? user?.user_contacts?.[0]?.contact?.contact_value
      : user?.user_contacts?.[1]?.contact?.contact_value;

  const fetchCheckoutUrl = async (plan) => {
    setCheckoutLoading(plan?.code);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      // 'organisation-id': organisation?.id ?? '',
    };
    axios
      .post(
        checkoutUrl,
        {
          msisdn: phone ?? '254700000000',
          account_number: '123S3AA',
          country_code: 'KEN',
          currency_code: plan?.amount_currency,
          customer_first_name: user?.user?.first_name,
          customer_last_name: user?.user?.last_name,
          customer_email: email ?? 'hello@ratiba.co.ke',
          due_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          merchant_transaction_id: '123S3AA',
          payment_option_code: 'SAFKE',
          callback_url: 'https://web.test001.ratiba.co.ke/',
          pending_redirect_url: 'https://web.test001.ratiba.co.ke/',
          request_amount: plan?.amount + 0.1,
          request_description: plan?.name,
          service_code: 'RATIBAONLINE',
          success_redirect_url: 'https://web.test001.ratiba.co.ke/',
          fail_redirect_url: 'https://web.test001.ratiba.co.ke/',
          language_code: 'en',
          charge_beneficiaries: [{}],
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setCheckoutLoading('');
        window.open(response?.data?.express_checkout_url);
      })
      .catch((err) => {
        setCheckoutLoading('');
        handleError(
          err,
          'Failed to fetch checkout url.',
          fetchCheckoutUrl,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  useEffect(() => {
    // Trigger the fetch
    fetchBillingPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function PlanInfo({ name }) {
    if (name === 'Free') {
      return (
        <Box>
          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='orange'
              icon={<MdCheck />}
            />
            <Text fontSize='xs'>Create and manage personal rota</Text>
          </Flex>

          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='orange'
              icon={<MdCheck />}
            />
            <Text fontSize='xs'>Book personal shift</Text>
          </Flex>

          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='orange'
              icon={<MdCheck />}
            />
            <Text fontSize='xs'>Limited monthly reports</Text>
          </Flex>
        </Box>
      );
    } else if (name === 'Premium') {
      return (
        <Box>
          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='whiteAlpha'
              icon={<MdCheck />}
            />
            <Text textColor='white' align='left' fontSize='xs'>
              Create and manage organisation rotas
            </Text>
          </Flex>

          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='whiteAlpha'
              icon={<MdCheck />}
            />
            <Text textColor='white' align='left' fontSize='xs'>
              Book and manage organisation shifts
            </Text>
          </Flex>

          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='whiteAlpha'
              icon={<MdCheck />}
            />
            <Text textColor='white' align='left' fontSize='xs'>
              Unlimited one on one and group chats
            </Text>
          </Flex>
          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='whiteAlpha'
              icon={<MdCheck />}
            />
            <Text textColor='white' align='left' fontSize='xs'>
              Unlimited monthly reports
            </Text>
          </Flex>
        </Box>
      );
    } else if (name === 'Enterprise') {
      return (
        <Box>
          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='orange'
              icon={<MdCheck />}
            />
            <Text align='left' fontSize='xs'>
              All premium features
            </Text>
          </Flex>

          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='orange'
              icon={<MdCheck />}
            />
            <Text align='left' fontSize='xs'>
              Advertising (e.g Pharmaceuticals, specialised services)
            </Text>
          </Flex>

          <Flex mt={4} align='center'>
            <IconButton
              variant='ghost'
              colorScheme='orange'
              icon={<MdCheck />}
            />
            <Text align='left' fontSize='xs'>
              Cataloguing - adjacent service providers having their catalogues
              accessible to the users of Ratiba
            </Text>
          </Flex>
        </Box>
      );
    }
    return null;
  }

  return (
    <Box h='100vh' align='center'>
      {/* top bar */}
      {isPlanPaid ? (
        <Breadcrumb mt={8} ml={8} fontWeight='medium' fontSize='sm'>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => dispatch(setOpenPlans(false))}>
              Subscription
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink>Plans</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      ) : null}

      {/* stepper */}
      <Box>
        {/* <Flex align='center' m={8} w='60%'>
          <Box align='center'>
            <IconButton
              size='lg'
              variant='ghost'
              icon={<MdCheckCircle color='#107e76' size={24} />}
            />
            <Text fontSize='xs'>Select plan</Text>
          </Box>
          <Box
            orientation='horizontal'
            h={0.8}
            w='80%'
            bgColor=' #666666'
            mr={2}
            ml={2}
          />
          <Box align='center'>
            <IconButton
              size='lg'
              variant='ghost'
              icon={<MdOutlineCircle color='#909090' size={24} />}
            />
            <Text fontSize='xs'>Payment</Text>
          </Box>
        </Flex> */}

        {/* choose plan */}
        <Center>
          <Box mt={8}>
            <Heading size='sm' mb={2}>
              Choose a plan to proceed
            </Heading>
            <Text fontSize='sm'>
              Your account will be customized based on the plan you pick
            </Text>
            <Center mt={4} mb={8}>
              <Flex align='center'>
                <Button
                  p={4}
                  borderRadius={10}
                  mr={8}
                  bgColor={plansFilter === 'monthly' ? '#107e76' : 'white'}
                  textColor={plansFilter === 'monthly' ? 'white' : 'black'}
                  onClick={() => {
                    setPlansFilter('monthly');
                  }}
                >
                  Bill Monthly
                </Button>

                <Button
                  p={4}
                  borderRadius={10}
                  bgColor={plansFilter === 'yearly' ? '#107e76' : 'white'}
                  textColor={plansFilter === 'yearly' ? 'white' : 'black'}
                  onClick={() => {
                    setPlansFilter('yearly');
                  }}
                >
                  Bill Yearly
                </Button>
              </Flex>
            </Center>
          </Box>
        </Center>

        {/* plans */}
        <Center>
          <Flex spacing='24px' mt={4} display={{ base: 'grid', md: 'flex' }}>
            {isLoading ? (
              <BounceLoader color={'#107e76'} loading={isLoading} size={80} />
            ) : plans.length > 0 ? (
              plans
                .filter((item) => item.interval === plansFilter)
                .map((plan) => (
                  <Card
                    bgColor={plan.name === 'Premium' ? '#107e76' : 'white'}
                    w={300}
                    m={4}
                  >
                    <CardHeader>
                      <Flex>
                        <Heading
                          size='sm'
                          textColor={
                            plan.name === 'Premium' ? 'white' : '#EF9920'
                          }
                        >
                          {plan.name}
                        </Heading>
                        <Spacer />
                        {plan.name === 'Premium' ? (
                          <Tag
                            borderRadius={12}
                            bgColor='white'
                            textColor='#EF9920'
                          >
                            Popular
                          </Tag>
                        ) : null}
                      </Flex>
                    </CardHeader>
                    <CardBody>
                      {/* amount */}
                      <Flex>
                        <Heading
                          size='sm'
                          mr={2}
                          textColor={
                            plan.name === 'Premium' ? 'white' : 'black'
                          }
                        >
                          {plan.amount_currency}
                        </Heading>
                        <Heading
                          size='lg'
                          textColor={
                            plan.name === 'Premium' ? 'white' : 'black'
                          }
                        >
                          {plan.amount}
                        </Heading>
                      </Flex>

                      <Divider mt={4} />

                      <PlanInfo name={plan.name} />
                    </CardBody>

                    <CardFooter>
                      <Button
                        w='100%'
                        bgColor={plan.name === 'Premium' ? 'white' : '#107e76'}
                        textColor={plan.name === 'Premium' ? 'black' : 'white'}
                        isLoading={isCheckoutLoading === plan.code}
                        isDisabled={planCode === plan.code}
                        onClick={() => {
                          // dispatch(setPlanId(plan.id));

                          // proceed();
                          if (
                            plan?.code === 'RTB-Free' ||
                            plan?.name === 'free'
                          ) {
                            dispatch(setPlanCode(plan.code));
                            dispatch(setPlanName(plan.name));
                            dispatch(setPlanPeriod(plan.interval));
                            dispatch(setPlanAmount(plan.amount));
                            dispatch(setPlanCurrency(plan.amount_currency));
                            dispatch(setPlanInterval(plan.interval));
                            dispatch(setPlanDueDate('N/A'));
                            dispatch(setIsPlanPaid(true));
                            dispatch(setOpenPlans(false));

                            toast({
                              position: 'bottom-left',
                              title: plan.name,
                              description: 'Subsciption added successfuly.',
                              status: 'success',
                              duration: 5000,
                              isClosable: true,
                            });
                          } else {
                            fetchCheckoutUrl(plan);
                          }
                        }}
                      >
                        {planCode === plan.code
                          ? 'Current Plan'
                          : 'Select option'}
                      </Button>
                    </CardFooter>
                  </Card>
                ))
            ) : (
              <Center>
                <Text>No plans found</Text>
              </Center>
            )}
          </Flex>
        </Center>
      </Box>
    </Box>
  );
};

export default PaymentPlansPage;
