import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { getOrganisationsUrl } from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';

const TasksPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, access_token } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);

  const [organisations, setOrganisations] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const email =
    user?.user_contacts?.[0]?.contact?.contact_type === 'EMAIL'
      ? user?.user_contacts?.[0]?.contact?.contact_value
      : user?.user_contacts?.[1]?.contact?.contact_value;

  // fetch organisations
  async function fetchOrganisations() {
    if (
      roles?.[organisation?.id]?.includes('Admin.update_user') &&
      email === 'hello@ratiba.co.ke'
    ) {
      setLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .get(getOrganisationsUrl, {
          headers: headers,
        })
        .then((response) => {
          setLoading(false);

          setOrganisations(response.data.items);
        })
        .catch((err) => {
          setLoading(false);
          handleError(
            err,
            'Failed to fetch organisations.',
            fetchOrganisations,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  useEffect(() => {
    fetchOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box margin={8}>
      <Box ml={4} mb={8}>
        <Heading as='h4' size='md' mb={2}>
          Manage tasks
        </Heading>
        <Text fontSize='sm'>All your tasks in one place</Text>
      </Box>

      {isLoading ? (
        <Center>
          <BounceLoader color={'#107e76'} loading={isLoading} size={80} />
        </Center>
      ) : organisations?.length > 0 ? (
        organisations?.map((organisation) => (
          <Card backgroundColor='white' mb={8} as={'sm'}>
            <CardBody>
              <Flex>
                <Text textColor='black' fontSize='md'>
                  {organisation.name}
                </Text>
                <Spacer />
                <Text fontSize='sm'>
                  {moment(organisation.created_at).format('DD MMM, YYYY')}
                </Text>
              </Flex>
              <Text mt={2} fontSize='sm'>
                You have recieved a new request to review this organisation
                which has been registered. Review to approve or reject this
                request.
              </Text>

              <Button
                p={0}
                rightIcon={<MdChevronRight />}
                textColor='#107e76'
                variant='ghost'
                fontSize='sm'
                onClick={() => navigate(`/organizations/${organisation.id}`)}
              >
                Review
              </Button>
            </CardBody>
          </Card>
        ))
      ) : (
        <Center>
          <Text>No tasks to manage</Text>
        </Center>
      )}
    </Box>
  );
};

export default TasksPage;
