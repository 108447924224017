import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import './UsersTable.css';

const UserEventsTable = ({ logs }) => {
  return (
    <TableContainer backgroundColor={'white'}>
      <Table size='md'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Action</Th>
            <Th>Created at</Th>
          </Tr>
        </Thead>
        <Tbody>
          {logs?.length > 0 ? (
            logs.map((item) => (
              <Tr key={item.id}>
                {/* name */}
                <Td>
                  <Text textColor='black'>{item.table_name}</Text>
                </Td>
                {/* action */}
                <Td>
                  <Text>{item.action}</Text>
                </Td>
                {/* created */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {moment(item.created_at).format('MMM DD, YYYY')}
                  </Text>
                  <Text fontSize='sm'>
                    {moment.utc(item.created_at).local().format('HH:mm a')}
                  </Text>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className='no-record'>
              <Td colSpan='3'>
                <Text fontSize='sm'>No records found</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default UserEventsTable;
