import './ChatPage.scss';
import ChannelListContainer from './ChannelListContainer';
import { Chat } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import { STREAM_API_KEY } from '../../application/services/utils';
import 'stream-chat-react/dist/css/index.css';
import { useState, useEffect } from 'react';
import ChannelContainer from './ChannelContainer';
import { useSelector } from 'react-redux';

const client = StreamChat.getInstance(STREAM_API_KEY);

function ChatPage() {
  const [createType, setCreateType] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userConnected, setUserConnected] = useState(false);

  const { user } = useSelector((state) => state.user);

  const token = user.getstream_token;

  const organisations = user.user_orgs;

  let organisationIDs = [];

  for (let i = 0; i < organisations.length; i++) {
    organisationIDs.push(organisations[i].id);
  }

  useEffect(() => {
    async function connectUser() {
      if (token && !userConnected) {
        client.connectUser(
          {
            id: user.user.id,
            name: `${user.user.first_name} ${user.user.last_name}`,
            fullName: `${user.user.first_name} ${user.user.last_name}`,
            gender: user.user.gender,
            dob: user.user.dob,
            status: user.user.status,
            organisations: organisationIDs,
          },
          token
        );
        setUserConnected(true);
      }
    }

    connectUser();
  }, []);
  return (
    <div className='chat'>
      <div className='app__wrapper'>
        <Chat client={client} theme='team light'>
          {/* <div className="chatBody"> */}
          <ChannelListContainer
            isCreating={isCreating}
            setIsCreating={setIsCreating}
            setIsEditing={setIsEditing}
            setCreateType={setCreateType}
          />
          <ChannelContainer
            isCreating={isCreating}
            setIsCreating={setIsCreating}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            createType={createType}
          />

          {/* </div> */}
        </Chat>
      </div>
    </div>
  );
}

export default ChatPage;
