import {
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  IconButton,
  Input,
  InputGroup,
  Link,
  Select,
  Spacer,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  branchMemberUrl,
  createScheduleUrl,
  rotasUrl,
} from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import DepartmentModal from '../settings/pages/departments/DepartmentModal';
import ShiftHourModal from '../settings/pages/working_hours/ShiftHourModal';
import DateRangeModal from './DateRangeModal';

const RotaModal = ({
  triggerFetch,
  fetchRotas,
  fetchDepartments,
  fetchShifts,
  selectedRota,
  setSelectedRota,
  departments,
  shifts,
  membersInfo,
  setMembersInfo,
  isOpen,
  onClose,
  isEdit,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);

  const {
    isOpen: isOpenDepartmentModal,
    onOpen: onOpenDepartmentModal,
    onClose: onCloseDepartmentModal,
  } = useDisclosure();
  const {
    isOpen: isOpenShiftHourModal,
    onOpen: onOpenShiftHourModal,
    onClose: onCloseShiftHourModal,
  } = useDisclosure();
  const {
    isOpen: isOpenDateRangeModal,
    onOpen: onOpenDateRangeModal,
    onClose: onCloseDateRangeModal,
  } = useDisclosure();

  const [title, setTitle] = useState('');
  const [shiftHour, setShiftHour] = useState('');
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [department, setDepartment] = useState('');
  const [slots, setSlots] = useState(1);
  const [searchMember, setSearchMember] = useState('');

  const [isRotaCreated, setRotaCreated] = useState(false);

  const miniumDate = moment().format('YYYY-MM-DD');
  const [workHourItem, setWorkHourItem] = useState({});

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const [members, setMembers] = useState([]);

  // create rota
  const createRota = async () => {
    const startDate = moment(state?.[0]?.startDate).format('YYYY-MM-DD');
    if (moment(startDate).isSameOrAfter(moment().format('YYYY-MM-DD'))) {
      setRotaCreated(true);
      const headers = {
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .post(
          createScheduleUrl,
          {
            title: title,
            department_id: department,
            start_date: moment(state?.[0]?.startDate).format('YYYY-MM-DD'),
            end_date: moment(state?.[0]?.endDate).format('YYYY-MM-DD'),
            shift_hour_id: shiftHour,
            slots: parseInt(slots) ?? 1,
            branch_member_ids: members,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setRotaCreated(false);

          fetchDepartments();
          setSearchMember('');
          setFilterMemberData([]);
          setMembers([]);
          setMembersInfo([]);
          // setMemberData([]);
          fetchRotas();
          triggerFetch();
          onClose();
          toast({
            position: 'bottom-left',
            title: 'Rota created successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((err) => {
          setRotaCreated(false);
          handleError(
            err,
            'Failed to create rota.',
            createRota,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    } else {
      toast({
        position: 'bottom-left',
        title: 'Start date cannot be earlier than today.',
        // description: err?.response?.data?.detail?.err_msg,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  //   update rota
  const updateRota = async () => {
    setRotaCreated(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(
        `${rotasUrl}${selectedRota.id}/`,
        {
          title: title,
          department_id: selectedRota.department_id,
          //   slots: parseInt(slots),
          //   branch_member_ids: members,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setRotaCreated(false);

        fetchDepartments();
        setSearchMember('');
        setFilterMemberData([]);
        setMembers([]);
        setMembersInfo([]);
        // setMemberData([]);
        fetchRotas();
        triggerFetch();
        onClose();
        toast({
          position: 'bottom-left',
          title: `${selectedRota.title} updated successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setRotaCreated(false);
        handleError(
          err,
          'Failed to update rota.',
          updateRota,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      updateRota();
    } else {
      createRota();
    }
  };

  const [filterMemberData, setFilterMemberData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  // const searchUser = () => {
  //   setSearchLoading(true);

  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${access_token}`,
  //     'organisation-id': organisation?.id,
  //   };
  //   axios
  //     .get(branchMemberUrl, {
  //       params: { branch_id: branch ?? '', email: searchMember },
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       setSearchLoading(false);
  //       setFilterMemberData(res.data.items);
  //     })
  //     .catch((err) => {
  //       setSearchLoading(false);
  //       handleError(
  //         err,
  //         'Failed to search user.',
  //         searchUser,
  //         access_token,
  //         dispatch,
  //         toast,
  //         navigate
  //       );
  //     });
  // };

  // fetch branch members
  const fetchBranchMembers = async () => {
    if (roles?.[organisation?.id]?.includes('BranchMembers.list')) {
      setSearchLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .get(branchMemberUrl, {
          params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((res) => {
          setSearchLoading(false);
          setFilterMemberData(res.data.items);
        })
        .catch((err) => {
          setSearchLoading(false);
          handleError(
            err,
            'Failed to fetch branch members.',
            fetchBranchMembers,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  };

  useEffect(() => {
    // Trigger the fetch
    fetchBranchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRota]);

  // const [memberData, setMemberData] = useState([]);
  // const [searchResult, setSearchResult] = useState([]);
  // let cancelToken = null;

  // const onChange = async (e) => {
  //   if (cancelToken) {
  //     cancelToken.cancel();
  //   }
  //   cancelToken = axios.CancelToken.source();
  //
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${access_token}`,
  //     'organisation-id': organisation?.id,
  //   };
  //   await axios
  //     .get(branchMemberUrl, {
  //       cancelToken: cancelToken.token,
  //       params: { branch_id: branch ?? '' },
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       setMemberData(res.data.items);
  //     })
  //     .catch((e) => {
  //       if (axios.isCancel(e) || e) {
  //         console.log('Data not found.');
  //       }
  //     });
  //   let stringKwd = searchMember.toLowerCase();
  //   let filterData = memberData.filter((item) => {
  //     return (
  //       item.org_member.user.first_name.toLowerCase().indexOf(stringKwd) !== -1
  //     );
  //   });
  //   setFilterMemberData(filterData);
  // };

  return (
    <Box>
      <Drawer
        size='md'
        isOpen={isOpen}
        placement='right'
        onClose={() => {
          setSelectedRota({});
          onClose();
        }}
        // finalFocusRef={btnRef}
      >
        <form onSubmit={handleSubmit}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody>
              <Container mt={8}>
                <Text as='b' fontSize='lg'>
                  {isEdit ? 'Edit Rota' : 'Create rota'}
                </Text>
                <Text fontSize='sm' mb={8} mt={2}>
                  Create rota to track and manage employees shifts
                </Text>

                {/* title */}
                <Flex>
                  <Text fontSize={'sm'}>Title</Text>
                  <Text fontSize={'sm'} color='red'>
                    *
                  </Text>
                </Flex>
                <InputGroup size='md' mb={8} mt={2}>
                  <Input
                    isRequired={true}
                    type={'text'}
                    pr='4.5rem'
                    placeholder='Enter your title'
                    defaultValue={selectedRota.title}
                    focusBorderColor='#107e76'
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </InputGroup>
                {!isEdit ? (
                  <div>
                    {/* shift hour */}
                    <Flex>
                      <Text fontSize={'sm'}>Shift hour</Text>
                      <Text fontSize={'sm'} color='red'>
                        *
                      </Text>
                      <Spacer />
                      {/* create shift hour */}
                      <Link
                        fontSize={'sm'}
                        variant='ghost'
                        textColor='#107e76'
                        onClick={() => {
                          onOpenShiftHourModal();
                        }}
                      >
                        Create shift hour
                      </Link>
                    </Flex>
                    <Select
                      required={true}
                      focusBorderColor='#107e76'
                      placeholder='Select your shift hour'
                      onChange={(e) => setShiftHour(e.target.value)}
                      mb={8}
                      mt={2}
                    >
                      {shifts
                        .filter((item) => item.is_active)
                        .map((shift) => {
                          return (
                            <option key={shift.id} value={shift.id}>
                              {shift.name}
                            </option>
                          );
                        })}
                    </Select>
                    {/* <Flex mb={4}>
                      <Text fontSize={'sm'}>Start and end date</Text>
                      <Text fontSize={'sm'} color='red'>
                        *
                      </Text>
                    </Flex>
                    <Center>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        rangeColors={['#107e76']}
                        
                      />
                    </Center> */}

                    <Flex mb={8}>
                      {/* start date */}
                      <Box>
                        <Flex>
                          <Text fontSize={'sm'}>Start date</Text>
                          <Text fontSize={'sm'} color='red'>
                            *
                          </Text>
                        </Flex>
                        <Input
                          style={{ caretColor: 'transparent' }}
                          placeholder='Select End Date'
                          readonly
                          value={moment(state?.[0]?.startDate).format(
                            'MMM DD, YYYY'
                          )}
                          onClick={() => onOpenDateRangeModal()}
                          // onChange={(e) => setEndDate(e.target.value)}
                          size='md'
                          type='text'
                          min={miniumDate}
                          mt={2}
                          focusBorderColor='#107e76'
                        />
                      </Box>

                      <Spacer />

                      {/* end date */}
                      <Box>
                        <Flex>
                          <Text fontSize={'sm'}>End date</Text>
                          <Text fontSize={'sm'} color='red'>
                            *
                          </Text>
                        </Flex>

                        <Input
                          style={{ caretColor: 'transparent' }}
                          placeholder='Select End Date'
                          readonly
                          value={moment(state?.[0]?.endDate).format(
                            'MMM DD, YYYY'
                          )}
                          onClick={() => onOpenDateRangeModal()}
                          // onChange={(e) => setEndDate(e.target.value)}
                          size='md'
                          type='text'
                          min={miniumDate}
                          mt={2}
                          focusBorderColor='#107e76'
                        />
                      </Box>
                    </Flex>
                  </div>
                ) : null}

                {roles?.[organisation?.id]?.includes('Rota.create') ? (
                  <div>
                    {/* department */}
                    <Flex align='center'>
                      <Text fontSize={'sm'}>Department</Text>
                      <Text fontSize={'sm'} color='red'>
                        *
                      </Text>
                      <Spacer />
                      {/* create department */}
                      <Link
                        fontSize={'sm'}
                        variant='ghost'
                        textColor='#107e76'
                        onClick={() => {
                          onOpenDepartmentModal();
                        }}
                      >
                        Create department
                      </Link>
                    </Flex>

                    <Select
                      focusBorderColor='#107e76'
                      placeholder='Select department'
                      isRequired={true}
                      defaultValue={selectedRota.department_id}
                      onChange={(e) => setDepartment(e.target.value)}
                      mb={8}
                      mt={2}
                    >
                      {departments
                        .filter((item) => item.is_active)
                        .map((department) => {
                          return (
                            <option key={department.id} value={department.id}>
                              {department.name}
                            </option>
                          );
                        })}
                    </Select>

                    {!isEdit ? (
                      <Box>
                        {organisation?.organisation_type !== 'INDIVIDUAL' && (
                          <div>
                            {/* number of slots */}
                            <Flex>
                              <Text fontSize={'sm'}>Number of slots</Text>
                              <Text fontSize={'sm'} color='red'>
                                *
                              </Text>
                            </Flex>
                            <InputGroup size='md' mb={8} mt={2}>
                              <Input
                                type={'number'}
                                pr='4.5rem'
                                min={1}
                                placeholder='Enter no of slots'
                                defaultValue={selectedRota.slots}
                                focusBorderColor='#107e76'
                                onChange={(e) => setSlots(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                        )}

                        {/* members */}
                        <Text fontSize={'sm'}>Branch members</Text>
                        <InputGroup size='md' mb={8} mt={2}>
                          <Input
                            type={'text'}
                            pr='4.5rem'
                            placeholder='Search name'
                            focusBorderColor='#107e76'
                            value={searchMember}
                            onChange={(e) => setSearchMember(e.target.value)}
                          />

                          {/* <InputRightElement width='4.5rem'>
                           <Button
                              variant={'unstyled'}
                              textColor='#107e76'
                              size='md'
                              isDisabled={searchMember === ''}
                              isLoading={searchLoading}
                              // onClick={() => searchUser()}
                            >
                              Search
                            </Button> 
                          </InputRightElement> */}
                        </InputGroup>
                        {searchMember !== '' &&
                          filterMemberData
                            .filter(
                              (member) =>
                                member?.org_member?.user.first_name
                                  ?.toLowerCase()
                                  .includes(searchMember?.toLowerCase()) ||
                                member?.org_member?.user.last_name?.includes(
                                  searchMember?.toLowerCase()
                                )
                            )
                            .map((item) => (
                              <Flex key={item.id} mb={4} alignItems='center'>
                                <Avatar
                                  size={'md'}
                                  name={`${item.org_member.user.first_name} ${item.org_member.user.last_name}`}
                                  backgroundColor='#C6DDDB'
                                  textColor={'#107e76'}
                                  fontWeight={'bold'}
                                  mr={4}
                                />
                                <Text key={item.id}>
                                  {item.org_member.user.first_name}{' '}
                                  {item.org_member.user.last_name}
                                </Text>
                                <Spacer />

                                {/* add members */}
                                <IconButton
                                  aria-label='Search database'
                                  icon={<MdAdd />}
                                  variant='unstyled'
                                  onClick={() => {
                                    setSearchMember('');
                                    setMembers([...members, `${item.id}`]);
                                    setMembersInfo([...membersInfo, item]);
                                    // remove user from filter
                                    setFilterMemberData(
                                      filterMemberData?.filter(
                                        (a) => a.id !== item.id
                                      )
                                    );
                                  }}
                                />
                              </Flex>
                            ))}
                        <Box mt={8}></Box>

                        {membersInfo.length > 0
                          ? membersInfo.map((item, index) => (
                              <Tag
                                key={item?.id}
                                mr={3}
                                mb={3}
                                size={'lg'}
                                borderRadius='full'
                                variant='solid'
                                background='#107e76'
                                textColor='white'
                              >
                                <TagLabel>{`${item?.org_member?.user?.first_name}`}</TagLabel>
                                {/* remove members */}
                                <TagCloseButton
                                  onClick={() => {
                                    setMembers(
                                      members.filter((a) => a !== `${item.id}`)
                                    );
                                    setMembersInfo(
                                      membersInfo.filter(
                                        (a) => a.id !== item.id
                                      )
                                    );
                                    // add user back to filter
                                    setFilterMemberData([
                                      ...filterMemberData,
                                      item,
                                    ]);
                                  }}
                                />
                              </Tag>
                            ))
                          : null}
                      </Box>
                    ) : null}
                  </div>
                ) : null}
              </Container>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant='outline'
                mr={3}
                onClick={() => {
                  setFilterMemberData([]);
                  setSearchMember('');
                  setMembers([]);
                  setMembersInfo([]);
                  // setMemberData([]);
                  setSelectedRota({});
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant='solid'
                backgroundColor={'#107e76'}
                textColor='white'
                isLoading={isRotaCreated}
                type='submit'
                isDisabled={
                  isEdit
                    ? !roles?.[organisation?.id]?.includes('Roles.update')
                    : !roles?.[organisation?.id]?.includes('Roles.create')
                }
              >
                Save
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>

      {/* create shift hour */}
      <ShiftHourModal
        fetchWorkingHours={fetchShifts}
        workHourItem={workHourItem}
        setWorkHourItem={setWorkHourItem}
        isOpen={isOpenShiftHourModal}
        onClose={onCloseShiftHourModal}
        active={true}
      />

      {/* department modal */}
      <DepartmentModal
        fetchDepartments={fetchDepartments}
        isEdit={false}
        isOpen={isOpenDepartmentModal}
        onClose={onCloseDepartmentModal}
      />

      {/* date range modal */}
      <DateRangeModal
        isOpen={isOpenDateRangeModal}
        onClose={onCloseDateRangeModal}
        state={state}
        setState={setState}
      />
    </Box>
  );
};

export default RotaModal;
