import { Button, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  continueString,
  emailLabelString,
  emailPlaceHoldeString,
  forgotPasswordPageOneDescString,
  forgotPasswordPageOneTitleString,
} from '../../application/services/app_strings';
import { forgotPasswordUrl } from '../../application/services/http_endpoints';
import { addContactId, addEmail } from '../../redux/user/userForgotPassword';
import InputField from '../core/components/input_field/InputField';
import './ForgotPasswordPageOne.scss';
import { ratibaLogoGreenIconSvgPath } from '../../application/services/asset_strings';
import { validateEmail } from '../../application/services/utils';

const ForgotPasswordPageOne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [isLoading, setLoading] = useState(false);

  const { email } = useSelector((state) => state.userForgotPassword);

  const [error, setError] = useState('');

  const sendOTP = async () => {
    setLoading(true);
    axios
      .post(forgotPasswordUrl, {
        email: email,
      })
      .then((response) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'OTP sent successfully.',
          description: response.data.msg,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        dispatch(addContactId(response.data.contact_id));
        navigate('/otp');
      })
      .catch((err) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'Failed to send OTP.',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setError('Enter a valid email.');
    } else {
      setError('');
      sendOTP();
    }
  };

  return (
    <div className='forgot-password-container'>
      <img src={ratibaLogoGreenIconSvgPath} />
      <div className='forgot-password-header'>
        {/* Title */}
        <h2 className='title'>{forgotPasswordPageOneTitleString}</h2>

        {/* desc */}
        <p className='desc'>{forgotPasswordPageOneDescString}</p>
      </div>
      <form className='forgot-password-form' onSubmit={handleSubmit}>
        {/* Email */}
        <InputField
          title={emailLabelString}
          isRequired={true}
          placeholder={emailPlaceHoldeString}
          type='email'
          onChange={(e) => dispatch(addEmail(e.target.value))}
        />

        {error && <div style={{ color: 'red' }}>{error}</div>}

        {/* Continue */}
        <Button
          background='#107e76'
          textColor='white'
          type='submit'
          isLoading={isLoading}
        >
          {continueString}
        </Button>
      </form>

      {/* back to login */}
      <Button align='center' variant='unstyled' onClick={() => navigate(-1)}>
        Back to login
      </Button>
    </div>
  );
};

export default ForgotPasswordPageOne;
