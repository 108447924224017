import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
};

export const userRegistrationSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserRegistration: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUserRegistration } = userRegistrationSlice.actions;

export default userRegistrationSlice.reducer;
