import { Box, Card, CardBody, CardHeader, Text } from '@chakra-ui/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

const StackedHours = ({
  organisationReports,
  branchReports,
  departmentReports,
  reportsFilter,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  var labels = organisationReports?.organisation_roles_working_hours?.map(
    function (a) {
      return a.role_name;
    }
  );

  const data = {
    labels,
    datasets: [
      {
        label: 'This month',
        data: organisationReports?.organisation_roles_working_hours?.map(
          function (a) {
            return a?.working_hours?.this_month?.total_hrs;
          }
        ),
        backgroundColor: '#107e76',
      },
      {
        label: 'Last month',
        data: organisationReports?.organisation_roles_working_hours?.map(
          function (a) {
            return a?.working_hours?.last_month?.total_hrs;
          }
        ),
        backgroundColor: '#F69520',
      },
    ],
  };

  return (
    <Card m={4} w={'100%'}>
      <CardHeader>
        <Text as='b'>Hours</Text>
      </CardHeader>
      <CardBody>
        <Box p={2} h={'35vh'} align='center'>
          <Bar
            options={options}
            data={reportsFilter === 'organisation' ? data : data}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

export default StackedHours;
