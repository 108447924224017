import { Tag, Th } from '@chakra-ui/react';

const ShiftDayHeader = ({ day, currentWeekDay, currentDay, fDay, filter }) => {
  return currentWeekDay !== day ? (
    filter === 'week' ? (
      <Th fontSize={'xs'}>{`${fDay ?? ''} ${day}`}</Th>
    ) : (
      <Th />
    )
  ) : (
    <Th>
      <Tag
        fontSize={'xs'}
        backgroundColor='#107e76'
        variant='solid'
        borderRadius='full'
      >
        {`${fDay ?? ''} ${day}`}
      </Tag>
    </Th>
  );
};

export default ShiftDayHeader;
