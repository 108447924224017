import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from './navigation/navigation';
import userReducer from './user/user';
import userRegistrationReducer from './user/userRegistration';
import userForgotPasswordReducer from './user/userForgotPassword';
import organisationRegistrationReducer from './organisation/organisationRegistration';
import organisationReducer from './organisation/organisation';
import rolesReducer from './roles/roles';
import tourReducer from './tour/tour';
import subscriptionReducer from './organisation/subscription';
import inviteReducer from './invite/invite';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const userPersistConfig = {
  key: 'user',
  storage,
};
const navigationrPersistConfig = {
  key: 'navigation',
  storage,
};
const organisationPersistConfig = {
  key: 'organisation',
  storage,
};
const rolesPersistConfig = {
  key: 'roles',
  storage,
};

const tourPersistConfig = {
  key: 'tour',
  storage,
};

const subscriptionPersistConfig = {
  key: 'subscription',
  storage,
};

const invitePersistConfig = {
  key: 'invite',
  storage,
};

export const store = configureStore({
  reducer: {
    navigation: persistReducer(navigationrPersistConfig, navigationReducer),
    user: persistReducer(userPersistConfig, userReducer),
    userRegistration: userRegistrationReducer,
    userForgotPassword: userForgotPasswordReducer,
    organisationRegistration: organisationRegistrationReducer,
    organisation: persistReducer(
      organisationPersistConfig,
      organisationReducer
    ),
    roles: persistReducer(rolesPersistConfig, rolesReducer),
    tour: persistReducer(tourPersistConfig, tourReducer),
    subscription: persistReducer(
      subscriptionPersistConfig,
      subscriptionReducer
    ),
    invite: persistReducer(invitePersistConfig, inviteReducer),
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);
