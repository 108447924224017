import { Button, Center, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  acceptInviteUrl,
  checkUserExistUrl,
} from '../../application/services/http_endpoints';
import {
  handleError,
  useLogout,
} from '../../application/services/http_service';
import './Invite.scss';
import {
  resetInvite,
  setInviteObj,
  setInvited,
} from '../../redux/invite/invite';
import { BounceLoader } from 'react-spinners';

const AcceptInvitePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { logoutUser } = useLogout();

  const { user, access_token, loggedIn } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const { inviteObj, isInvited } = useSelector((state) => state.invite);

  // set user invited to state
  dispatch(setInvited(false));

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const [token, setToken] = useState(query.get('token'));

  const [actionText, setActionText] = useState('');
  const [isChecking, setChecking] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [userExists, setUserExists] = useState();

  // check if user exists
  async function checkUserExist() {
    if (!isInvited) {
      setChecking(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      };
      axios
        .get(`${checkUserExistUrl}${token ?? inviteObj?.token}/`, {
          headers: headers,
        })
        .then((response) => {
          setChecking(false);
          // setRotas(response.data.items);
          setUserExists(response.data.user_exists);
          dispatch(setInviteObj(response.data));

          // user doesn't exist and is logged out
          if (!inviteObj?.user_exists && !loggedIn)
            setActionText('Create Account');
          // user exists and is logged out
          else if (inviteObj?.user_exists && !loggedIn) setActionText('Log In');
          // user exists and is logged in
          else setActionText('Accept');
        })
        .catch((err) => {
          setChecking(false);
          handleError(
            err,
            'Failed to validate invite.',
            checkUserExist,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  // accept invite
  async function acceptInvite() {
    // user doesn't exist and is logged out
    if (!inviteObj?.user_exists && !loggedIn) {
      dispatch(setInvited(true));
      navigate('/register');
    }
    // user exists and is logged out
    else if (inviteObj?.user_exists && !loggedIn) {
      dispatch(setInvited(true));
      navigate('/');
    }
    // user doesn't exist and is logged in
    else if (
      !inviteObj?.user_exists &&
      loggedIn &&
      inviteObj?.email !== email
    ) {
      dispatch(setInvited(true));
      logoutUser();
      navigate('/register');
    } else {
      // user exists and is logged in
      setLoading(true);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': inviteObj?.organisation_id,
      };
      axios
        .post(
          `${acceptInviteUrl}${inviteObj?.token}/accept/`,
          {},
          {
            headers: headers,
          }
        )
        .then((response) => {
          setLoading(false);
          setToken('');
          dispatch(resetInvite());

          toast({
            position: 'bottom-left',
            title: 'Invite Accepted.',
            description: `You have successfully joined ${inviteObj?.organisation_name}.`,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate('/');
          // setRolesList(response.data.items);
        })
        .catch((err) => {
          setLoading(false);
          handleError(
            err,
            'Failed to accept invite.',
            acceptInvite,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  useEffect(() => {
    // Trigger the fetch
    checkUserExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const email =
    user?.user_contacts?.[0]?.contact?.contact_type === 'EMAIL'
      ? user?.user_contacts?.[0]?.contact?.contact_value
      : user?.user_contacts?.[1]?.contact?.contact_value;

  return isChecking ? (
    <Center className='invite-container'>
      <BounceLoader color={'#107e76'} loading={isChecking} size={80} />
    </Center>
  ) : (
    <div className='invite-container'>
      <div className='invite-header'>
        {/* Title */}
        <h2 className='title'>Organisation Invite</h2>

        {/* desc */}
        {actionText ? (
          !inviteObj?.user_exists && !loggedIn ? (
            <Text fontSize='sm'>
              To accept the invite create an account using{' '}
              {inviteObj?.email ?? 'the invited email'} or log in to that
              account.
            </Text>
          ) : (
            <Text fontSize='sm'>
              You have been invited to join {inviteObj?.organisation_name}{' '}
              organisation with the account {inviteObj?.email}.
            </Text>
          )
        ) : (
          'The invite has already been accepted or has expired.'
        )}
      </div>

      {/* Accept */}
      {actionText && (
        <Button
          backgroundColor='#107e76'
          textColor='white'
          type='submit'
          isLoading={isLoading}
          onClick={() => acceptInvite()}
        >
          {actionText}
        </Button>
      )}

      <Button variant='outline' onClick={() => navigate('/')}>
        Cancel
      </Button>

      {/* Decline */}

      {/* <Button
        align='center'
        variant='unstyled'
        textColor='red'
        onClick={() => navigate('/')}
      >
        Decline
      </Button> */}
    </div>
  );
};

export default AcceptInvitePage;
