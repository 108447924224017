import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Select,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { continueString } from '../../application/services/app_strings';
import { registerUrl } from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import InputField from '../core/components/input_field/InputField';

const InviteMemberPage = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { access_token } = useSelector((state) => state.user);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [otherName, setOtherName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');
  const [isLoading, setLoading] = useState(false);

  const inviteMember = async () => {
    setLoading(true);
    axios
      .post(registerUrl, {
        user: {
          first_name: firstName,
          last_name: lastName,
          surname: otherName,
          gender: gender,
          dob: dob,
        },
        contacts: [
          {
            contact_type: 'PHONE',
            contact_value: phoneNumber,
            is_primary: true,
          },
          {
            contact_type: 'EMAIL',
            contact_value: email,
            is_primary: false,
          },
        ],
      })
      .then((response) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'Member registered successfully!',
          description:
            'An invite has been sent to their email containing their log in credentials.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Registration failed.',
          inviteMember,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    inviteMember();
  };
  const maxDate = moment().subtract(18, 'years').format('YYYY-MM-DD');
  return (
    <div className='register-container'>
      <Card>
        <CardBody backgroundColor='white' p={8}>
          <Box className='register-header' mb={8}>
            <h2 className='title'>Invite Member</h2>
            <p className='desc'>Create your member's account</p>
            <Box
              mt={2}
              h={1.5}
              backgroundColor='#EF9920'
              borderRadius={8}
            ></Box>
          </Box>
          {/* <div className='register-prog-indicator'>
        <div className='first active'></div>
        <div className='second active'></div>
      </div> */}

          <form className='register-form' onSubmit={handleSubmit}>
            <Flex display={{ md: 'flex' }}>
              <Box mr={2}>
                <InputField
                  title='First name'
                  isRequired={true}
                  placeholder='Enter first name'
                  type='text'
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
              <Spacer />
              <Box mr={2}>
                <InputField
                  title='Last name'
                  isRequired={true}
                  placeholder='Enter last name'
                  type='text'
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
              <Spacer />
              <Box mr={2}>
                <InputField
                  title='Other names'
                  isRequired={true}
                  placeholder='Enter other names'
                  type='text'
                  onChange={(e) => setOtherName(e.target.value)}
                />
              </Box>
            </Flex>

            <div className='input-row'>
              <InputField
                title='Work email address'
                isRequired={true}
                placeholder='Enter email address'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputField
                title='Phone number'
                isRequired={true}
                placeholder='Enter phone number'
                type='number'
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <InputField
              title='Date of Birth'
              isRequired={true}
              placeholder='Enter your DOB'
              type='date'
              max={maxDate}
              onChange={(e) => setDOB(e.target.value)}
            />
            <Box>
              <Flex>
                <Text>Gender</Text>
                <Text textColor={'red'}>*</Text>
              </Flex>
              <Select
                selected={gender}
                setSelected={setGender}
                placeholder='Select gender'
                isRequired={true}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value='MALE' selected>
                  MALE
                </option>
                <option value='FEMALE'>FEMALE</option>
              </Select>
            </Box>

            {/* Continue */}
            <Button
              backgroundColor='#107e76'
              textColor='white'
              type='submit'
              isLoading={isLoading}
              mt={6}
            >
              {continueString}
            </Button>
          </form>

          {/* back to members */}
          <div className='register-header'>
            <Button
              mt={4}
              align='center'
              variant='unstyled'
              onClick={() => navigate(-1)}
            >
              Back to members
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default InviteMemberPage;
