import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ProceduresTable = ({
  procedures,
  openModal,
  setSelectedProcedure,
  onOpenDeactivateModal,
  setItems,
}) => {
  const { roles } = useSelector((state) => state.roles);
  const { organisation } = useSelector((state) => state.organisation);

  return (
    <TableContainer backgroundColor={'white'} m={12}>
      <Table>
        <Thead>
          <Tr>
            <Th>Record no</Th>
            <Th>Procedure name</Th>
            <Th>Doctor's name</Th>
            <Th>Payment type</Th>
            <Th>Procedure amount</Th>
            <Th>Status</Th>
            <Th>Date created</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {procedures?.length > 0 ? (
            procedures
              ?.sort((a, b) => new Date(a.created_at) + new Date(b.created_at))
              ?.map((item) => (
                <Tr key={item.id}>
                  {/* record no */}
                  <Td>
                    <Text textColor='black' fontSize='sm'>
                      {item.record_no ?? '-'}
                    </Text>
                  </Td>
                  {/* procedure name */}
                  <Td>
                    <Text textColor='black' fontSize='sm'>
                      {item.procedure_name}
                    </Text>
                  </Td>
                  {/* doctor's name */}
                  <Td>
                    <Text textColor='black' fontSize='sm'>
                      {`${item.doctor.org_member.user.first_name} ${item.doctor.org_member.user.last_name} `}
                    </Text>
                  </Td>
                  {/* payment type */}
                  <Td>
                    <Text textColor='black' fontSize='sm'>
                      {item.payment_type}
                    </Text>
                  </Td>
                  {/* amount */}
                  <Td>
                    <Text textColor='black' fontSize='sm'>
                      {item.procedure_cost.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'KES',
                      })}
                    </Text>
                  </Td>
                  {/* status */}
                  <Td>
                    <Text textColor='black' fontSize='sm'>
                      {item.status ?? '-'}
                    </Text>
                  </Td>
                  {/* created */}
                  <Td>
                    <Text textColor='black' fontSize='sm'>
                      {moment(item.created_at).format('MMM DD, YYYY')}
                    </Text>
                    <Text fontSize='sm'>
                      {moment.utc(item.created_at).local().format('HH:mm a')}
                    </Text>
                  </Td>

                  {/* actions */}
                  <Td>
                    <Button
                      backgroundColor='#DAF2F0'
                      textColor='#107e76'
                      size='sm'
                      // isDisabled={
                      //   !roles?.[organisation?.id]?.includes('Procedures.update')
                      // }
                      onClick={() => {
                        setSelectedProcedure(item);
                        setItems(item?.items);
                        openModal(true);
                      }}
                      mr={4}
                    >
                      Edit Procedure
                    </Button>

                    {/* <Button
                    backgroundColor={item?.is_active ? '#F8E0E5' : '#DAF2F0'}
                    textColor={item?.is_active ? '#C80024' : '#107e76'}
                    size='sm'
                    isDisabled={
                      !roles?.[organisation?.id]?.includes('Procedures.update')
                    }
                    onClick={() => {
                      setSelectedProcedure(item);
                      onOpenDeactivateModal();
                    }}
                  >
                    {item?.is_active ? 'Deactivate' : 'Activate'}
                  </Button> */}
                  </Td>
                </Tr>
              ))
          ) : (
            <Tr className='no-record'>
              <Td colSpan='5'>
                <Text fontSize='sm'>No records found</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ProceduresTable;
