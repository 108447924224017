import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Select,
  SimpleGrid,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { addMonths, addYears, subMonths, subYears } from 'date-fns';
import moment from 'moment';
import { useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { BounceLoader } from 'react-spinners';
import {
  trackYourShiftsDescriptionString,
  trackYourShiftsString,
} from '../../application/services/app_strings';
import { yearCuateIconSvgPath } from '../../application/services/asset_strings';
import CalendarModal from '../rota/CalendarModal';
import './HomePage.scss';
import ShiftCard from './ShiftCard';
import ShiftDayHeader from './ShiftDayHeader';

const ShiftsWrapper = ({
  sun,
  mon,
  tue,
  wed,
  thu,
  fri,
  sat,
  sunSifts,
  monShifts,
  tueShifts,
  wedShifts,
  thuShifts,
  friShifts,
  satShifts,
  selectedDate,
  setSelectedDate,
  curr,
  shiftWrapperLoading,
  shiftFilter,
  setShiftFilter,
  triggerFetch,
  resetShifts,
}) => {
  const {
    isOpen: isOpenCalendarModal,
    onOpen: onOpenCalendarModal,
    onClose: onCloseCalendarModal,
  } = useDisclosure();

  const first = +curr.startOf('week').format('DD');
  const second = +curr.startOf('week').add(1, 'days').format('DD');
  const third = +curr.startOf('week').add(2, 'days').format('DD');
  const fourth = +curr.startOf('week').add(3, 'days').format('DD');
  const fifth = +curr.startOf('week').add(4, 'days').format('DD');
  const sixth = +curr.startOf('week').add(5, 'days').format('DD');
  const seventh = +curr.startOf('week').add(6, 'days').format('DD');

  useEffect(() => {
    // Trigger the fetch
    triggerFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftFilter, selectedDate]);

  return (
    <div>
      {shiftWrapperLoading ? (
        <Center>
          <BounceLoader
            color={'#107e76'}
            loading={shiftWrapperLoading}
            size={80}
          />
        </Center>
      ) : (
        <div>
          {/* <Shifts /> */}
          <Flex ml={10} mt={10} mb={10} align='center'>
            <div>
              <Heading as='h4' size='md' mb={2}>
                Shifts
              </Heading>
              <Text noOfLines={1} fontSize='sm'>
                {moment(selectedDate).format('ddd DD, MMM, YYYY')}
              </Text>
            </div>
            <Spacer />
            {/* calendar filter */}
            <Flex align='center'>
              <IconButton
                variant='ghost'
                aria-label='previous'
                textColor='#107e76'
                icon={<FiChevronsLeft />}
                onClick={() => {
                  resetShifts();
                  setSelectedDate(subYears(selectedDate, 1));
                }}
              />
              <IconButton
                variant='ghost'
                aria-label='previous'
                textColor='#107e76'
                icon={<FiChevronLeft />}
                onClick={() => {
                  resetShifts();
                  setSelectedDate(subMonths(selectedDate, 1));
                }}
              />
              <Spacer />
              <Button
                fontSize='sm'
                textColor='#107e76'
                onClick={() => onOpenCalendarModal()}
              >
                {moment(selectedDate).format('MMM DD, YYYY')}
              </Button>
              <Spacer />
              <IconButton
                variant='ghost'
                aria-label='previous'
                textColor='#107e76'
                icon={<FiChevronRight />}
                onClick={() => {
                  resetShifts();
                  setSelectedDate(addMonths(selectedDate, 1));
                }}
              />
              <IconButton
                variant='ghost'
                aria-label='previous'
                textColor='#107e76'
                icon={<FiChevronsRight />}
                onClick={() => {
                  resetShifts();
                  setSelectedDate(addYears(selectedDate, 1));
                }}
              />
            </Flex>

            <Spacer />

            <Box>
              <Text noOfLines={1} fontSize='sm' mr={2}>
                Filter by:
              </Text>
              <Select
                variant='unstyled'
                placeholder='Select filter'
                value={shiftFilter}
                size='sm'
                onChange={(e) => {
                  resetShifts();
                  setShiftFilter(e.target.value);
                }}
              >
                <option value={'day'}>Day</option>
                <option value={'week'}>Week</option>
              </Select>
            </Box>
          </Flex>

          {sunSifts?.length +
            monShifts?.length +
            tueShifts?.length +
            wedShifts?.length +
            thuShifts?.length +
            friShifts?.length +
            satShifts?.length >
          0 ? (
            shiftFilter === 'week' ? (
              // week view
              <TableContainer m={4}>
                <Table size='sm' variant='unstyled'>
                  <Thead mb={5}>
                    <Tr>
                      <ShiftDayHeader
                        day={'Sun'}
                        currentWeekDay={moment(selectedDate).format('ddd')}
                        currentDay={moment(selectedDate).format('DD DDD')}
                        fDay={first}
                        filter={shiftFilter}
                      />
                      <ShiftDayHeader
                        day={'Mon'}
                        currentWeekDay={moment(selectedDate).format('ddd')}
                        currentDay={moment(selectedDate).format('DD DDD')}
                        fDay={second}
                        filter={shiftFilter}
                      />
                      <ShiftDayHeader
                        day={'Tue'}
                        currentWeekDay={moment(selectedDate).format('ddd')}
                        currentDay={moment(selectedDate).format('DD ddd')}
                        fDay={third}
                        filter={shiftFilter}
                      />
                      <ShiftDayHeader
                        day={'Wed'}
                        currentWeekDay={moment(selectedDate).format('ddd')}
                        currentDay={moment(selectedDate).format('DD ddd')}
                        fDay={fourth}
                        filter={shiftFilter}
                      />
                      <ShiftDayHeader
                        day={'Thu'}
                        currentWeekDay={moment(selectedDate).format('ddd')}
                        currentDay={moment(selectedDate).format('DD ddd')}
                        fDay={fifth}
                        filter={shiftFilter}
                      />
                      <ShiftDayHeader
                        day={'Fri'}
                        currentWeekDay={moment(selectedDate).format('ddd')}
                        currentDay={moment(selectedDate).format('DD ddd')}
                        fDay={sixth}
                        filter={shiftFilter}
                      />
                      <ShiftDayHeader
                        day={'Sat'}
                        currentWeekDay={moment(selectedDate).format('ddd')}
                        currentDay={moment(selectedDate).format('DD ddd')}
                        fDay={seventh}
                        filter={shiftFilter}
                      />
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr>
                      <Td>
                        {/* Sun */}
                        {sunSifts.map((eventData) => (
                          <ShiftCard
                            day={sun}
                            eventData={eventData}
                            triggerFetch={triggerFetch}
                          />
                        ))}
                      </Td>
                      {/* Mon */}
                      <Td>
                        {monShifts.map((eventData) => (
                          <ShiftCard
                            day={mon}
                            eventData={eventData}
                            triggerFetch={triggerFetch}
                          />
                        ))}
                      </Td>
                      {/* Tue */}
                      <Td>
                        {tueShifts.map((eventData) => (
                          <ShiftCard
                            day={tue}
                            eventData={eventData}
                            triggerFetch={triggerFetch}
                          />
                        ))}
                      </Td>
                      {/* Wed */}
                      <Td>
                        {wedShifts.map((eventData) => (
                          <ShiftCard
                            day={wed}
                            eventData={eventData}
                            triggerFetch={triggerFetch}
                          />
                        ))}
                      </Td>
                      {/* Thur */}
                      <Td>
                        {thuShifts.map((eventData) => (
                          <ShiftCard
                            day={thu}
                            eventData={eventData}
                            triggerFetch={triggerFetch}
                          />
                        ))}
                      </Td>
                      {/* Fri */}
                      <Td>
                        {friShifts.map((eventData) => (
                          <ShiftCard
                            day={fri}
                            eventData={eventData}
                            triggerFetch={triggerFetch}
                          />
                        ))}
                      </Td>
                      {/* Sat */}
                      <Td>
                        {satShifts.map((eventData) => (
                          <ShiftCard
                            day={sat}
                            eventData={eventData}
                            triggerFetch={triggerFetch}
                          />
                        ))}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              // day view
              <SimpleGrid columns={{ sm: 2, md: 4 }} spacing={4} align='center'>
                {/* Sun */}
                {sunSifts.map((eventData) => (
                  <ShiftCard
                    day={sun}
                    eventData={eventData}
                    triggerFetch={triggerFetch}
                  />
                ))}

                {/* Mon */}

                {monShifts.map((eventData) => (
                  <ShiftCard
                    day={mon}
                    eventData={eventData}
                    triggerFetch={triggerFetch}
                  />
                ))}

                {/* Tue */}

                {tueShifts.map((eventData) => (
                  <ShiftCard
                    day={tue}
                    eventData={eventData}
                    triggerFetch={triggerFetch}
                  />
                ))}

                {/* Wed */}

                {wedShifts.map((eventData) => (
                  <ShiftCard
                    day={wed}
                    eventData={eventData}
                    triggerFetch={triggerFetch}
                  />
                ))}

                {/* Thur */}

                {thuShifts.map((eventData) => (
                  <ShiftCard
                    day={thu}
                    eventData={eventData}
                    triggerFetch={triggerFetch}
                  />
                ))}

                {/* Fri */}

                {friShifts.map((eventData) => (
                  <ShiftCard
                    day={fri}
                    eventData={eventData}
                    triggerFetch={triggerFetch}
                  />
                ))}

                {/* Sat */}

                {satShifts.map((eventData) => (
                  <ShiftCard
                    day={sat}
                    eventData={eventData}
                    triggerFetch={triggerFetch}
                  />
                ))}
              </SimpleGrid>
            )
          ) : (
            <Center>
              <Box align='center' w={'50%'}>
                <img src={yearCuateIconSvgPath} alt='track your shift' />
                <h4>{trackYourShiftsString}</h4>
                <p>{trackYourShiftsDescriptionString}</p>
              </Box>
            </Center>
          )}
          {/* date range modal */}
          <CalendarModal
            isOpen={isOpenCalendarModal}
            onClose={onCloseCalendarModal}
            date={selectedDate}
            setDate={setSelectedDate}
            resetShifts={resetShifts}
          />
        </div>
      )}
    </div>
  );
};

// const ShiftsMonthly = () => {
//   const [value, onChange] = useState(new Date());

//   return (
//     <TableContainer m={5}>
//       <Flex ml={20} mt={10} mb={10} mr={20}>
//         <Heading size='lg' mr={20}>
//           Shifts
//         </Heading>
//         <Spacer />
//         {/* create shift */}
//         <Box w='200px'>
//           <Button background='#107e76' textColor='white' >Create Shift</Button>
//         </Box>
//       </Flex>
//       <Table variant='simple' size='lg'>
//         <Tbody>
//           <Tr>
//             {/* slots */}
//             <Td>
//               <SimpleGrid
//                 spacing={4}
//                 templateColumns='repeat(auto-fill, minmax(140px, 1fr))'
//                 ml={6}
//               >
//                 {Events.map((data) => (
//                   <Card bg='white' mr={5} key={data.id}>
//                     <CardBody>
//                       <Heading mb={4} size='sm' noOfLines={1}>
//                         {data.name}
//                       </Heading>
//                       <Text mb={4} fontSize='xs'>
//                         {data.time}
//                       </Text>
//                       <Tag mb={4} colorScheme='yellow' variant='outline'>
//                         Full Day
//                       </Tag>
//                       <Avatars />
//                     </CardBody>
//                   </Card>
//                 ))}
//               </SimpleGrid>
//             </Td>
//             {/* calendar */}
//             <div>
//               <Center mt={4}>
//                 <Calendar onChange={onChange} value={value} />
//               </Center>
//               <br />
//               <p>{value && value.toDateString()}</p>
//             </div>
//           </Tr>
//         </Tbody>
//       </Table>
//     </TableContainer>
//   );
// };

export default ShiftsWrapper;
