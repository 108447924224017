import { PF } from './utils';

//App Logos
export const ratibaLogoIconSvgPath = PF + 'images/ratiba_logo.svg';
export const ratibaLogoGreenIconSvgPath = PF + 'images/ratiba_logo_green.svg';
export const minRatibaLogoIconSvgPath = PF + 'images/min_ratiba_logo.svg';

//Login page
export const loginPeopleIconSvgPath = PF + 'images/login_people_image.svg';

//GIFs
export const successGifPath = PF + 'gifs/success.gif';
export const unSuccessfulGifPath = PF + 'gifs/unsuccessful.gif';
export const waitingGifPath = PF + 'gifs/waiting.gif';
export const errorGifPath = PF + 'gifs/error.gif';
export const noInternetGifPath = PF + 'gifs/no_net.gif';
export const welcomeGifPath = PF + 'gifs/welcome.gif';

//Side bar
export const shiftIconSvgPath = PF + 'icons/shift_icon.svg';
export const shiftCheckmarkIconSvgPath = PF + 'icons/shift_checkmark_icon.svg';
export const analyticsIconSvgPath = PF + 'icons/analytics_icon.svg';

//Register page
export const addDocumentIconSvgPath = PF + 'icons/add_document_icon.svg';
export const mobileIconSvgPath = PF + 'icons/mobile_icon.svg';
export const mailIconSvgPath = PF + 'icons/mail_icon.svg';

/// Dashboard
// Sidebar
export const homeActiveIconSvgPath = PF + 'icons/dashboard_icon_active.svg';
export const homeIconSvgPath = PF + 'icons/dashboard_icon.svg';

// tasks
export const tasksActiveIconSvgPath = PF + 'icons/tasks_icon_active.svg';
export const tasksIconSvgPath = PF + 'icons/tasks_icon.svg';

// chat
export const chatActiveIconSvgPath = PF + 'icons/chat_icon_active.svg';
export const chatIconSvgPath = PF + 'icons/chat_icon.svg';

export const activityActiveIconSvgPath = PF + 'icons/activity_icon_active.svg';
export const activityIconSvgPath = PF + 'icons/activity_icon.svg';

export const folderActiveIconSvgPath = PF + 'icons/folder_icon_active.svg';
export const folderIconSvgPath = PF + 'icons/folder_icon.svg';

export const buildingActiveIconSvgPath = PF + 'icons/building_icon_active.svg';
export const buildingIconSvgPath = PF + 'icons/building_icon.svg';

// settings
export const settingsActiveIconSvgPath = PF + 'icons/settings_icon_active.svg';
export const settingsIconSvgPath = PF + 'icons/settings_icon.svg';

// members
export const membersActiveIconSvgPath = PF + 'icons/members_icon_active.svg';
export const membersIconSvgPath = PF + 'icons/members_icon.svg';

// rota
export const rotaActiveIconSvgPath = PF + 'icons/rota_icon_active.svg';
export const rotaIconSvgPath = PF + 'icons/rota_icon.svg';

// records
export const recordsActiveIconSvgPath = PF + 'icons/records_icon_active.svg';
export const recordsIconSvgPath = PF + 'icons/records_icon.svg';

// Settings
export const branchesActiveIconSvgPath = PF + 'icons/branches_icon_active.svg';
export const branchesIconSvgPath = PF + 'icons/branches_icon.svg';
export const timeActiveIconSvgPath = PF + 'icons/time_icon_active.svg';
export const timeIconSvgPath = PF + 'icons/time_icon.svg';
export const reportActiveIconSvgPath = PF + 'icons/report_icon_active.svg';
export const reportIconSvgPath = PF + 'icons/report_icon.svg';
export const roleActiveIconSvgPath = PF + 'icons/role_icon_active.svg';
export const roleIconSvgPath = PF + 'icons/role_icon.svg';

export const notificationIconSvgPath = PF + 'icons/notification_icon.svg';

export const locationIconSvgPath = PF + 'icons/location_icon.svg';

// organisation
export const organizationIconSvgPath = PF + 'icons/organization_icon.svg';
export const organizationIconAcitiveSvgPath =
  PF + 'icons/organization_icon_active.svg';
export const attachmentSvgPath = PF + 'icons/attachment_icon.svg';

// Home zero states
export const yearCuateIconSvgPath = PF + 'images/year_cuate_icon.svg';
export const addUserIconSvgPath = PF + 'images/add_user_icon.svg';
export const createOrganizationIconSvgPath = PF + 'images/create_org_icon.svg';

// scaffold bg
export const scaffoldBgIconSvgPath = PF + 'images/scaffold_bg.svg';

// stat bg
export const statBgIconSvgPath = PF + 'images/stat_bg.svg';
