import { Button, Flex, Input, Spacer, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stringValPatternValidation } from '../../application/services/utils';
import {
  addCertOfReg,
  addKraNumber,
  addRegNumber,
  setIsViewMap,
} from '../../redux/organisation/organisationRegistration';
import FileSelector from '../core/components/file_sector/FileSelector';
import './org_registration.scss';

const OrganizationRegistrationPageTwo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { cert_of_reg, kra_number, registration_number } = useSelector(
    (state) => state.organisationRegistration
  );

  const [file, setFile] = useState(cert_of_reg);

  const [error, setError] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!cert_of_reg) {
      setError('Upload certificate of registration');
    } else if (
      registration_number.length < 3 ||
      stringValPatternValidation(registration_number)
    ) {
      setError('Enter a valid registration number');
    } else if (
      kra_number.length < 10 ||
      stringValPatternValidation(kra_number)
    ) {
      setError('Enter a valid KRA number');
    } else {
      navigate('/maps');
    }
  };

  const removeFile = (filename) => {
    setFile(undefined);
    dispatch(addCertOfReg(undefined));
  };
  return (
    <div className='org-container'>
      <div className='org-header'>
        <h2 className='title'>Almost there</h2>
        <p className='desc'>
          We'll need a few documents to verify your organization
        </p>
      </div>
      {/* progress */}
      <div className='org-prog-indicator'>
        <div className='first active'></div>
        <div className='second active'></div>
      </div>
      <form onSubmit={handleSubmit}>
        {/* cert of reg */}
        <FileSelector
          title='Company certificate of registration'
          isRequired={true}
          file={file}
          setFile={setFile}
          removeFile={removeFile}
          accept={'.png, .jpg, .pdf'}
          desc='PDF, PNG and JPG'
        />
        {/* registration number */}
        <Flex mt={6}>
          <Text fontSize={'sm'}>Registration Number</Text>
          <Text fontSize={'sm'} color='red'>
            *
          </Text>
        </Flex>
        <Input
          focusBorderColor='#107e76'
          title='Company registration number'
          isRequired={true}
          placeholder='Enter reg number'
          type='text'
          value={registration_number}
          onChange={(e) => dispatch(addRegNumber(e.target.value))}
        />
        {/* physical location */}
        {/* <InputField
          title='Organization physical location'
          isRequired={true}
          placeholder='Enter physical location'
          type='text'
        /> */}
        {/* KRA number */}
        <Flex mt={6}>
          <Text fontSize={'sm'}>KRA Number</Text>
          <Text fontSize={'sm'} color='red'>
            *
          </Text>
        </Flex>
        <Input
          mb={2}
          focusBorderColor='#107e76'
          title='KRA number'
          isRequired={true}
          placeholder='Enter KRA number'
          type='text'
          value={kra_number}
          onChange={(e) => dispatch(addKraNumber(e.target.value))}
        />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <Flex align='center' mt={6}>
          <Button
            mt={4}
            variant='ghost'
            onClick={() => {
              dispatch(navigate('/organizations'));
            }}
          >
            Previous
          </Button>

          <Spacer />
          {/* next */}
          <Button mt={4} background='#107e76' textColor='white' type='submit'>
            Next
          </Button>
        </Flex>
      </form>
    </div>
  );
};

export default OrganizationRegistrationPageTwo;
