import './SideBarItem.scss';

const SideBarItem = ({ iconPath, title, description }) => {
  return (
    <div className='side-bar-item'>
      <img src={iconPath} />
      <h4 className='title'>{title}</h4>
      <p className='desc'>{ description}</p>
    </div>
  );
};

export default SideBarItem