import CenteredModal from '../../core/centeredModal/CenteredModal';
import './DeleteWithPassModal.scss';

const DeleteWithPassModal = ({ title, desc, deleteWithPassModalRef }) => {
  return (
    <CenteredModal ref={deleteWithPassModalRef} width={350} height={300}>
      <div className='deleteWithPassModal'>
        <h4 className='deleteWithPassTitle'>{title}</h4>
        <p className='deleteWithPassModalBody'>
          Please enter your login password to delete this {desc}
        </p>
        <div className='inputField'>
          <input placeholder='Enter your password' />
        </div>
        <div className='deleteWithPassModalActions'>
          <button
            className='cancelBtn'
            onClick={() => deleteWithPassModalRef.current.close()}
          >
            Cancel
          </button>
          <button className='deleteBtn'>Delete</button>
        </div>
      </div>
    </CenteredModal>
  );
};

export default DeleteWithPassModal;
