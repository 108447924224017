import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { settings } from '../../application/services/utils';
import { setSettingsPath } from '../../redux/navigation/navigation';
import { setOpenPlans } from '../../redux/organisation/subscription';
import PaymentPlansPage from '../payment_plans/PaymentPlansPage';

const SettingsWrapperPage = () => {
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);
  const { isPlanPaid, openPlans } = useSelector((state) => state.subscription);
  const { settingsPath, settingsComp } = useSelector(
    (state) => state.navigation
  );

  const dispatch = useDispatch();
  dispatch(setOpenPlans(true));

  const lastItem =
    settings(organisation, roles, isPlanPaid)?.filter(
      (item) => item.show === true
    ).length - 1;

  return (
    <div>
      <Grid
        h='100%'
        templateRows='repeat(2, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={4}
        display={{ base: 'grid', md: 'flex' }}
      >
        {/* master */}
        <GridItem
          rowSpan={2}
          colSpan={1}
          h='100vh'
          w='140px'
          backgroundColor={'white'}
          className='gridItem'
        >
          {settings(organisation, roles, isPlanPaid)
            ?.filter((item) => {
              return item.show === true;
            })
            .map((item, index) => (
              <Box
                key={index}
                backgroundColor={settingsPath === index ? '#3c968f2e' : 'white'}
                padding={5}
                onClick={() => {
                  dispatch(setSettingsPath(index));
                }}
              >
                <Text
                  fontSize='sm'
                  textColor={settingsPath === index ? '#107e76' : null}
                  fontWeight={'600'}
                >
                  {item.title}
                </Text>
                <Text
                  textColor={settingsPath === index ? '#107e76' : null}
                  fontSize={'xs'}
                >
                  {item.description}
                </Text>
              </Box>
            ))}
        </GridItem>
        {/* detail */}
        <GridItem colSpan={4} w='100%'>
          {/* detailed view */}
          {openPlans && settingsPath === lastItem ? (
            <PaymentPlansPage />
          ) : (
            settings(organisation, roles, isPlanPaid)?.filter(
              (item) => item.show === true
            )[settingsPath]?.component
          )}
          {/* {
            settings(organisation, roles, isPlanPaid)?.filter(
              (item) => item.show === true
            )[settingsPath]?.component
          } */}
        </GridItem>
      </Grid>
    </div>
  );
};

export default SettingsWrapperPage;
