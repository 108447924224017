import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetOrg } from '../../redux/organisation/organisation';
import { setIsOrgRegOpen } from '../../redux/organisation/organisationRegistration';
import { resetSubscription } from '../../redux/organisation/subscription';
import { resetRoles } from '../../redux/roles/roles';
import {
  resetUser,
  setAccessToken,
  setLoggedIn,
  setSessionExpired,
} from '../../redux/user/user';
import { refreshTokenUrl } from './http_endpoints';
import { resetNavigation } from '../../redux/navigation/navigation';

function useFetch({ url, params, headers, role, successMsg, errMsg }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.roles);
  const { organisation } = useSelector((state) => state.organisation);
  const { access_token } = useSelector((state) => state.user);

  async function handleError(error, errMsg, retry) {
    // If the token has expired, refresh it and retry the call
    if (
      error?.response?.data?.detail === 'Authorization token expired.' ||
      error?.response?.data?.detail === 'Refresh token expired.' ||
      error?.response?.data?.detail === `The API couldn't decode your token.`
    ) {
      axios
        .post(refreshTokenUrl, {
          token: access_token,
        })
        .then((response) => {
          dispatch(setAccessToken(response?.token));
          retry();
        })
        .catch((err) => {
          dispatch(setLoggedIn(false));
          dispatch(resetUser());
          dispatch(resetOrg());
          dispatch(resetRoles());
          dispatch(resetSubscription());
          dispatch(resetNavigation());
          dispatch(setSessionExpired(true));
          navigate('/');
        });
    } else {
      setError(error);
      const msg = error?.response?.data?.detail;
      if (msg !== 'Not Found')
        toast({
          position: 'bottom-left',
          title: errMsg,
          description: error?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
    }
  }

  // fetch data
  async function fetchData() {
    if (!role) {
      setLoading(true);
      try {
        const response = await axios.get(url, { params, headers });
        setData(response.data);
        if (successMsg)
          toast({
            position: 'bottom-left',
            title: successMsg,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
      } catch (error) {
        handleError(error, errMsg, fetchData);
      }
      setLoading(false);
    }
  }

  // fetch data by role
  async function fetchDataByRole() {
    if (role && roles?.[organisation?.id]?.includes(role)) {
      setLoading(true);
      try {
        const response = await axios.get(url, { params, headers });
        setData(response.data);
        if (successMsg)
          toast({
            position: 'bottom-left',
            title: successMsg,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
      } catch (error) {
        handleError(error, errMsg, fetchDataByRole);
      }
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   fetchData();
  //   fetchDataByRole();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [url, organisation?.id, branch]);

  return {
    data,
    error,
    loading,
    fetchData,
    fetchDataByRole,
    setData,
  };
}

export default useFetch;

export async function handleError(
  error,
  errMsg,
  retry,
  access_token,
  dispatch,
  toast,
  navigate,
  headers
) {
  // If the token has expired, refresh it and retry the call
  if (
    error?.response?.data?.detail === 'Authorization token expired.' ||
    error?.response?.data?.detail === 'Refresh token expired.' ||
    error?.response?.data?.detail === `The API couldn't decode your token.`
  ) {
    console.log(access_token);
    axios
      .post(
        refreshTokenUrl,
        {
          token: access_token,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        dispatch(setAccessToken(response?.token));
        retry();
      })
      .catch((err) => {
        dispatch(setLoggedIn(false));
        dispatch(resetUser());
        dispatch(resetOrg());
        dispatch(resetRoles());
        dispatch(setSessionExpired(true));
        navigate('/');
      });
  } else {
    const msg = error?.response?.data?.detail;
    if (msg !== 'Not Found')
      toast({
        position: 'bottom-left',
        title: errMsg,
        description: error?.response?.data?.detail?.toString(),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
  }
}

// logout user
export function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  function logoutUser() {
    dispatch(setLoggedIn(false));
    dispatch(resetUser());
    dispatch(resetOrg());
    dispatch(resetRoles());
    dispatch(resetSubscription());
    dispatch(resetNavigation());
    dispatch(setIsOrgRegOpen(false));
    toast({
      position: 'bottom-left',
      title: 'Logged out successfully.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    navigate('/');
  }

  return {
    logoutUser,
  };
}
