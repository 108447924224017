import './AuthScaffold.scss';
import { useState } from 'react';
import { motion } from 'framer-motion';
import {
  analyticsIconSvgPath,
  ratibaLogoIconSvgPath,
  shiftCheckmarkIconSvgPath,
  shiftIconSvgPath,
} from '../../../application/services/asset_strings';
import SideBarItem from '../components/sidebar_item/SideBarItem';
import {
  analyticsDescString,
  analyticsString,
  manageShiftsDescString,
  manageShiftsString,
  shiftSwapsString,
  shiftSwapsDescString,
} from '../../../application/services/app_strings';

const AuthScaffold = ({ children }) => {
  const [activePath, setActivePath] = useState('/');
  const toggleActive = (val) => setActivePath(val);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: 'auto',
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <div className='main-container'>
      <motion.div
        animate={{
          transition: {
            duration: 0.5,
            type: 'spring',
            damping: 11,
          },
        }}
        className='sidebar'
      >
        <div className={'top_section'}>
          <a href='/' className='logo'>
            <motion.img
              src={ratibaLogoIconSvgPath}
              alt='logo'
              variants={showAnimation}
              initial='hidden'
              animate='show'
              exit='hidden'
            />
          </a>
          <h5 className='header-title'>
            Rotas made easy for you and your employees
          </h5>
        </div>
        <section className='auth_sidebar_body'>
          <SideBarItem
            iconPath={shiftIconSvgPath}
            title={manageShiftsString}
            description={manageShiftsDescString}
          />
          <SideBarItem
            iconPath={shiftCheckmarkIconSvgPath}
            title={shiftSwapsString}
            description={shiftSwapsDescString}
          />
          <SideBarItem
            iconPath={analyticsIconSvgPath}
            title={analyticsString}
            description={analyticsDescString}
          />
        </section>
      </motion.div>
      <main>{children}</main>
    </div>
  );
};

export default AuthScaffold;
