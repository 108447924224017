import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  phone_number: '',
  email: '',
  cert_of_reg: undefined,
  registration_number: '',
  kra_number: '',
  industry_id: '',
  longitude: 0,
  latitude: 0,
  isOrgRegOpen: false,
  viewLat: 0,
  viewLng: 0,
  isViewMap: '',
  locId: '',
};

export const organisationRegistrationSlice = createSlice({
  name: 'organisationRegistration',
  initialState,
  reducers: {
    addName: (state, action) => {
      state.name = action.payload;
    },
    addPhone: (state, action) => {
      state.phone_number = action.payload;
    },
    addEmail: (state, action) => {
      state.email = action.payload;
    },
    addCertOfReg: (state, action) => {
      state.cert_of_reg = action.payload;
    },
    addRegNumber: (state, action) => {
      state.registration_number = action.payload;
    },
    addKraNumber: (state, action) => {
      state.kra_number = action.payload;
    },
    addIndustryId: (state, action) => {
      state.industry_id = action.payload;
    },
    addLongitude: (state, action) => {
      state.longitude = action.payload;
    },
    addLatitude: (state, action) => {
      state.latitude = action.payload;
    },
    setIsOrgRegOpen: (state, action) => {
      state.isOrgRegOpen = action.payload;
    },
    addViewLng: (state, action) => {
      state.viewLng = action.payload;
    },
    addViewLat: (state, action) => {
      state.viewLat = action.payload;
    },
    setIsViewMap: (state, action) => {
      state.isViewMap = action.payload;
    },
    setLocId: (state, action) => {
      state.locId = action.payload;
    },
    resetOrgReg: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  addName,
  addPhone,
  addEmail,
  addCertOfReg,
  addRegNumber,
  addKraNumber,
  addIndustryId,
  addLongitude,
  addLatitude,
  setIsOrgRegOpen,
  addViewLng,
  addViewLat,
  setIsViewMap,
  locId,
  setLocId,
  resetOrgReg,
} = organisationRegistrationSlice.actions;

export default organisationRegistrationSlice.reducer;
