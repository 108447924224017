import './CenteredModal.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

const CenteredModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false),
    };
  });
  return (
    <AnimatePresence>
      {open && (
        <div className='centeredModal'>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.2,
                duration: 0.3,
              },
            }}
            className='centeredModalBackdrop'
            onClick={() => setOpen(false)}
          />
          <motion.div
            initial={{
              opacity: 0,
              scale: 0,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                delay: 0.2,
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              scale: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className='centeredModalWrapper'
            style={{ width: props.width, height: props.height }}
          >
            {props.children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
});

export default CenteredModal;
