import { Box, Card, CardBody, CardHeader, Text } from '@chakra-ui/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

const Hours = ({
  organisationReports,
  branchReports,
  departmentReports,
  reportsFilter,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    maintainAspectRatio: true,
  };

  // const labels = [
  //   'Doctors',
  //   'Nurses',
  //   'Lab tech',
  //   'Receptionists',
  //   'Dentists',
  //   'Oncologists',
  // ];

  // const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'This month',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: '#107e76',
  //     },
  //     {
  //       label: 'Last month',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: '#F69520',
  //     },
  //   ],
  // };

  const labels = (() => {
    if (reportsFilter === 'organisation') return ['Organisation'];
    else if (reportsFilter === 'branch') return ['Branch'];
    else if (reportsFilter === 'department') return ['Department'];
    else return [''];
  })();

  const dispDataT = (() => {
    if (reportsFilter === 'organisation')
      return [
        organisationReports?.organisation_working_hrs_summary?.this_month
          ?.total_hrs,
      ];
    else if (reportsFilter === 'branch')
      return [branchReports?.branch_working_hrs_summary?.this_month?.total_hrs];
    else if (reportsFilter === 'department')
      return [
        departmentReports?.department_working_hrs_summary?.this_month
          ?.total_hrs,
      ];
    else return [];
  })();

  const dispDataL = (() => {
    if (reportsFilter === 'organisation')
      return [
        organisationReports?.organisation_working_hrs_summary?.last_month
          ?.total_hrs,
      ];
    else if (reportsFilter === 'branch')
      return [branchReports?.branch_working_hrs_summary?.last_month?.total_hrs];
    else if (reportsFilter === 'department')
      return [
        departmentReports?.department_working_hrs_summary?.last_month
          ?.total_hrs,
      ];
    else return [];
  })();

  const data = {
    labels,
    datasets: [
      {
        label: 'This month',
        data: dispDataT,
        backgroundColor: '#107e76',
      },
      {
        label: 'Last month',
        data: dispDataL,
        backgroundColor: '#F69520',
      },
    ],
  };

  return (
    <Card m={4} w={'100%'}>
      <CardHeader>
        <Text as='b'>Hours</Text>
      </CardHeader>
      <CardBody>
        <Box p={2} h={'35vh'} align='center'>
          <Bar options={options} data={data} />
        </Box>
      </CardBody>
    </Card>
  );
};

export default Hours;
