import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  registerPageTwoDescriptionString,
  registerPageTwoTitleString,
} from '../../application/services/app_strings';
import { registerUrl } from '../../application/services/http_endpoints';
import './RegisterPage.scss';
import SpecialityModal from './modal/SpecialityModal';
import { personelList } from '../../application/services/utils';

const RegisterPageTwo = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.userRegistration);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [profession, setProfession] = useState();
  const [personel, setPersonel] = useState();
  const [speciality, setSpeciality] = useState();
  const [regNo, setRegNo] = useState('');
  const [isLoading, setLoading] = useState(false);

  const registerUser = async () => {
    setLoading(true);
    axios
      .post(registerUrl, {
        user: {
          first_name: user.firstName,
          last_name: user.lastName,
          password: user.password,
          gender: user.gender,
          // reg_no: regNo,
        },
        contacts: [
          {
            contact_type: 'PHONE',
            contact_value: user.phoneNumber,
            is_primary: true,
          },
          {
            contact_type: 'EMAIL',
            contact_value: user.email,
            is_primary: false,
          },
        ],
        profession: {
          name: personel,
          type: profession,
          speciality: speciality,
          registration_number: regNo,
        },
      })
      .then((response) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'Registered successfully!',
          description: 'Log in with your new account.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      })
      .catch((err) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'Registration failed.',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (profession === 'MEDICAL' && regNo.trim().length < 3) {
      setError('Registration number should be at least 3 characters long.');
    } else {
      registerUser();
    }
  };
  return (
    <div className='register-container'>
      <div className='register-header'>
        <h2 className='title'>{`${registerPageTwoTitleString} ${user.firstName}`}</h2>
        <p className='desc'>{registerPageTwoDescriptionString}</p>
      </div>
      <div className='register-prog-indicator'>
        <div className='first active'></div>
        <div className='second active'></div>
      </div>
      <form onSubmit={handleSubmit}>
        {/* type of profession */}
        <Flex mt={6}>
          <Text fontSize={'sm'}>Type of profession</Text>
          <Text fontSize={'sm'} textColor={'red'}>
            *
          </Text>
        </Flex>
        <Select
          size={'lg'}
          fontSize={'md'}
          focusBorderColor='#16ACA1'
          borderRadius={10}
          mb={6}
          selected={profession}
          placeholder='Select profession'
          isRequired={true}
          onChange={(e) => setProfession(e.target.value)}
        >
          <option value='MEDICAL'>Medical personel</option>
          <option value='NON_MEDICAL'>Non medical</option>
        </Select>

        {/* type of medical personel */}
        {profession && (
          <Box>
            <Flex mt={6}>
              <Text fontSize={'sm'}>Type of personel</Text>
              <Text fontSize={'sm'} textColor={'red'}>
                *
              </Text>
            </Flex>
            <Select
              size={'lg'}
              fontSize={'md'}
              focusBorderColor='#16ACA1'
              borderRadius={10}
              mb={6}
              selected={personel}
              placeholder='Select personel'
              isRequired={true}
              onChange={(e) => setPersonel(e.target.value)}
            >
              {personelList
                ?.filter((item) => profession === item.type)
                ?.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
            </Select>
          </Box>
        )}

        {/* speciality*/}
        {profession === 'MEDICAL' && personel === 'Doctor' && (
          <Box>
            <Flex mt={6}>
              <Text fontSize={'sm'}>Speciality</Text>
              <Text fontSize={'sm'} color='red'>
                *
              </Text>
            </Flex>
            <Input
              size={'lg'}
              fontSize={'md'}
              focusBorderColor='#16ACA1'
              borderRadius={10}
              mb={6}
              isRequired={true}
              placeholder={'Select speciality'}
              value={speciality}
              type='text'
              onClick={() => onOpen()}
              // value={regNo}
              // onChange={(e) => setRegNo(e.target.value)}
            />
          </Box>
        )}

        {/* reg no */}
        {profession === 'MEDICAL' && personel && (
          <Box>
            <Flex>
              <Text fontSize={'sm'}>Registration No.</Text>
              <Text fontSize={'sm'} color='red'>
                *
              </Text>
            </Flex>
            <Input
              size={'lg'}
              fontSize={'md'}
              focusBorderColor='#16ACA1'
              borderRadius={10}
              mb={6}
              isRequired={true}
              placeholder={'Enter registration no'}
              type='text'
              value={regNo}
              onChange={(e) => setRegNo(e.target.value)}
            />
          </Box>
        )}

        {error && <div style={{ color: 'red' }}>{error}</div>}

        <Flex align='center'>
          <Button mt={4} variant='ghost' onClick={() => navigate('/register')}>
            Previous
          </Button>

          <Spacer />

          <Button
            mt={4}
            background='#107e76'
            textColor='white'
            type='submit'
            isLoading={isLoading}
          >
            Create Account
          </Button>
        </Flex>
      </form>

      {/* speciality modal */}
      <SpecialityModal
        speciality={speciality}
        setSpeciality={setSpeciality}
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};

export default RegisterPageTwo;
