import { Button, Center, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenPlans } from '../../../../redux/organisation/subscription';
import './Subscription.scss';

const SubscriptionPage = () => {
  const [isActive, setActive] = useState(true);
  const toggleActive = (val) => setActive(val);

  const { user, access_token } = useSelector((state) => state.user);
  const {
    planName,
    planPeriod,
    planAmount,
    planCurrency,
    planDueDate,
    isPlanPaid,
    planCode,
    planInterval,
  } = useSelector((state) => state.subscription);

  const toast = useToast();
  const dispatch = useDispatch();

  return (
    <div className='subscription__wrapper'>
      <div className='subscription__wrapper__header'>
        <h1>Subscription</h1>
        <h4>Manage your payment and view your payment history</h4>
      </div>
      <div className='subscription__wrapper__body'>
        <div className='subscription__wrapper__body__tabs'>
          <div
            className={`tabs ${isActive ? 'active-tab' : ''}`}
            onClick={() => toggleActive(true)}
          >
            <p>My Plan</p>
          </div>
          <div
            className={`tabs ${!isActive ? 'active-tab' : ''}`}
            onClick={() => toggleActive(false)}
          >
            <p>Payment History</p>
          </div>
        </div>
        <hr />
        <div className='subscription__wrapper__body__content__tabs'>
          {isActive ? (
            <div className='plan'>
              <div className='plan__body__item'>
                <div>
                  <h2>{planName}</h2>
                  <h6>Details about your plan</h6>
                </div>
                <Button
                  background='#107e76'
                  textColor='white'
                  onClick={() => dispatch(setOpenPlans(true))}
                >
                  Upgrade subscription
                </Button>
              </div>
              <div className='plan__body__item'>
                <div>
                  <h6>
                    {planInterval === 'monthly'
                      ? 'Monthly payment'
                      : 'Yearly payment'}
                  </h6>
                  <h2>{`${planCurrency} ${planAmount}`}</h2>
                </div>
                <div>
                  <h6>Next payment date</h6>
                  <h2>{planDueDate ?? '-'}</h2>
                </div>
                <div>
                  <h6>Code</h6>
                  <h2>{planCode}</h2>
                </div>
              </div>
              {/* <div className='plan__body__payment__info'>
                <div>
                  <h2>Payment information</h2>
                  <h6>How to pay for your subscription</h6>
                </div>
                <div className='payment__details'>
                  <div>
                    <p>Paybill no</p>
                    <h2>555666</h2>
                  </div>
                  <div>
                    <p>Account no</p>
                    <h2>0707 707 090</h2>
                  </div>
                  <div>
                    <p>Account name</p>
                    <h2>Ratiba</h2>
                  </div>
                </div>
                <h3>or</h3>
                <div>
                  <p className='description'>
                    Enter MPESA phone number<span>*</span>
                  </p>
                  <div className='mpesa__number'>
                    <div className='input'>
                      <InputGroup>
                        <InputLeftAddon children='+254' />
                        <Input
                          type='tel'
                          placeholder='phone number'
                          focusBorderColor='#107e76'
                        />
                      </InputGroup>
                    </div>
                    <div className='button'>
                      <Button
                        backgroundColor={'#107e76'}
                        textColor='white'
                        onClick={() => window.open(url)}
                      >
                        Pay now
                      </Button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          ) : (
            // <TableContainer backgroundColor={'white'}>
            //   <Table>
            //     <Tbody>
            //       <Tr>
            //         <Th className='scheduleName'>Payment method</Th>
            //         <Th>Price</Th>
            //         <Th>Duration</Th>
            //         <Th>Date</Th>
            //       </Tr>
            //       <Tr>
            //         <Td>
            //           <Text textColor={'black'}>MPESA</Text>
            //         </Td>
            //         <Td>
            //           <Text textColor={'black'}>1,200</Text>
            //         </Td>

            //         <Td>
            //           <Text textColor={'black'}>1 month</Text>
            //         </Td>
            //         <Td>
            //           <Text textColor='black' fontSize='sm'>
            //             April 14, 2022
            //           </Text>
            //           <Text fontSize='sm'>5:20 PM</Text>
            //         </Td>
            //       </Tr>
            //     </Tbody>
            //   </Table>
            // </TableContainer>
            <Center>
              <Text>No payments found</Text>
            </Center>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
