import './NavbarItem.scss';
import { NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Image, Text } from '@chakra-ui/react';

const NavbarItem = ({
  title,
  icon,
  activeIcon,
  route,
  isOpen,
  showAnimation,
  toggleActive,
  activeRoute,
  show,
}) => {
  if (!show) return <div></div>;
  return (
    <div className='nav-link'>
      <NavLink
        to={route}
        key={route}
        className={`link ${activeRoute === route ? 'activeLink' : ''}`}
        onClick={() => toggleActive(route)}
      >
        <Image boxSize='18px' src={activeRoute === route ? activeIcon : icon} />

        <AnimatePresence>
          {isOpen && (
            <motion.div
              variants={showAnimation}
              initial='hidden'
              animate='show'
              exit='hidden'
              className='link_text'
            >
              <Text
                textColor={activeRoute === route ? '#107E76' : null}
                fontSize='sm'
              >
                {title}
              </Text>
            </motion.div>
          )}
        </AnimatePresence>
      </NavLink>
    </div>
  );
};

export default NavbarItem;
