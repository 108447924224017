import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { departmentsUrl } from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';
import DepartmentModal from './DepartmentModal';

const DepartmentsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeactivateOpen,
    onOpen: onDeactivateOpen,
    onClose: onDeactivateClose,
  } = useDisclosure();
  const toast = useToast();

  const { organisation, branch } = useSelector((state) => state.organisation);
  const [isLoading, setLoading] = useState(false);
  const [isDepartmentsLoading, setDepartmentsLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { access_token } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.roles);

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});

  async function fetchDepartments() {
    setDepartmentsLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .get(departmentsUrl, {
        params: { branch_id: branch ?? '' },
        headers: headers,
      })
      .then((response) => {
        setDepartmentsLoading(false);
        setDepartments(response.data.items);
      })
      .catch((err) => {
        setDepartmentsLoading(false);
        handleError(
          err,
          'Failed to fetch departments.',
          fetchDepartments,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  useEffect(() => {
    // Trigger the fetch
    fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDepartment = async (payload) => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(`${departmentsUrl}${selectedDepartment.id}/`, payload, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);

        setLoading(false);
        onClose();
        fetchDepartments();
        toast({
          position: 'bottom-left',
          title: 'Department updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update department.',
          updateDepartment,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const openModal = (isEdit) => {
    setEdit(isEdit);
    onOpen();
  };

  return (
    <div>
      <Flex m={12} align='center'>
        <Container>
          <Heading as='h4' size='md' mb={2}>
            Departments
          </Heading>
          <Text fontSize='sm'>
            Create and manage your organization departments
          </Text>
        </Container>

        <Spacer />
        <Button
          noOfLines={1}
          onClick={() => {
            setSelectedDepartment({});
            openModal(false);
          }}
          backgroundColor='#107e76'
          textColor={'white'}
          isDisabled={
            organisation?.id === undefined ||
            organisation?.id === '' ||
            !roles?.[organisation?.id]?.includes('Departments.create')
          }
        >
          Create
        </Button>
      </Flex>

      {/* department modal */}
      <DepartmentModal
        fetchDepartments={fetchDepartments}
        selectedDepartment={selectedDepartment}
        isEdit={isEdit}
        isOpen={isOpen}
        onClose={onClose}
      />

      {/* deactivate department */}
      <Modal
        isOpen={isDeactivateOpen}
        onClose={() => {
          onDeactivateClose();
          setSelectedDepartment({});
        }}
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Container mt={8}>
              {/* via email */}
              <Text as='b' fontSize='lg'>
                {`${
                  selectedDepartment?.is_active ? 'Deactivate' : 'Activate'
                } department`}
              </Text>
              <Text fontSize='sm' mb={8} mt={2}>
                <span
                  style={{ fontWeight: 800 }}
                >{`${selectedDepartment.name} `}</span>
                {`will be ${
                  selectedDepartment?.is_active ? 'deactivated' : 'activated'
                }.`}
              </Text>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onDeactivateClose();
                setSelectedDepartment({});
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={
                selectedDepartment?.is_active ? '#E05A5A' : '#107e76'
              }
              textColor='white'
              isLoading={isLoading}
              onClick={() => {
                onDeactivateClose();
                updateDepartment({
                  branch_id: branch,
                  is_active: !selectedDepartment?.is_active,
                });
              }}
            >
              {selectedDepartment?.is_active ? 'Deactivate' : 'Activate'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isDepartmentsLoading ? (
        <Center>
          <BounceLoader
            color={'#107e76'}
            loading={isDepartmentsLoading}
            size={80}
          />
        </Center>
      ) : (
        <div>
          <TableContainer m={12} backgroundColor={'white'}>
            <Table size='md'>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Date created</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {departments.length > 0 ? (
                  departments.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {item.name}
                        </Text>
                      </Td>
                      {/* created */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {moment(item.created_at).format('MMM DD, YYYY')}
                        </Text>
                        <Text fontSize='sm'>
                          {moment
                            .utc(item.created_at)
                            .local()
                            .format('HH:mm a')}
                        </Text>
                      </Td>
                      {/* status */}
                      <Td>
                        <Center>
                          {item.is_active ? (
                            <Tag
                              borderRadius='full'
                              backgroundColor='#53A24C'
                              textColor='white'
                            >
                              Active
                            </Tag>
                          ) : (
                            <Tag
                              borderRadius='full'
                              backgroundColor='#C80024'
                              textColor='white'
                            >
                              Deactivated
                            </Tag>
                          )}
                        </Center>
                      </Td>
                      {/* actions */}
                      <Td>
                        <Button
                          backgroundColor='#DAF2F0'
                          textColor='#107e76'
                          size='sm'
                          onClick={() => {
                            setSelectedDepartment(item);
                            openModal(true);
                          }}
                          mr={4}
                          isDisabled={
                            organisation?.id === undefined ||
                            organisation?.id === '' ||
                            !roles?.[organisation?.id]?.includes(
                              'Departments.update'
                            )
                          }
                        >
                          Edit
                        </Button>
                        <Button
                          mr={5}
                          textColor={item?.is_active ? '#C80024' : '#107e76'}
                          variant='outline'
                          backgroundColor={
                            item?.is_active ? '#F8E0E5' : '#DAF2F0'
                          }
                          size='sm'
                          isDisabled={
                            organisation?.id === undefined ||
                            organisation?.id === '' ||
                            !roles?.[organisation?.id]?.includes(
                              'Departments.update'
                            )
                          }
                          onClick={() => {
                            setSelectedDepartment(item);
                            onDeactivateOpen();
                          }}
                        >
                          {item?.is_active ? 'Deactivate' : 'Activate'}
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr className='no-record'>
                    <Td colSpan='5'>
                      <Text fontSize='sm'>No records found</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default DepartmentsPage;
