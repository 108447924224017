import { Avatar, AvatarGroup } from '@chakra-ui/react';

const ShiftAvatars = ({ members }) => {
  return (
    <AvatarGroup size='sm' max={3}>
      {members
        .filter((item) => item.is_active === true)
        .map((item) => (
          <Avatar
            name={`${item.rota_member.branch_member.org_member.user.first_name} ${item.rota_member.branch_member.org_member.user.last_name}`}
          />
        ))}
    </AvatarGroup>
  );
};

export default ShiftAvatars;
