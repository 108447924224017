import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  planCode: '',
  planName: '',
  planPeriod: '',
  planAmount: 0,
  planCurrency: '',
  planDueDate: '-',
  planInterval: '',
  isPlanPaid: false,
  openPlans: false,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setPlanCode: (state, action) => {
      state.planCode = action.payload;
    },
    setPlanName: (state, action) => {
      state.planName = action.payload;
    },
    setPlanPeriod: (state, action) => {
      state.planPeriod = action.payload;
    },
    setPlanAmount: (state, action) => {
      state.planAmount = action.payload;
    },
    setPlanCurrency: (state, action) => {
      state.planCurrency = action.payload;
    },
    setPlanDueDate: (state, action) => {
      state.planDueDate = action.payload;
    },
    setPlanInterval: (state, action) => {
      state.planInterval = action.payload;
    },
    setIsPlanPaid: (state, action) => {
      state.isPlanPaid = action.payload;
    },
    setOpenPlans: (state, action) => {
      state.openPlans = action.payload;
    },
    resetSubscription: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  planCode,
  planName,
  planPeriod,
  planAmount,
  planCurrency,
  planDueDate,
  planInterval,
  setPlanCode,
  setPlanName,
  setPlanPeriod,
  setPlanAmount,
  setPlanCurrency,
  setPlanDueDate,
  setPlanInterval,
  setIsPlanPaid,
  setOpenPlans,
  resetSubscription,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
