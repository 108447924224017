import {
  Box,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  Image,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activityActiveIconSvgPath,
  activityIconSvgPath,
  chatActiveIconSvgPath,
  chatIconSvgPath,
  homeActiveIconSvgPath,
  homeIconSvgPath,
  membersActiveIconSvgPath,
  membersIconSvgPath,
  organizationIconAcitiveSvgPath,
  organizationIconSvgPath,
  ratibaLogoGreenIconSvgPath,
  recordsActiveIconSvgPath,
  recordsIconSvgPath,
  rotaActiveIconSvgPath,
  rotaIconSvgPath,
  settingsActiveIconSvgPath,
  settingsIconSvgPath,
  tasksActiveIconSvgPath,
  tasksIconSvgPath,
} from '../../../application/services/asset_strings';
import { setActivePath } from '../../../redux/navigation/navigation';
import TopBar from '../TopBar/TopBar';
import NavbarItem from '../components/navbar_item/NavbarItem';

export default function SidebarWithHeader({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>{children}</Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);

  const dispatch = useDispatch();
  const toggleActive = (val) => dispatch(setActivePath(val));
  const { activePath } = useSelector((state) => state.navigation);
  const [isNavOpen, setIsNavOpen] = useState(true);

  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <Flex
        mt={8}
        // h='20'
        mb={5}
        align='center'
        mx='8'
        justifyContent='space-between'
      >
        <Image src={ratibaLogoGreenIconSvgPath} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      <div className='home-tour'>
        <NavbarItem
          title='Home'
          icon={homeIconSvgPath}
          activeIcon={homeActiveIconSvgPath}
          route=''
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={true}
          // show={
          //      organisation?.id === undefined ||
          //      organisation?.id === '' ||
          //   roles?.[organisation?.id]?.includes('Shifts.list')
          // }
        />
      </div>

      <div className='rota-tour'>
        <NavbarItem
          title='Rota'
          icon={rotaIconSvgPath}
          activeIcon={rotaActiveIconSvgPath}
          route='/rota'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={
            roles?.[organisation?.id]?.includes('Rota.list') ||
            roles?.[organisation?.id]?.includes('Shifts.list')
          }
        />
      </div>

      <div className='records-tour'>
        <NavbarItem
          title='Records'
          icon={recordsIconSvgPath}
          activeIcon={recordsActiveIconSvgPath}
          route='/records'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={organisation?.id !== undefined || organisation?.id !== ''}
          // show={
          //   roles?.[organisation?.id]?.includes('Procedures.list') ||
          //   roles?.[organisation?.id]?.includes('Patients.list')
          // }
        />
      </div>

      <div className='tasks-tour'>
        <NavbarItem
          title='Tasks'
          icon={tasksIconSvgPath}
          activeIcon={tasksActiveIconSvgPath}
          route='/tasks'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={
            organisation?.organisation_type !== 'INDIVIDUAL' &&
            roles?.[organisation?.id]?.includes('Admin.update_user')
          }
        />
      </div>
      <div className='chat-tour'>
        <NavbarItem
          title='Chat'
          icon={chatIconSvgPath}
          activeIcon={chatActiveIconSvgPath}
          route='/chat'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={
            organisation?.organisation_type !== 'INDIVIDUAL' &&
            roles?.[organisation?.id]?.includes('Chats.list')
          }
        />
      </div>
      <div className='timesheet-tour'>
        <NavbarItem
          title='Timesheet'
          icon={activityIconSvgPath}
          activeIcon={activityActiveIconSvgPath}
          route='/timesheet'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={
            organisation?.organisation_type !== 'INDIVIDUAL' &&
            (roles?.[organisation?.id]?.includes(
              'TimeSheet.view_branch_timesheet'
            ) ||
              roles?.[organisation?.id]?.includes(
                'TimeSheet.view_branch_shift_hours'
              ) ||
              roles?.[organisation?.id]?.includes(
                'TimeSheet.view_personal_timesheet'
              ))
          }
        />
      </div>
      <div className='members-tour'>
        <NavbarItem
          title='Members'
          icon={membersIconSvgPath}
          activeIcon={membersActiveIconSvgPath}
          route='/members'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={
            // organisation?.organisation_type !== 'INDIVIDUAL' &&
            roles?.[organisation?.id]?.includes('OrganisationMembers.list')
          }
        />
      </div>
      <div className='organisation-tour'>
        <NavbarItem
          title='Organizations'
          icon={organizationIconSvgPath}
          activeIcon={organizationIconAcitiveSvgPath}
          route='/organizations'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={
            organisation?.id === undefined ||
            organisation?.id === '' ||
            roles?.[organisation?.id]?.includes('Organisations.list')
          }
        />
      </div>

      <div className='settings-tour'>
        <NavbarItem
          title='Settings'
          icon={settingsIconSvgPath}
          activeIcon={settingsActiveIconSvgPath}
          route='/settings'
          isOpen={isNavOpen}
          toggleActive={toggleActive}
          activeRoute={activePath}
          show={
            organisation?.id === undefined ||
            organisation?.id === '' ||
            roles?.[organisation?.id]?.includes('ShiftHours.list') ||
            roles?.[organisation?.id]?.includes('Departments.list') ||
            roles?.[organisation?.id]?.includes('Branches.list') ||
            roles?.[organisation?.id]?.includes('Roles.list')
          }
        />
      </div>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { organisation } = useSelector((state) => state.organisation);
  return (
    <Box
      ml={{ base: 0, md: 60 }}
      height={
        organisation?.organisation_type !== 'INDIVIDUAL' &&
        organisation?.id !== undefined &&
        organisation?.id !== '' &&
        organisation.status !== 'VERIFIED'
          ? '32'
          : '24'
      }
      bg={useColorModeValue('white', 'gray.900')}
      align='left'
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'none' }}
      {...rest}
    >
      <TopBar onOpenDrawer={onOpen} />
    </Box>
  );
};
