import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@chakra-ui/react';

const DepartmentReport = ({ branchReports, departmentReports, filter }) => {
  return (
    <Card m={4} w={'98%'}>
      <CardHeader>
        <Text as='b'>{filter === 'branch' ? 'Branch' : 'Department'}</Text>
      </CardHeader>
      <CardBody>
        <Flex pr={4} pl={4} pb={4}>
          {/* Members */}
          <Box align='center' mr={2}>
            <Text pt='2' fontSize='sm'>
              Members
              <Heading textColor='black' size='xs' textTransform='uppercase'>
                {filter === 'branch'
                  ? branchReports?.branch_summary?.staff ?? '-'
                  : departmentReports?.department_summary?.staff ?? '-'}
              </Heading>
            </Text>
          </Box>
          <Spacer />

          {/* Roles */}
          <Box align='center' mr={2}>
            <Text pt='2' fontSize='sm'>
              Roles
              <Heading textColor='black' size='xs' textTransform='uppercase'>
                {filter === 'branch'
                  ? branchReports?.branch_summary?.no_of_roles ?? '-'
                  : departmentReports?.department_summary?.no_of_roles ?? '-'}
              </Heading>
            </Text>
          </Box>
          <Spacer />

          {/* Hours done */}
          <Box align='center' mr={2}>
            <Text pt='2' fontSize='sm'>
              Hours done
              <Heading textColor='black' size='xs' textTransform='uppercase'>
                {filter === 'branch'
                  ? branchReports?.branch_working_hrs_summary?.this_month
                      ?.total_hrs_done ?? '-'
                  : departmentReports?.department_working_hrs_summary
                      ?.this_month?.total_hrs_done ?? '-'}
              </Heading>
            </Text>
          </Box>
          <Spacer />

          {/* Hours pending */}
          <Box align='center' mr={2}>
            <Text pt='2' fontSize='sm'>
              Hours pending
              <Heading textColor='black' size='xs' textTransform='uppercase'>
                {filter === 'branch'
                  ? branchReports?.branch_working_hrs_summary?.this_month
                      ?.total_hrs_pending ?? '-'
                  : departmentReports?.department_working_hrs_summary
                      ?.this_month?.total_hrs_pending ?? '-'}
              </Heading>
            </Text>
          </Box>
          <Spacer />

          {/*  Hours this month */}
          <Box align='center' mr={2}>
            <Text pt='2' fontSize='sm'>
              Total Hours
              <Heading textColor='black' size='xs' textTransform='uppercase'>
                {filter === 'branch'
                  ? branchReports?.branch_working_hrs_summary?.this_month
                      ?.total_hrs ?? '-'
                  : departmentReports?.department_working_hrs_summary
                      ?.this_month?.total_hrs ?? '-'}
              </Heading>
            </Text>
          </Box>
          <Spacer />
        </Flex>
      </CardBody>
    </Card>
  );
};

export default DepartmentReport;
