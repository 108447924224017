import { ChannelList, useChatContext } from 'stream-chat-react';
import ChannelSearch from './ChannelSearch';
import TeamChannelList from './TeamChannelList';
import TeamChannelPreview from './TeamChannelPreview';
import { useState } from 'react';

const customChannelTeamFilter = (channels) => {
  return channels.filter((channel) => channel.type === 'team');
};

const customChannelMessagingFilter = (channels) => {
  return channels.filter((channel) => channel.type === 'messaging');
};

const ChannelListContent = ({
  isCreating,
  setIsCreating,
  setCreateType,
  setIsEditing,
  setToggleContainer,
}) => {
  const { client } = useChatContext();

  const [selectedTab, setSelectedTab] = useState('direct');

  const filters = { members: { $in: [client.userID] } };
  const sort = { last_message_at: -1 };

  return (
    <>
      <div className='channel-list__list__wrapper'>
        <ChannelSearch setToggleContainer={setToggleContainer} />

        <div className='channel-list__list__wrapper__tabs'>
          <div
            className={`tab_item ${selectedTab === 'groups' ? '' : 'active'}`}
            onClick={() => setSelectedTab('direct')}
          >
            Chats
          </div>
          <div
            className={`tab_item ${selectedTab === 'groups' ? 'active' : ''}`}
            onClick={() => setSelectedTab('groups')}
          >
            Groups
          </div>
        </div>
        {selectedTab === 'groups' && (
          <ChannelList
            filters={filters}
            sort={sort}
            channelRenderFilterFn={customChannelTeamFilter}
            List={(listProps) => (
              <TeamChannelList
                {...listProps}
                type='team'
                isCreating={isCreating}
                setIsCreating={setIsCreating}
                setCreateType={setCreateType}
                setIsEditing={setIsEditing}
                setToggleContainer={setToggleContainer}
              />
            )}
            Preview={(previewProps) => (
              <TeamChannelPreview
                {...previewProps}
                setIsCreating={setIsCreating}
                setIsEditing={setIsEditing}
                setToggleContainer={setToggleContainer}
                type='team'
              />
            )}
          />
        )}
        {selectedTab !== 'groups' && (
          <ChannelList
            filters={filters}
            channelRenderFilterFn={customChannelMessagingFilter}
            List={(listProps) => (
              <TeamChannelList
                {...listProps}
                type='messaging'
                isCreating={isCreating}
                setIsCreating={setIsCreating}
                setCreateType={setCreateType}
                setIsEditing={setIsEditing}
                setToggleContainer={setToggleContainer}
              />
            )}
            Preview={(previewProps) => (
              <TeamChannelPreview
                {...previewProps}
                setIsCreating={setIsCreating}
                setIsEditing={setIsEditing}
                setToggleContainer={setToggleContainer}
                type='messaging'
              />
            )}
          />
        )}
      </div>
    </>
  );
};

const ChannelListContainer = ({
  setCreateType,
  setIsCreating,
  setIsEditing,
}) => {
  const [toggleContainer, setToggleContainer] = useState(false);

  return (
    <>
      <div className='channel-list__container'>
        <ChannelListContent
          setIsCreating={setIsCreating}
          setCreateType={setCreateType}
          setIsEditing={setIsEditing}
        />
      </div>

      <div
        className='channel-list__container-responsive'
        style={{
          left: toggleContainer ? '0%' : '-89%',
          backgroundColor: '#107e76',
        }}
      >
        <div
          className='channel-list__container-toggle'
          onClick={() =>
            setToggleContainer((prevToggleContainer) => !prevToggleContainer)
          }
        ></div>
        <ChannelListContent
          setIsCreating={setIsCreating}
          setCreateType={setCreateType}
          setIsEditing={setIsEditing}
          setToggleContainer={setToggleContainer}
        />
      </div>
    </>
  );
};

export default ChannelListContainer;
