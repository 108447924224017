import {
  Card,
  CardBody,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';

const RolesHours = ({
  organisationReports,
  branchReports,
  departmentReports,
  reportsFilter,
}) => {
  const dispReport = (() => {
    if (reportsFilter === 'organisation')
      return organisationReports?.roles_summary;
    else if (reportsFilter === 'branch') return branchReports?.roles_summary;
    else if (reportsFilter === 'department')
      return departmentReports?.roles_summary;
    else return '-';
  })();

  return (
    <Card m={4} w={'100%'}>
      <CardBody>
        <TableContainer backgroundColor={'white'} m={4}>
          <Table size='md'>
            <TableCaption>info in hours</TableCaption>
            <Thead>
              <Tr>
                <Th>Role</Th>
                <Th>Count</Th>
                <Th>Pending</Th>
                <Th>Done</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dispReport?.length > 0 ? (
                dispReport?.map((item) => (
                  <Tr key={item?.role_details?.id}>
                    {/* name */}
                    <Td>
                      <Text textColor='black' fontSize='sm'>
                        {item?.role_details?.name}
                      </Text>
                    </Td>
                    {/* count */}
                    <Td>
                      <Text fontSize='sm'>{item?.role_count}</Text>
                    </Td>
                    {/* total hours pending */}
                    <Td>
                      <Text fontSize='sm'>{item?.total_hrs_pending}</Text>
                    </Td>
                    {/* total hours done */}
                    <Td>
                      <Text fontSize='sm'>{item?.total_hrs_done}</Text>
                    </Td>
                    {/* total hours */}
                    <Td>
                      <Text fontSize='sm'>{item?.total_hrs}</Text>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr className='no-record'>
                  <Td colSpan='5'>
                    <Text fontSize='sm'>No records found</Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default RolesHours;
