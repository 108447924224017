import {
  Avatar,
  Flex,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

const TimeSheetTable = ({ data }) => {
  return (
    <TableContainer m={12} background='white'>
      <Table size='md'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Hours this week</Th>
            {/* <Th>Role</Th> */}
            <Th>Status</Th>
            <Th>Ongoing shift</Th>
            <Th>Next shift</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.length > 0 ? (
            data.map((item) => (
              <Tr key={item.id}>
                <Td>
                  <Flex align='center'>
                    {/* avatar */}
                    <Avatar
                      name={`${item.employee_details.org_member.user.first_name} ${item.employee_details.org_member.user.last_name}`}
                      size='sm'
                      mr={5}
                    />
                    <div>
                      {/* name */}
                      <span>
                        <Text textColor='black' fontSize='sm'>
                          {`${item.employee_details.org_member.user.first_name} ${item.employee_details.org_member.user.last_name}`}
                        </Text>
                      </span>
                      {/* email */}
                      <span className='user-email'>
                        <Text fontSize='sm'>{item.email}</Text>
                      </span>
                    </div>
                  </Flex>
                </Td>
                {/* hours worked */}
                <Td>
                  <Text fontSize='sm'>{item.employee_hours_worked}</Text>
                </Td>
                {/* role */}
                {/* <Td>
                  <Text fontSize='sm'>
                    {
                      item.employee_details.org_member.member_type
                        ?.member_type_name
                    }
                  </Text>
                </Td> */}
                {/* status */}
                <Td>
                  <Tag
                    borderRadius='full'
                    colorScheme={
                      item.employee_details.is_active ? 'green' : 'red'
                    }
                    variant='solid'
                  >
                    {item.employee_details.is_active ? 'Active' : 'Deactivated'}
                  </Tag>
                </Td>

                {/* ongoing shift */}
                {item?.ongoing_shift ? (
                  <Td>
                    <Text textColor='#107e76' fontSize='sm'>
                      {moment(item?.ongoing_shift?.shift_date).format(
                        'MMM DD, YYYY'
                      )}
                    </Text>
                    <Text textColor='black' fontSize='sm'>
                      {item?.ongoing_shift?.shift_hour?.name}
                    </Text>
                    <Text fontSize='sm'>
                      {moment(
                        `${item?.ongoing_shift?.shift_date} ${item?.ongoing_shift?.shift_hour?.start_time}`
                      ).format('HH:mm A')}{' '}
                      -{' '}
                      {moment(
                        `${item?.ongoing_shift?.shift_date} ${item?.ongoing_shift?.shift_hour?.end_time}`
                      ).format('HH:mm A')}
                    </Text>
                  </Td>
                ) : (
                  <Td>
                    <Text fontSize='sm'>No shift</Text>
                  </Td>
                )}

                {/* next shift */}
                {item?.next_shift ? (
                  <Td>
                    <Text
                      textColor={
                        moment().format('YYYY-MM-DD') ===
                        moment(item?.next_shift?.shift_date).format(
                          'YYYY-MM-DD'
                        )
                          ? '#EF9920'
                          : '#107e76'
                      }
                      fontSize='sm'
                    >
                      {moment().format('YYYY-MM-DD') ===
                      moment(item?.next_shift?.shift_date).format('YYYY-MM-DD')
                        ? 'Today'
                        : moment(item?.next_shift?.shift_date).format(
                            'MMM DD, YYYY'
                          )}
                    </Text>
                    <Text textColor='black' fontSize='sm'>
                      {item?.next_shift?.shift_hour?.name}
                    </Text>
                    <Text fontSize='sm'>
                      {moment(
                        `${item?.next_shift?.shift_date} ${item?.next_shift?.shift_hour?.start_time}`
                      ).format('HH:mm A')}{' '}
                      -{' '}
                      {moment(
                        `${item?.next_shift?.shift_date} ${item?.next_shift?.shift_hour?.end_time}`
                      ).format('HH:mm A')}
                    </Text>
                  </Td>
                ) : (
                  <Td>
                    <Text fontSize='sm'>No shift</Text>
                  </Td>
                )}
              </Tr>
            ))
          ) : (
            <Tr className='no-record'>
              <Td colSpan='5'>
                <Text fontSize='sm'>No records found</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TimeSheetTable;
