import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { shiftHoursUrl } from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';
import ShiftHourModal from './ShiftHourModal';
import './ShiftHours.scss';
const WorkingHours = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenDeactivateModal,
    onOpen: onOpenDeactivateModal,
    onClose: onCloseDeactivateModal,
  } = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { organisation, branch } = useSelector((state) => state.organisation);
  const [shiftType, setShiftType] = useState('');

  const [workingHour, setWorkingHour] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [active, setActive] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const [isDepartmentsLoading, setDepartmentsLoading] = useState(false);

  const { access_token } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.roles);

  const [workHours, setWorkHours] = useState([]);

  const [workHourItem, setWorkHourItem] = useState({});

  async function fetchWorkingHours() {
    if (organisation?.id !== undefined && organisation?.id !== '') {
      setDepartmentsLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id ?? '',
      };
      axios
        .get(shiftHoursUrl, {
          params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((response) => {
          setDepartmentsLoading(false);

          setWorkHours(response.data.items);
        })
        .catch((err) => {
          setDepartmentsLoading(false);
          handleError(
            err,
            'Failed to fetch working hours.',
            fetchWorkingHours,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  useEffect(() => {
    // Trigger the fetch
    fetchWorkingHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWorkingHour = async () => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(
        `${shiftHoursUrl}${workHourItem.id}/`,
        {
          name: `${workingHour.length > 0 ? workingHour : workHourItem.name}`,
          shift_type: `${
            shiftType.length > 0 ? shiftType : workHourItem.shift_type
          }`,
          branch_id: branch,
          start_time: `${
            startTime.length > 0 ? `${startTime}:00` : workHourItem.start_time
          }`,
          end_time: `${
            endTime.length > 0 ? `${endTime}:00` : workHourItem.end_time
          }`,
          is_active: !workHourItem.is_active,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);

        onClose();
        fetchWorkingHours();
        setWorkHourItem({});
        toast({
          position: 'bottom-left',
          title: 'Working hour updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update working hour.',
          updateWorkingHour,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  return (
    <Box>
      <Flex m={12} align='center'>
        <Container>
          <Heading as='h4' size='md' mb={2}>
            Team working hours
          </Heading>
          <Text fontSize='sm'>
            Create and edit and track your working hours
          </Text>
        </Container>

        <Spacer />
        <Button
          noOfLines={1}
          onClick={onOpen}
          backgroundColor='#107e76'
          textColor={'white'}
          isDisabled={
            organisation?.id === undefined ||
            organisation?.id === '' ||
            !roles?.[organisation?.id]?.includes('ShiftHours.create')
          }
        >
          Create
        </Button>
      </Flex>

      {/* create shift hour */}
      <ShiftHourModal
        fetchWorkingHours={fetchWorkingHours}
        workHourItem={workHourItem}
        setWorkHourItem={setWorkHourItem}
        isOpen={isOpen}
        onClose={onClose}
        active={active}
      />

      {/* Deactivate/ activate Working Hour Modal */}
      <Modal
        isOpen={isOpenDeactivateModal}
        onClose={() => {
          onCloseDeactivateModal();
          setWorkHourItem({});
        }}
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Container mt={8}>
              {/* via email */}
              <Text as='b' fontSize='lg'>
                Deactivate working hours
              </Text>
              <Text fontSize='sm' mb={8} mt={2}>
                <span
                  style={{ fontWeight: 800 }}
                >{`${workHourItem.name} `}</span>
                {`will be ${
                  workHourItem?.is_active ? 'deactivated' : 'activated'
                }. You or any other team members will ${
                  workHourItem?.is_active ? 'not' : ''
                } be
                able to use this working hour to create a rota anymore.`}
              </Text>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onCloseDeactivateModal();
                setWorkHourItem({});
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={workHourItem?.is_active ? '#E05A5A' : '#107e76'}
              textColor='white'
              isLoading={isLoading}
              onClick={() => {
                onCloseDeactivateModal();
                setActive(false);
                updateWorkingHour();
              }}
            >
              {workHourItem?.is_active ? 'Deactivate' : 'Activate'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isDepartmentsLoading ? (
        <Center>
          <BounceLoader
            color={'#107e76'}
            loading={isDepartmentsLoading}
            size={80}
          />
        </Center>
      ) : (
        <div>
          <TableContainer m={12} backgroundColor={'white'}>
            <Table size='md'>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Shift Type</Th>
                  <Th>Time</Th>
                  <Th>Date created</Th>
                  <Th>Status</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {workHours.length > 0 ? (
                  workHours.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {item.name}
                        </Text>
                      </Td>
                      {/* Shift Type */}
                      <Td>
                        <p
                          className={`status-badge ${item.shift_type.replace(
                            /\s/g,
                            ''
                          )}`}
                        >
                          {item.shift_type}
                        </p>
                      </Td>
                      {/* Time */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {moment(`2022-12-20T${item.start_time}`).format(
                            'HH:mm A'
                          )}{' '}
                          -{' '}
                          {moment(`2022-12-20T${item.end_time}`).format(
                            'HH:mm A'
                          )}
                        </Text>
                      </Td>
                      {/* created */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {moment(item.created_at).format('MMM DD, YYYY')}
                        </Text>
                        <Text fontSize='sm'>
                          {moment
                            .utc(item.created_at)
                            .local()
                            .format('HH:mm a')}
                        </Text>
                      </Td>
                      {/* status */}
                      <Td>
                        <Center>
                          {item.is_active ? (
                            <Tag
                              borderRadius='full'
                              backgroundColor='#53A24C'
                              textColor='white'
                            >
                              Active
                            </Tag>
                          ) : (
                            <Tag
                              borderRadius='full'
                              backgroundColor='#C80024'
                              textColor='white'
                            >
                              Deactivated
                            </Tag>
                          )}
                        </Center>
                      </Td>
                      {/* actions */}
                      <Td>
                        {/* edit */}
                        <Button
                          backgroundColor='#DAF2F0'
                          textColor='#107e76'
                          size='sm'
                          onClick={() => {
                            onOpen();
                            setWorkHourItem(item);
                          }}
                          mr={4}
                          isDisabled={
                            organisation?.id === undefined ||
                            organisation?.id === '' ||
                            !roles?.[organisation?.id]?.includes(
                              'ShiftHours.update'
                            )
                          }
                        >
                          Edit
                        </Button>
                        {/* activate/ deactivate */}
                        <Button
                          mr={5}
                          textColor={item?.is_active ? '#C80024' : '#107e76'}
                          variant='outline'
                          backgroundColor={
                            item?.is_active ? '#F8E0E5' : '#DAF2F0'
                          }
                          size='sm'
                          isDisabled={
                            organisation?.id === undefined ||
                            organisation?.id === '' ||
                            !roles?.[organisation?.id]?.includes(
                              'ShiftHours.update'
                            )
                          }
                          onClick={() => {
                            onOpenDeactivateModal();
                            setWorkHourItem(item);
                          }}
                        >
                          {item?.is_active ? 'Deactivate' : 'Activate'}
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr className='no-record'>
                    <Td colSpan='5'>
                      <Text fontSize='sm'>No records found</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Box>
  );
};

export default WorkingHours;
