import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import {
  departmentsUrl,
  refreshTokenUrl,
  rotasUrl,
  shiftHoursUrl,
  shiftsUrl,
} from '../../application/services/http_endpoints';
import {
  handleError,
  useLogout,
} from '../../application/services/http_service';
import { setAccessToken } from '../../redux/user/user';
import SchedulesTable from '../core/tables/schedulesTable/SchedulesTable';
import ShiftsWrapper from '../home/ShiftsWrapper';
import './Rota.scss';
import RotaMembersModal from './RotaMembersModal';
import RotaModal from './RotaModal';

const Rota = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDeactivateModal,
    onOpen: onOpenDeactivateModal,
    onClose: onCloseDeactivateModal,
  } = useDisclosure();
  const {
    isOpen: isOpenMembersModal,
    onOpen: onOpenMembersModal,
    onClose: onCloseMembersModal,
  } = useDisclosure();

  const [selectedRota, setSelectedRota] = useState({});
  const [isEdit, setEdit] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);
  const [shifts, setShifts] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [isRotaCreated, setRotaCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  // fetch shift hours
  async function fetchShifts() {
    if (roles?.[organisation?.id]?.includes('ShiftHours.list')) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .get(shiftHoursUrl, {
          params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((response) => {
          setShifts(response.data.items);
        })
        .catch((err) => {
          handleError(
            err,
            'Failed to fetch shifts.',
            fetchShifts,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  // fetch departments
  async function fetchDepartments() {
    if (roles?.[organisation?.id]?.includes('Departments.list')) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .get(departmentsUrl, {
          params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((response) => {
          setDepartments(response.data.items);
        })
        .catch((err) => {
          handleError(
            err,
            'Failed to fetch departments.',
            fetchDepartments,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  const [rotas, setRotas] = useState([]);
  // fetch rotas
  async function fetchRotas() {
    if (roles?.[organisation?.id]?.includes('Rota.list')) {
      setLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id ?? '',
      };
      axios
        .get(rotasUrl, {
          params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((response) => {
          setLoading(false);
          setRotas(response.data.items);
        })
        .catch((err) => {
          setLoading(false);
          handleError(
            err,
            'Failed to fetch rotas.',
            fetchRotas,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }
  const [membersInfo, setMembersInfo] = useState([]);

  useEffect(() => {
    // Trigger the fetch

    fetchDepartments();
    fetchShifts();
    fetchRotas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deactivateRota = async () => {
    setRotaCreated(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .delete(`${rotasUrl}${selectedRota.id}/`, {
        headers: headers,
      })
      .then((response) => {
        setRotaCreated(false);
        fetchRotas();
        onCloseDeactivateModal();
        toast({
          position: 'bottom-left',
          title: `${selectedRota.title} deactivated successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setRotaCreated(false);
        toast({
          position: 'bottom-left',
          title: 'Failed to deactivate rota',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const openModal = (isEdit) => {
    setEdit(isEdit);
    onOpen();
    // fetchDepartments();
    // fetchShifts();
  };

  // shifts

  const [sunSifts, setSunShifts] = useState([]);
  const [monShifts, setMonShifts] = useState([]);
  const [tueShifts, setTueShifts] = useState([]);
  const [wedShifts, setWedShifts] = useState([]);
  const [thuShifts, setThuShifts] = useState([]);
  const [friShifts, setFriShifts] = useState([]);
  const [satShifts, setSatShifts] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const curr = moment(selectedDate);

  const sun = moment(curr.startOf('week')).format('YYYY-MM-DD');
  const mon = moment(curr.startOf('week')).add(1, 'days').format('YYYY-MM-DD');
  const tue = moment(curr.startOf('week')).add(2, 'days').format('YYYY-MM-DD');
  const wed = moment(curr.startOf('week')).add(3, 'days').format('YYYY-MM-DD');
  const thu = moment(curr.startOf('week')).add(4, 'days').format('YYYY-MM-DD');
  const fri = moment(curr.startOf('week')).add(5, 'days').format('YYYY-MM-DD');
  const sat = moment(curr.startOf('week')).add(6, 'days').format('YYYY-MM-DD');

  const { logoutUser } = useLogout();

  const [shiftWrapperLoading, setShiftWrapperLoading] = useState(false);

  const [shiftFilter, setShiftFilter] = useState('day');

  const resetShifts = () => {
    setSunShifts([]);
    setMonShifts([]);
    setTueShifts([]);
    setWedShifts([]);
    setThuShifts([]);
    setFriShifts([]);
    setSatShifts([]);
  };

  async function fetchData(shift_date) {
    if (
      branch &&
      (organisation?.id === undefined ||
        organisation?.id === '' ||
        roles?.[organisation.id]?.includes('Shifts.list'))
    ) {
      setShiftWrapperLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id ?? '',
      };
      axios
        .get(`${shiftsUrl}${branch}/`, {
          params: { shift_date: shift_date },
          headers: headers,
        })
        .then((response) => {
          setShiftWrapperLoading(false);
          switch (shift_date) {
            case sun:
              setSunShifts(response.data);
              break;
            case mon:
              setMonShifts(response.data);
              break;
            case tue:
              setTueShifts(response.data);
              break;
            case wed:
              setWedShifts(response.data);
              break;
            case thu:
              setThuShifts(response.data);
              break;
            case fri:
              setFriShifts(response.data);
              break;
            case sat:
              setSatShifts(response.data);
              break;

            default:
              break;
          }
        })
        .catch((error) => {
          // If the token has expired, refresh it and retry the call
          setShiftWrapperLoading(false);
          if (
            error?.response?.data?.detail === 'Authorization token expired.' ||
            error?.response?.data?.detail === 'Refresh token expired.' ||
            error?.response?.data?.detail ===
              `The API couldn't decode your token.`
          ) {
            axios
              .post(
                refreshTokenUrl,
                {
                  token: access_token,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`,
                  },
                }
              )
              .then((response) => {
                dispatch(setAccessToken(response?.token));
                fetchData(shift_date);
              })
              .catch((err) => {
                logoutUser();
              });
          } else {
            toast({
              position: 'bottom-left',
              title: 'Failed to fetch shifts.',
              description: error?.response?.data?.detail,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        });
    } else {
      setSunShifts([]);
      setMonShifts([]);
      setTueShifts([]);
      setWedShifts([]);
      setThuShifts([]);
      setFriShifts([]);
      setSatShifts([]);
    }
  }

  const triggerFetch = () => {
    // onCloseCalendarModal();
    // resetShifts();
    if (shiftFilter === 'day') {
      fetchData(moment(selectedDate).format('YYYY-MM-DD'));
    } else {
      fetchData(sun);
      fetchData(mon);
      fetchData(tue);
      fetchData(wed);
      fetchData(thu);
      fetchData(fri);
      fetchData(sat);
    }
  };

  return (
    <div className='rota'>
      {/*  tabs */}
      <Tabs
        variant='solid-rounded'
        defaultIndex={
          roles?.[organisation?.id]?.includes('Shifts.list') ? 0 : 1
        }
      >
        <TabList ml={12} mt={8}>
          <Tab
            _selected={{ color: 'white', bg: '#107e76' }}
            isDisabled={!roles?.[organisation?.id]?.includes('Shifts.list')}
            // onClick={() => triggerFetch()}
          >
            Shifts
          </Tab>
          <Tab
            _selected={{ color: 'white', bg: '#107e76' }}
            isDisabled={!roles?.[organisation?.id]?.includes('Rota.list')}
            // onClick={() => fetchRotas()}
          >
            Rotas
          </Tab>
        </TabList>

        <TabPanels>
          {/* shifts */}
          <TabPanel>
            <ShiftsWrapper
              sun={sun}
              mon={mon}
              tue={tue}
              wed={wed}
              thu={thu}
              fri={fri}
              sat={sat}
              sunSifts={sunSifts}
              monShifts={monShifts}
              tueShifts={tueShifts}
              wedShifts={wedShifts}
              thuShifts={thuShifts}
              friShifts={friShifts}
              satShifts={satShifts}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              curr={curr}
              shiftWrapperLoading={shiftWrapperLoading}
              resetShifts={resetShifts}
              shiftFilter={shiftFilter}
              setShiftFilter={setShiftFilter}
              triggerFetch={triggerFetch}
            />
          </TabPanel>
          {/* rotas */}
          <TabPanel>
            <Box>
              <Flex m={12} align='center'>
                <Container>
                  <Heading as='h4' size='md' mb={2}>
                    Track rotas
                  </Heading>
                  <Text fontSize='sm'>
                    Create rota to track and manage employees shifts
                  </Text>
                </Container>

                <Spacer />
                <Button
                  noOfLines={1}
                  backgroundColor='#107e76'
                  textColor='white'
                  isDisabled={
                    organisation?.id === undefined ||
                    organisation?.id === '' ||
                    !roles?.[organisation?.id]?.includes('Rota.create')
                  }
                  onClick={() => {
                    openModal(false);
                  }}
                >
                  Create rota
                </Button>
              </Flex>

              {/* deactivate rota */}
              <Modal
                isOpen={isOpenDeactivateModal}
                onClose={() => {
                  onCloseDeactivateModal();
                  setSelectedRota({});
                }}
                size={'lg'}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalBody>
                    <Container mt={8}>
                      <Text as='b' fontSize='lg'>
                        Deactivate rota
                      </Text>
                      <Text fontSize='sm' mb={8} mt={2}>
                        <span
                          style={{ fontWeight: 800 }}
                        >{`${selectedRota.title} `}</span>
                        {`will be 
                        ${selectedRota?.is_active ? 'deactivated' : 'activated'}
                        .`}
                      </Text>
                    </Container>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant='outline'
                      mr={3}
                      onClick={() => {
                        onCloseDeactivateModal();
                        setSelectedRota({});
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='solid'
                      backgroundColor={
                        selectedRota?.is_active ? '#E05A5A' : '#107e76'
                      }
                      textColor='white'
                      isLoading={isRotaCreated}
                      onClick={() => {
                        if (selectedRota?.is_active) deactivateRota();
                      }}
                    >
                      {selectedRota?.is_active ? 'Deactivate' : 'Activate'}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>

              {loading ? (
                <Center>
                  <BounceLoader color={'#107e76'} loading={loading} size={80} />
                </Center>
              ) : (
                <SchedulesTable
                  rotas={rotas}
                  openModal={openModal}
                  onOpenMembersModal={onOpenMembersModal}
                  setSelectedRota={setSelectedRota}
                  setMembersInfo={setMembersInfo}
                  onOpenDeactivateModal={onOpenDeactivateModal}
                />
              )}

              {/* rota modal */}
              <RotaModal
                triggerFetch={triggerFetch}
                fetchRotas={fetchRotas}
                fetchDepartments={fetchDepartments}
                fetchShifts={fetchShifts}
                selectedRota={selectedRota}
                setSelectedRota={setSelectedRota}
                departments={departments}
                shifts={shifts}
                membersInfo={membersInfo}
                setMembersInfo={setMembersInfo}
                isOpen={isOpen}
                onClose={onClose}
                isEdit={isEdit}
              />

              {/* rota members modal */}
              <RotaMembersModal
                triggerFetch={triggerFetch}
                fetchRotas={fetchRotas}
                selectedRota={selectedRota}
                setSelectedRota={setSelectedRota}
                membersInfo={membersInfo}
                setMembersInfo={setMembersInfo}
                isOpen={isOpenMembersModal}
                onClose={onCloseMembersModal}
                isEdit={isEdit}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Rota;
