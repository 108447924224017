import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment/moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  continueString,
  emailLabelString,
  emailPlaceHoldeString,
  forgotPasswordString,
  logInDescString,
  logInString,
  passwordLabelString,
  passwordPlaceHolderString,
  registerString,
} from '../../application/services/app_strings';
import { ratibaLogoGreenIconSvgPath } from '../../application/services/asset_strings';
import { loginUrl, rolesUrl } from '../../application/services/http_endpoints';
import { validateEmail } from '../../application/services/utils';
import {
  setDecodedRoles,
  setRoleName,
  setRoles,
} from '../../redux/roles/roles';
import {
  addExpiry,
  addUser,
  setAccessToken,
  setLoggedIn,
  setSessionExpired,
} from '../../redux/user/user';
import { addEmail } from '../../redux/user/userForgotPassword';
import './LoginPage.scss';

const LoginPage = () => {
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { isSessionExpired } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // fetch user roles
  async function fetchRole(org_id, decoded_roles, access_token) {
    if (decoded_roles[org_id]) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': org_id,
      };
      axios
        .get(`${rolesUrl}${decoded_roles[org_id]}/`, {
          // params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((response) => {
          let roles = {};
          roles[org_id] = response.data.user_facing_permissions;
          dispatch(setRoles(roles));
          dispatch(setRoleName(response.data.name));

          // log in user
          dispatch(setLoggedIn(true));
          const expiryDate = moment().add(2, 'hours');
          dispatch(addExpiry(expiryDate));

          toast({
            position: 'bottom-left',
            title: 'Logged in successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((err) => {
          if (err?.response?.data?.detail === 'Please update your password.') {
            dispatch(addEmail(email));
            navigate('/reset-pass');
          } else {
            toast({
              position: 'bottom-left',
              title: 'Failed to fetch roles.',
              description: err?.response?.data?.detail?.toString(),
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        });
    } else {
      // log in user
      dispatch(setLoggedIn(true));
      const expiryDate = moment().add(2, 'hours');
      dispatch(addExpiry(expiryDate));
      toast({
        position: 'bottom-left',
        title: 'Logged in successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  // log in user
  const logInUser = async (email, password) => {
    setLoading(true);
    axios
      .post(loginUrl, {
        email: email,
        password: password,
      })
      .then((response) => {
        setLoading(false);

        dispatch(setAccessToken(response?.data?.access_token));
        dispatch(addUser(response.data));
        dispatch(setSessionExpired(false));
        // decode token
        const decoded = jwtDecode(response.data.access_token);
        dispatch(setDecodedRoles(decoded?.roles));

        // set default signed in org
        // dispatch(setOrganisation(response.data?.user_orgs[0]?.org));

        if (response.data?.user_orgs?.length > 0) {
          // fetch user role and log in
          fetchRole(
            response.data?.user_orgs[0]?.org?.id,
            decoded?.roles,
            response.data.access_token
          );
        } else {
          // log in user
          dispatch(setLoggedIn(true));
          const expiryDate = moment().add(2, 'hours');
          dispatch(addExpiry(expiryDate));
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.detail === 'Please update your password.') {
          dispatch(addEmail(email));
          navigate('/reset-pass');
        } else {
          toast({
            position: 'bottom-left',
            title: 'Log in failed.',
            description:
              err?.response?.data?.detail?.toString() ?? err?.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setError('Enter a valid email.');
    } else {
      setError('');
      logInUser(email, password);
    }
  };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <div className='login-container'>
      <img alt='logo' src={ratibaLogoGreenIconSvgPath} />
      {isSessionExpired ? (
        <Tooltip label='Your session has expired'>
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle noOfLines={1}>Your session has expired!</AlertTitle>
            <AlertDescription noOfLines={1}>
              Please log in again.
            </AlertDescription>
          </Alert>
        </Tooltip>
      ) : null}
      <div className='login-header'>
        {/* Title */}
        <h2 className='title'>{logInString}</h2>

        {/* desc */}
        <p className='desc'>{logInDescString}</p>
      </div>
      <form className='login-form' onSubmit={handleSubmit}>
        {/* Email */}
        <Flex mt={6}>
          <Text fontSize={'sm'}>{emailLabelString}</Text>
          <Text fontSize={'sm'} color='red'>
            *
          </Text>
        </Flex>
        <Input
          isRequired={true}
          placeholder={emailPlaceHoldeString}
          type='email'
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Password */}
        <Flex mt={6}>
          <Text fontSize={'sm'}>{passwordLabelString}</Text>
          <Text fontSize={'sm'} color='red'>
            *
          </Text>
        </Flex>
        <InputGroup size='md'>
          <Input
            isRequired={true}
            placeholder={passwordPlaceHolderString}
            type={show ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement width='4.5rem'>
            <Button variant='ghost' h='1.75rem' size='sm' onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>

        {error && <div style={{ color: 'red', marginTop: 20 }}>{error}</div>}

        {/* Continue */}
        <Button
          mt={8}
          background='#107e76'
          textColor='white'
          type='submit'
          isLoading={isLoading}
        >
          {continueString}
        </Button>

        <div className='input-row'>
          {/* Forgot password */}
          <p className='forgot-password'>
            <NavLink
              exact
              to='/forgot-pass'
              key='forgot-pass'
              className='link-btn'
            >
              <span>{forgotPasswordString}</span>
            </NavLink>
          </p>

          {/* Register */}
          <p className='no-account'>
            New here?{' '}
            <NavLink exact to='/register' key='register' className='link-btn'>
              <span>{registerString}</span>
            </NavLink>
          </p>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
