import './EmployeeRoles.scss';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import TopBar from '../../../core/TopBar/TopBar';
import EmployeeRolesTable from '../../../core/tables/employee_roles_table/EmployeeRolesTable';

const EmployeeRoles = () => {
  return (
    <div className='employeeRoles'>
      <TopBar />
      <div className='employeeRolesWrapper'>
        <div className='employeeRolesHeader'>
          <h4 className='employeeRolesHeaderTitle'>
            <NavLink
              exact
              to='/settings'
              key='/settings'
              className='link'
              activeClassName='active'
            >
              Settings
            </NavLink>
            <IoIosArrowForward />
            <span className='employeeRolesText'> Roles</span>
          </h4>
        </div>
        <div className='employeeRolesBody'>
          <EmployeeRolesTable />
        </div>
      </div>
    </div>
  );
};
export default EmployeeRoles;
