import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { DateRange } from 'react-date-range';

const DateRangeModal = ({ isOpen, onClose, state, setState }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box mt={4}>
            <Text as='b' fontSize='lg'>
              Select Date
            </Text>

            <Center mt={8}>
              <DateRange
                editableDateInputs={false}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                rangeColors={['#107e76']}
              />
            </Center>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='solid'
            backgroundColor={'#107e76'}
            textColor='white'
            onClick={onClose}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DateRangeModal;
