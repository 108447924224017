import {
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spacer,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useRef, useState } from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  branchMemberUrl,
  procedureItemsUrl,
  proceduresUrl,
} from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';
import { paymentTypeList } from '../../../../application/services/utils';

const AddProceduresModal = ({
  fetchProcedures,
  fetchProcedureStats,
  selectedProcedure,
  isEdit,
  isOpen,
  onClose,
  items,
  setItems,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);

  const [isLoading, setLoading] = useState(false);
  const [addProcedureItemLoading, setAddProcedureItemLoading] = useState(false);
  const [updateProcedureItemLoading, setUpdateProcedureItemLoading] =
    useState('');

  const [selectedDoctor, setSelectedDoctor] = useState();
  const [procedureName, setProcedureName] = useState();
  const [procedureCost, setProcedureCost] = useState();
  const [paymentType, setPaymentType] = useState();
  const [patientInitials, setPatientInitials] = useState();
  const [branchMembers, setBranchMembers] = useState([]);
  const [searchlabel, setSearchLabel] = useState('');
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [error, setError] = useState('');

  async function clearState() {
    setSelectedDoctor();
    setProcedureName();
    setProcedureCost();
    setPaymentType();
    setPatientInitials();
    setBranchMembers([]);
    setSearchLabel('');
    setItems([]);
    setName();
    setQuantity();
    setError('');
  }

  // create procedure
  const createProcedure = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .post(
        proceduresUrl,
        {
          doctor_id: selectedDoctor?.id,
          procedure_name: procedureName,
          procedure_cost: procedureCost,
          payment_type: paymentType,
          patient_initials: patientInitials,
          items: items,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);
        clearState();
        onClose();
        fetchProcedures();
        fetchProcedureStats();
        toast({
          position: 'bottom-left',
          title: 'Procedure record created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to create procedure record.',
          createProcedure,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  // update procedure
  async function updateProcedure(payload) {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(`${proceduresUrl}${selectedProcedure.id}/`, payload, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        clearState();
        onClose();
        fetchProcedures();
        fetchProcedureStats();
        toast({
          position: 'bottom-left',
          title: 'Procedure record updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update procedure record.',
          updateProcedure,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  // create procedure item
  const createProcedureItem = async (item_name, quantity) => {
    setAddProcedureItemLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .post(
        procedureItemsUrl,
        {
          item_name: item_name,
          quantity: quantity,
          procedure_id: selectedProcedure.id,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAddProcedureItemLoading(false);
        clearState();
        onClose();
        fetchProcedures();
        toast({
          position: 'bottom-left',
          title: 'Procedure item added successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setAddProcedureItemLoading(false);
        handleError(
          err,
          'Failed to add procedure item.',
          createProcedure,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  // update procedure items
  async function updateProcedureItems(itemId) {
    setUpdateProcedureItemLoading(itemId);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(
        `${procedureItemsUrl}${itemId}/`,
        {
          is_active: false,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setUpdateProcedureItemLoading('');
        clearState();
        onClose();
        fetchProcedures();
        toast({
          position: 'bottom-left',
          title: 'Procedure item removed successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setUpdateProcedureItemLoading('');
        handleError(
          err,
          'Failed to remove procedure item.',
          updateProcedure,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  // search branch members
  const [searchLoading, setSearchLoading] = useState(false);
  const searchtRef = useRef(null);

  async function searchBranchMembers(name) {
    if (roles?.[organisation?.id]?.includes('BranchMembers.list')) {
      if (!name || name === '') {
        setBranchMembers([]);
        setSearchLoading(false);
        setSearchLabel('');
      } else {
        setSearchLabel('Searching...');
        setSearchLoading(true);

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
          'organisation-id': organisation?.id,
        };
        axios
          .get(branchMemberUrl, {
            params: { branch_id: branch ?? '', first_name: name },
            headers: headers,
          })
          .then((res) => {
            setSearchLoading(false);
            setBranchMembers(res.data.items);
            if (res.data.items?.length <= 0) {
              setSearchLabel(`${name} not found`);
            } else {
              setSearchLabel('');
            }
          })
          .catch((err) => {
            setSearchLoading(false);
            handleError(
              err,
              'Failed to search doctor.',
              searchBranchMembers,
              access_token,
              dispatch,
              toast,
              navigate
            );
          });
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEdit && !selectedDoctor?.id) {
      setError('Enter doctor on duty');
    } else {
      setError();
      isEdit
        ? updateProcedure({
            doctor_id: selectedDoctor?.id ?? selectedProcedure.doctor_id,
            procedure_name: procedureName ?? selectedProcedure.procedure_name,
            procedure_cost: procedureCost ?? selectedProcedure.procedure_cost,
            payment_type: paymentType ?? selectedProcedure.payment_type,
            patient_initials:
              patientInitials ?? selectedProcedure.patient_initials,
            items: items,
            status: selectedProcedure.status ?? 'PENDING',
          })
        : createProcedure();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        clearState();
      }}
      size={'lg'}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Container mt={8}>
              {/* title */}
              <Text as='b' fontSize='lg'>
                {isEdit ? 'Edit procedure record' : 'Add procedure record'}
              </Text>

              {/* name of procedure */}
              <Flex mt={8}>
                <Text fontSize={'sm'}>Name of procedure</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  type={'text'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  required={true}
                  placeholder='Enter name'
                  defaultValue={selectedProcedure?.procedure_name}
                  onChange={(e) => setProcedureName(e.target.value)}
                />
              </InputGroup>

              {/* cost of procedure */}
              <Flex>
                <Text fontSize={'sm'}>Cost of procedure</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  type={'number'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  required={true}
                  placeholder='Enter cost'
                  defaultValue={selectedProcedure?.procedure_cost}
                  onChange={(e) => setProcedureCost(e.target.value)}
                />
              </InputGroup>

              {/* payment type */}
              <Flex>
                <Text fontSize={'sm'}>Payment type</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <Select
                required={true}
                focusBorderColor='#107e76'
                placeholder='Select payment type'
                defaultValue={selectedProcedure?.payment_type}
                onChange={(e) => setPaymentType(e.target.value)}
                mb={8}
                mt={2}
              >
                {paymentTypeList?.map((payType, index) => {
                  return (
                    <option key={index} value={payType}>
                      {payType}
                    </option>
                  );
                })}
              </Select>

              {/* search doctor */}
              <Flex>
                <Text fontSize={'sm'}>Doctor on duty</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  type={'text'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  placeholder='Search by firstname'
                  defaultValue={
                    selectedProcedure?.doctor?.org_member?.user?.first_name
                  }
                  ref={searchtRef}
                  onChange={(e) => searchBranchMembers(e.target.value)}
                />
              </InputGroup>

              {error && <div style={{ color: 'red' }}>{error}</div>}

              {searchLoading !== '' && (
                <Container mb={8}>
                  <Text fontSize='sm' as='i' textColor='#107e76'>
                    {searchlabel}
                  </Text>
                </Container>
              )}

              {branchMembers.map((item) => (
                <Tag
                  mr={3}
                  mb={8}
                  size={'lg'}
                  borderRadius='full'
                  variant={selectedDoctor?.id === item.id ? 'outline' : 'solid'}
                  colorScheme={
                    selectedDoctor?.id === item.id ? 'yellow' : 'teal'
                  }
                >
                  <TagLabel>{`${item?.org_member?.user?.first_name} ${item?.org_member?.user?.last_name} `}</TagLabel>
                  <TagRightIcon
                    as={selectedDoctor?.id === item.id ? MdClose : MdAdd}
                    onClick={() => {
                      if (selectedDoctor?.id === item.id) {
                        setBranchMembers(
                          branchMembers.filter((a) => a?.id !== item.id)
                        );
                        setSelectedDoctor({});
                      } else setSelectedDoctor(item);
                      searchtRef.current.value = '';
                    }}
                  />
                </Tag>
              ))}

              {/* patient initial */}
              <Flex>
                <Text fontSize={'sm'}>Patient initial</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  type={'text'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  required={true}
                  placeholder='Enter initial'
                  defaultValue={selectedProcedure?.patient_initials}
                  onChange={(e) => setPatientInitials(e.target.value)}
                />
              </InputGroup>

              {/* add proedure items */}
              <Flex mb={8}>
                {/* item name */}
                <Box mr={2}>
                  <Flex>
                    <Text fontSize={'sm'}>Item name</Text>
                  </Flex>
                  <InputGroup size='md'>
                    <Input
                      type={'text'}
                      pr='4.5rem'
                      focusBorderColor='#107e76'
                      placeholder='Enter name'
                      value={name}
                      // defaultValue={selectedProcedure?.doctor}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </Box>

                {/* no of items */}
                <Box mr={2}>
                  <Flex>
                    <Text fontSize={'sm'}>No of items</Text>
                  </Flex>
                  <InputGroup size='md'>
                    <Input
                      type={'number'}
                      pr='4.5rem'
                      focusBorderColor='#107e76'
                      placeholder='Enter number'
                      value={quantity}
                      // defaultValue={selectedProcedure?.doctor}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </InputGroup>
                </Box>

                {/* add item */}
                <Box mt={5}>
                  <Button
                    variant='solid'
                    backgroundColor={'#107e76'}
                    textColor='white'
                    isLoading={addProcedureItemLoading}
                    onClick={() => {
                      if (isEdit) {
                        createProcedureItem(name, quantity);
                        setName('');
                        setQuantity('');
                      } else {
                        setItems([
                          ...items,
                          {
                            item_name: name,
                            quantity: quantity,
                            is_active: true,
                          },
                        ]);
                        setName('');
                        setQuantity('');
                      }
                    }}
                  >
                    Add
                  </Button>
                </Box>
              </Flex>

              {/* procedure items */}
              {items?.length > 0 && (
                <Box>
                  <Text as='b'>Items used</Text>
                  {items
                    ?.filter((pItem) => pItem.is_active)
                    .map((item, index) => (
                      <Flex key={index} align='center' m={2}>
                        {/* name */}
                        <Text fontSize='sm' textColor='black'>
                          {item.item_name}
                        </Text>
                        <Spacer />
                        {/* quantity */}
                        <Text> {item.quantity}</Text>
                        {/* remove  */}
                        <IconButton
                          size='xs'
                          ml={8}
                          variant='outline'
                          colorScheme='red'
                          aria-label='Remove'
                          fontSize='20px'
                          icon={<MdClose />}
                          isLoading={updateProcedureItemLoading === item.id}
                          onClick={() => {
                            if (isEdit) updateProcedureItems(item.id);
                            else {
                              const newItems = [...items];
                              newItems.splice(index, 1);
                              setItems(newItems);
                            }
                          }}
                        />
                      </Flex>
                    ))}
                </Box>
              )}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onClose();
                clearState();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={'#107e76'}
              textColor='white'
              isLoading={isLoading}
              isDisabled={
                isEdit &&
                !procedureName &&
                !procedureCost &&
                !paymentType &&
                !selectedDoctor &&
                !patientInitials
              }
              type='submit'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddProceduresModal;
