import './FileSelector.scss';
import { addDocumentIconSvgPath } from '../../../../application/services/asset_strings';
import { AiFillFileText } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { addCertOfReg } from '../../../../redux/organisation/organisationRegistration';

const FileSelector = ({
  isRequired,
  title,
  file,
  setFile,
  removeFile,
  accept,
  desc,
}) => {
  const dispatch = useDispatch();
  const uploadHandler = (event) => {
    const file = event.target.files[0];
    file.isUploading = true;
    setFile(file);

    // upload file
    const formData = new FormData();
    formData.append(file.name, file, file.name);
    setFile(file);
    dispatch(addCertOfReg(file));

    //TODO:(eugene) restore when the api is ready
    // axios
    //   .post('http://localhost:8080/upload', formData)
    //   .then((res) => {
    //     file.isUploading = false;
    //     setFiles([...files, file]);
    //   })
    //   .catch((err) => {
    //     // inform the user
    //     console.error(err);
    //     removeFile(file.name);
    //   });
  };

  const deleteFileHandler = (_name) => {
    removeFile(_name);
    //TODO:(eugene) restore when the api is ready
    // axios
    //   .delete(`http://localhost:8080/upload?name=${_name}`)
    //   .then((res) => removeFile(_name))
    //   .catch((err) => console.error(err));
  };
  return (
    <div className='filePicker'>
      <p>
        {title}
        {isRequired && <span>*</span>}
      </p>
      <div className='filePicker-body'>
        <input
          isRequired={true}
          type='file'
          onChange={uploadHandler}
          accept={accept}
        />
        <img src={addDocumentIconSvgPath} />
        <h4 className='title'>Select a file to upload</h4>
        <p className='desc'>{`${desc ?? 'PNG, JPG, PDF'} files allowed`}</p>
      </div>
      <ul className='file-list'>
        {file && (
          <div className='file-item' key={file.name}>
            <div className='title-row'>
              <AiFillFileText className='icon' />
              <p className='title'>{file.name}</p>
            </div>
            <MdDelete
              className='icon'
              onClick={() => deleteFileHandler(file.name)}
            />
          </div>
        )}
      </ul>
    </div>
  );
};

export default FileSelector;
