import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import startsWith from 'lodash.startswith';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { getBranchesUrl } from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import {
  addViewLat,
  addViewLng,
  setIsViewMap,
  setLocId,
} from '../../redux/organisation/organisationRegistration';
import { setBranch } from '../../redux/organisation/organisation';

const BranchesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDeactivateModal,
    onOpen: onOpenDeactivateModal,
    onClose: onCloseDeactivateModal,
  } = useDisclosure();

  const [branchName, setBranchName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState(true);

  const [branchItem, setBranchItem] = useState({});

  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);
  const { access_token } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);
  const [isLoading, setLoading] = useState(false);

  async function fetchBranches() {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .get(getBranchesUrl, {
        params: { org_id: organisation?.id ?? '' },
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        setBranches(response.data.items);
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to fetch branches.',
          fetchBranches,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }
  useEffect(() => {
    // Trigger the fetch
    fetchBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createBranch = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .post(
        getBranchesUrl,
        {
          name: branchName,
          org_id: organisation.id,
          contacts: {
            email: email,
            phone_number: phoneNumber,
          },
          location: {
            longitude: 0,
            latitude: 0,
          },
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);
        dispatch(setBranch(response.data?.id));

        onClose();
        fetchBranches();
        toast({
          position: 'bottom-left',
          title: 'Branch created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to create branch.',
          createBranch,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const updateBranch = async () => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(
        `${getBranchesUrl}${branchItem.id}/`,
        {
          name: `${branchName.length > 0 ? branchName : branchItem.name}`,
          contacts: {
            email: email,
            phone_number: phoneNumber,
          },
          location: {
            longitude: 0,
            latitude: 0,
          },
          is_active:
            active !== branchItem.is_active ? active : branchItem.is_active,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);

        onClose();
        fetchBranches();
        setBranchItem({});
        toast({
          position: 'bottom-left',
          title: 'Branch updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update branch.',
          updateBranch,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phoneNumber.length < 8) {
      setError('Enter a valid phone number');
    } else {
      setError('');
      if (branchItem.name) {
        updateBranch();
      } else {
        createBranch();
      }
    }
  };

  return (
    <div className='branches'>
      <Flex m={12} align='center'>
        <Container>
          <Heading as='h4' size='md' mb={2}>
            Organization branches
          </Heading>
          <Text noOfLines={1} fontSize='sm'>
            Create your organization branches
          </Text>
        </Container>

        <Spacer />
        <Button
          backgroundColor='#107e76'
          textColor='white'
          isDisabled={
            organisation?.id === undefined ||
            organisation?.id === '' ||
            !roles?.[organisation?.id]?.includes('Branches.create')
          }
          onClick={onOpen}
        >
          <Text noOfLines={1} textColor='white'>
            Create
          </Text>
        </Button>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setBranchItem({});
          setError('');
        }}
        size={'lg'}
      >
        <form onSubmit={handleSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Container mt={8}>
                {/* via email */}
                <Text as='b' fontSize='lg'>
                  {branchItem.name ? <>Update branch</> : <>Create a branch</>}
                </Text>
                <Text fontSize='sm' mb={8} mt={2}>
                  {!branchItem.name && (
                    <>
                      These are locations, other than the main office, where you
                      conduct business
                    </>
                  )}
                </Text>

                {/* name */}
                <Flex>
                  <Text fontSize={'sm'}>Branch name</Text>
                  <Text fontSize={'sm'} color='red'>
                    *
                  </Text>
                </Flex>
                <Input
                  isRequired={true}
                  size={'lg'}
                  fontSize={'md'}
                  type={'text'}
                  defaultValue={branchItem.name}
                  focusBorderColor='#107e76'
                  pr='4.5rem'
                  placeholder='Enter name'
                  onChange={(e) => setBranchName(e.target.value)}
                />

                {/* phone number */}
                <Flex mt={6}>
                  <Text fontSize={'sm'}>Branch phone number</Text>
                  <Text fontSize={'sm'} color='red'>
                    *
                  </Text>
                </Flex>
                <PhoneInput
                  isRequired={true}
                  inputStyle={{
                    height: '45px',
                    width: '100%',
                    backgroundColor: 'transparent',
                    borderColor: '#E2E8F0',
                    fontFamily: 'Nunito',
                    fontSize: '16px',
                    borderRadius: 10,
                  }}
                  country={'ke'}
                  value={
                    branchItem?.contacts?.[0]?.contact?.contact_type === 'PHONE'
                      ? branchItem?.contacts?.[0]?.contact?.contact_value
                      : branchItem?.contacts?.[1]?.contact?.contact_value
                  }
                  isValid={(inputNumber, country, countries) => {
                    return countries.some((country) => {
                      return (
                        startsWith(inputNumber, country.dialCode) ||
                        startsWith(country.dialCode, inputNumber)
                      );
                    });
                  }}
                  onChange={(phone) => setPhoneNumber(phone)}
                />

                {/* email */}
                <Flex mt={6}>
                  <Text fontSize={'sm'}>Branch email</Text>
                  <Text fontSize={'sm'} color='red'>
                    *
                  </Text>
                </Flex>
                <Input
                  size={'lg'}
                  fontSize={'md'}
                  borderRadius={10}
                  mb={6}
                  isRequired={true}
                  placeholder={'Enter email'}
                  type='email'
                  defaultValue={
                    branchItem?.contacts?.[0]?.contact?.contact_type === 'EMAIL'
                      ? branchItem?.contacts?.[0]?.contact?.contact_value
                      : branchItem?.contacts?.[1]?.contact?.contact_value
                  }
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='outline'
                mr={3}
                onClick={() => {
                  setError('');
                  onClose();
                  setBranchItem({});
                }}
              >
                Cancel
              </Button>
              <Button
                variant='solid'
                backgroundColor={'#107e76'}
                textColor='white'
                isLoading={isLoading}
                type='submit'
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <Modal
        isOpen={isOpenDeactivateModal}
        onClose={() => {
          onCloseDeactivateModal();
          setBranchItem({});
        }}
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Container mt={8}>
              {/* via email */}
              <Text as='b' fontSize='lg'>
                Deactivate branch
              </Text>
              <Text fontSize='sm' mb={8} mt={2}>
                <span style={{ fontWeight: 800 }}>{`${branchItem.name} `}</span>
                will be deactivated. You or any other team members will not be
                able to add a rota to this branch anymore.
              </Text>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onCloseDeactivateModal();
                setBranchItem({});
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={'#E05A5A'}
              textColor='white'
              isLoading={isLoading}
              onClick={() => {
                onCloseDeactivateModal();
                setActive(false);
                updateBranch();
              }}
            >
              Deactivate branch
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isLoading ? (
        <Center>
          <BounceLoader color={'#107e76'} loading={isLoading} size={80} />
        </Center>
      ) : (
        <TableContainer backgroundColor={'white'} m={12}>
          <Table>
            <Tbody>
              <Tr>
                <Th>Branch name</Th>
                <Th>Phone Number</Th>
                <Th>Email</Th>
                <Th>Date created</Th>
                <Th>Actions</Th>
              </Tr>
              {branches.length > 0 ? (
                branches.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      <Text textColor={'black'} fontSize='sm'>
                        {item.name}
                      </Text>
                    </Td>
                    <Td>
                      <Text textColor='black' fontSize='sm'>
                        {item.contacts[0]?.contact.contact_type === 'PHONE'
                          ? item.contacts[0]?.contact.contact_value ?? '-'
                          : item.contacts[1]?.contact.contact_value ?? '-'}
                      </Text>
                    </Td>
                    <Td>
                      <Text textColor='black' fontSize='sm'>
                        {item.contacts[0]?.contact.contact_type === 'EMAIL'
                          ? item.contacts[0]?.contact.contact_value ?? '-'
                          : item.contacts[1]?.contact.contact_value ?? '-'}
                      </Text>
                    </Td>

                    <Td>
                      <Text textColor='black' fontSize='sm'>
                        {moment(item.created_at).format('MMM DD, YYYY')}
                      </Text>
                      <Text fontSize='sm'>
                        {moment.utc(item.created_at).local().format('HH:mm a')}
                      </Text>
                    </Td>
                    <Td>
                      <Button
                        backgroundColor='#DAF2F0'
                        textColor='#107e76'
                        size='sm'
                        mr={4}
                        isDisabled={
                          organisation?.id === undefined ||
                          organisation?.id === '' ||
                          !roles?.[organisation?.id]?.includes(
                            'Branches.update'
                          )
                        }
                        onClick={() => {
                          onOpen();
                          setBranchItem(item);
                        }}
                      >
                        Edit Info
                      </Button>

                      <Button
                        backgroundColor='#DAF2F0'
                        textColor='#107e76'
                        size='sm'
                        mr={4}
                        isDisabled={
                          organisation?.id === undefined ||
                          organisation?.id === '' ||
                          !roles?.[organisation?.id]?.includes(
                            'Branches.update'
                          )
                        }
                        onClick={() => {
                          setBranchItem(item);
                          dispatch(addViewLat(item?.latitude));
                          dispatch(addViewLng(item?.longitude));
                          dispatch(setLocId(item?.id));
                          dispatch(setIsViewMap('branch'));
                          navigate('/maps');
                        }}
                      >
                        Edit Location
                      </Button>
                      {item.is_active && (
                        <Button
                          backgroundColor='#F8E0E5'
                          textColor='#C80024'
                          size='sm'
                          onClick={() => {
                            setBranchItem(item);
                            onOpenDeactivateModal();
                          }}
                        >
                          Deactivate
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan='5'>
                    <Text fontSize='sm'>No records found</Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
            {/* <CreateScheduleModal createScheduleModalRef={modalRef} /> */}
            {/* <DeleteWiThPassModal
            title='Delete Ward branchesPage'
            desc='branchesPage'
            deleteWiThPassModalRef={deleteModalRef}
          /> */}
          </Table>
        </TableContainer>
      )}
      {/* <CreateScheduleModal createScheduleModalRef={modalRef} /> */}
    </div>
  );
};

export default BranchesPage;
