import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../../../application/services/http_service';

const DeactivateModal = ({
  fetchPatients,
  selectedPatient,
  setSelectedPatient,
  isEdit,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);

  const [isPatientLoading, setPatientLoading] = useState(false);
  const deactivatePatient = async () => {
    setPatientLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .delete(`${'url'}${selectedPatient.id}/`, {
        headers: headers,
      })
      .then((response) => {
        setPatientLoading(false);
        fetchPatients();
        onClose();
        toast({
          position: 'bottom-left',
          title: `${selectedPatient.title} deactivated successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setPatientLoading(false);
        handleError(
          err,
          'Failed to deactivate patient record.',
          deactivatePatient,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedPatient({});
      }}
      size={'lg'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Container mt={8}>
            <Text as='b' fontSize='lg'>
              Deactivate Patient
            </Text>
            <Text fontSize='sm' mb={8} mt={2}>
              <span
                style={{ fontWeight: 800 }}
              >{`${selectedPatient.title} `}</span>
              {`will be 
                        ${
                          selectedPatient?.is_active
                            ? 'deactivated'
                            : 'activated'
                        }
                        .`}
            </Text>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            variant='outline'
            mr={3}
            onClick={() => {
              onClose();
              setSelectedPatient({});
            }}
          >
            Cancel
          </Button>
          <Button
            variant='solid'
            backgroundColor={selectedPatient?.is_active ? '#E05A5A' : '#107e76'}
            textColor='white'
            isLoading={isPatientLoading}
            onClick={() => {
              if (selectedPatient?.is_active) deactivatePatient();
            }}
          >
            {selectedPatient?.is_active ? 'Deactivate' : 'Activate'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeactivateModal;
