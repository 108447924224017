import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tour: '',
};

export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTour: (state, action) => {
      state.tour = action.payload;
    },

    resetTour: () => initialState,
  },
});

export const { tour, setTour, resetTour } = tourSlice.actions;

export default tourSlice.reducer;
