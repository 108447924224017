import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { RiArrowDownSFill, RiArrowRightSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  rolesUrl,
  userOrganisationsUrl,
  usersUrl,
} from '../../../application/services/http_endpoints';
import {
  handleError,
  useLogout,
} from '../../../application/services/http_service';
import { setActivePath } from '../../../redux/navigation/navigation';
import {
  setBranch,
  setOrganisation,
} from '../../../redux/organisation/organisation';
import { setRoleName, setRoles } from '../../../redux/roles/roles';
import './TopBar.scss';

const TopBar = ({ onOpenDrawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { decodedRoles, roleName } = useSelector((state) => state.roles);
  const { isInvited } = useSelector((state) => state.invite);

  const toast = useToast();
  const [organisations, setOrganisations] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeactivateOpen,
    onOpen: onDeactivateOpen,
    onClose: onDeactivateClose,
  } = useDisclosure();

  const { logoutUser } = useLogout();

  // fetch user roles
  async function fetchRole(org_id) {
    if (decodedRoles[org_id]) {
      //  user has a role assigned get role info

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': org_id,
      };
      axios
        .get(`${rolesUrl}${decodedRoles[org_id]}/`, {
          // params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((response) => {
          dispatch(
            setRoles({
              key: org_id,
              value: response.data.user_facing_permissions,
            })
          );
          dispatch(setRoleName(response.data.name));
        })
        .catch((err) => {
          handleError(
            err,
            'Failed to fetch roles.',
            fetchRole,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  const [isDeactivateLoading, setDeactivateLoading] = useState(false);

  // deactivate account
  const deactivateUser = async () => {
    setDeactivateLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .patch(
        `${usersUrl}${user?.user?.id}/`,
        { is_active: false },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setDeactivateLoading(false);
        toast({
          position: 'bottom-left',
          title: 'Your account has been deactivated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        logoutUser();
      })
      .catch((err) => {
        setDeactivateLoading(false);
        handleError(
          err,
          'Failed to deactivate account.',
          deactivateUser,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  useEffect(() => {
    // fetch org info
    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      };
      axios
        .get(userOrganisationsUrl, {
          headers: headers,
        })
        .then((response) => {
          setOrganisations(response.data.items);
          // set default signed in org
          if (
            (!organisation?.id || organisation?.id === '') &&
            response.data.items.length > 0
          ) {
            fetchRole(response.data.items[0]?.id);
            dispatch(setOrganisation(response.data.items[0]));
            dispatch(setBranch(response.data.items[0]?.branches[0]?.id));
          }
        })
        .catch((err) => {
          handleError(
            err,
            'Failed to fetch organisations.',
            fetchData,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
    // Trigger the fetch
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, isInvited]);

  function trimOrgName(name) {
    const words = name.split(' ');
    // Check if the array has at least three words
    if (words.length >= 3) {
      words.splice(2, 1); // Remove the third word
    }
    const modifiedString = words.join(' ');

    return modifiedString;
  }

  return (
    <Box w='100%' align='top'>
      {/* organisation status alert */}
      {organisation?.organisation_type !== 'INDIVIDUAL' &&
      organisation?.id !== undefined &&
      organisation?.id !== '' &&
      organisation.status !== 'VERIFIED' ? (
        <Tooltip
          label={`Your organisation is ${organisation.status}. Contact us at hello@ratiba.co.ke for more information or log in again if it has been resolved.`}
        >
          <Alert
            status={organisation.status === 'REJECTED' ? 'error' : 'warning'}
          >
            <AlertIcon />
            <AlertDescription noOfLines={1} mr={1}>
              Your organisation is
            </AlertDescription>
            <AlertDescription noOfLines={1} as='b'>
              {organisation.status}
            </AlertDescription>
            <AlertDescription noOfLines={1}>
              . Contact us at hello@ratiba.co.ke for more information.
            </AlertDescription>
          </Alert>
        </Tooltip>
      ) : null}
      <Flex p={4}>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpenDrawer}
          variant='outline'
          aria-label='open menu'
          icon={<FiMenu />}
          mr={4}
        />
        {organisation?.organisation_type !== 'INDIVIDUAL' && (
          <div>
            <Text
              ml={8}
              textColor={'black'}
              fontWeight='bold'
              minW={40}
              noOfLines={1}
            >
              {organisation?.name}
            </Text>
            {organisation?.branches?.length > 0 ? (
              <Select
                ml={8}
                size='sm'
                textColor='#EF9920'
                iconColor='#EF9920'
                placeholder='Select branch'
                variant='unstyled'
                value={branch}
                onChange={(e) => {
                  dispatch(setBranch(e.target.value));
                  navigate('/');
                  toast({
                    position: 'bottom-left',
                    title: 'Branch switched successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                }}
              >
                {organisation.branches.map((branch, index) => {
                  return (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  );
                })}
              </Select>
            ) : null}
          </div>
        )}
        <Spacer />
        {/* <Box
        display={{ base: 'none', md: 'flex' }}
        minW={20}
        mr={4}
        className={`searchbar ${
          isSearchActive || query.length > 0 ? 'active-searchbar' : ''
        }`}
      >
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            children={<AiOutlineSearch className='search-icon' />}
          />
          <Input
            placeholder='Search'
            className='search-input'
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => setSearchActive(true)}
            onBlur={() => setSearchActive(false)}
          />
        </InputGroup>
      </Box> */}
        <Spacer />

        {/* user avatar */}
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            // w={40}
          >
            <Flex alignItems={'center'}>
              <Avatar
                name={`${user.user.first_name} ${user.user.last_name}`}
                size='md'
                backgroundColor='#C6DDDB'
                textColor={'#107e76'}
              />
              <Box ml={2} display={{ base: 'none', md: 'block' }}>
                <Text
                  noOfLines={1}
                  fontSize={'sm'}
                  textColor={'black'}
                  fontWeight={'bold'}
                >
                  {`${user.user.first_name} ${user.user.last_name}`}
                </Text>
                <Text
                  style={{ 'text-transform': 'capitalize' }}
                  noOfLines={1}
                  fontSize={'sm'}
                  textColor={'black'}
                  fontWeight={'bold'}
                >
                  {roleName ?? 'Not assigned'}
                </Text>
              </Box>
              <RiArrowDownSFill className='search-icon' />
            </Flex>
          </MenuButton>
          <MenuList alignItems={'center'}>
            {organisation?.id !== undefined && organisation?.id !== '' ? (
              <div>
                {/* signed in to */}
                <Container p={5} onClick={onOpen}>
                  <Text fontSize={'sm'}>You're signed in to:</Text>
                  <br />
                  <Center borderRadius={15} backgroundColor={'white'}>
                    <Avatar
                      size={'md'}
                      name={
                        organisation?.organisation_type === 'INDIVIDUAL'
                          ? trimOrgName(organisation?.name)
                          : organisation?.name
                      }
                      backgroundColor='#C6DDDB'
                      textColor={'#107e76'}
                      fontWeight={'bold'}
                    />

                    <Container>
                      <Text
                        textColor={'blackAlpha.800'}
                        fontSize={'sm'}
                        fontWeight={'bold'}
                        noOfLines={1}
                      >
                        {organisation?.organisation_type === 'INDIVIDUAL'
                          ? trimOrgName(organisation?.name)
                          : organisation?.name}
                      </Text>
                      <Text
                        style={{ 'text-transform': 'capitalize' }}
                        fontSize={'sm'}
                      >
                        {roleName ?? 'Not assigned'}
                      </Text>
                    </Container>
                    <RiArrowRightSLine className='search-icon' />
                  </Center>
                </Container>
                {/*switch organisation */}
                <MenuDivider />
                <MenuItem onClick={onOpen}>Switch organisation</MenuItem>
              </div>
            ) : null}

            {/* settings */}
            <MenuDivider />
            <MenuItem
              onClick={() => {
                navigate('/settings');
                dispatch(setActivePath('/settings'));
              }}
            >
              Settings
            </MenuItem>
            {/* Logout */}
            <MenuDivider />
            <MenuItem onClick={() => logoutUser()}>
              <Text fontSize={'md'} color={'#c80024'}>
                Logout of Ratiba
              </Text>
            </MenuItem>
            {/* deactivate */}
            <MenuDivider />
            <MenuItem onClick={() => onDeactivateOpen()}>
              <Text fontSize={'md'} color={'#c80024'}>
                Deactivate account
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>

        {/* switch companies */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Switch Account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container>
                {organisations.map((organization, index) => (
                  <Center
                    key={organization.id}
                    mb={2}
                    p={3}
                    borderRadius={15}
                    backgroundColor={
                      organisation.id === organization.id ? '#EDF9F8' : 'white'
                    }
                    onClick={() => {
                      fetchRole(organization?.id);
                      dispatch(setOrganisation(organization));
                      dispatch(setBranch(organization.branches[0]?.id));
                      onClose();
                      navigate('/');
                      toast({
                        position: 'bottom-left',
                        title: `Signed in to ${
                          organization?.organisation_type === 'INDIVIDUAL'
                            ? 'your personal account'
                            : organization?.name
                        }`,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                      });
                    }}
                  >
                    <Avatar
                      size={'md'}
                      name={organization.name}
                      backgroundColor='#C6DDDB'
                      textColor={'#107e76'}
                      fontWeight={'bold'}
                    />

                    <Container>
                      <Text
                        textColor={
                          organisation.id === organization.id
                            ? '#107e76'
                            : 'blackAlpha.800'
                        }
                        fontSize={'sm'}
                        fontWeight={'bold'}
                        noOfLines={1}
                      >
                        {organization?.organisation_type === 'INDIVIDUAL'
                          ? trimOrgName(organization?.name)
                          : organization?.name}
                      </Text>
                      {/* {organization?.organisation_type !== 'INDIVIDUAL' && (
                        <Text
                          textColor={
                            organisation.id === organization.id
                              ? '#107e76'
                              : null
                          }
                          fontSize={'sm'}
                        >
                          {organization.status}
                        </Text>
                      )} */}
                      <Tag
                        mt={1}
                        fontSize='xs'
                        fontWeight='bold'
                        borderRadius='full'
                        backgroundColor={
                          organization?.organisation_type !== 'INDIVIDUAL'
                            ? '#EF99204D'
                            : 'lightblue'
                        }
                        textColor={
                          organization?.organisation_type !== 'INDIVIDUAL'
                            ? '#EF9920'
                            : '#107e76'
                        }
                        variant='solid'
                      >
                        {organization?.organisation_type === 'INDIVIDUAL'
                          ? 'PERSONAL'
                          : organization?.organisation_type}
                      </Tag>
                    </Container>

                    <RiArrowRightSLine className='search-icon' />
                  </Center>
                ))}
              </Container>
            </ModalBody>

            <ModalFooter>
              <Button variant='ghost' onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* deactivate account */}
        <Modal
          isOpen={isDeactivateOpen}
          onClose={() => onDeactivateClose()}
          size={'lg'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Container mt={8}>
                <Text as='b' fontSize='lg'>
                  Deactivate account
                </Text>
                <Text fontSize='sm' mb={8} mt={2}>
                  Your account will be deactivated.
                </Text>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='outline'
                mr={3}
                onClick={() => onDeactivateClose()}
              >
                Cancel
              </Button>
              <Button
                variant='solid'
                backgroundColor={'#E05A5A'}
                textColor='white'
                isLoading={isDeactivateLoading}
                onClick={() => deactivateUser()}
              >
                Deactivate
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default TopBar;
