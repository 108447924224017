import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { statBgIconSvgPath } from '../../application/services/asset_strings';
import {
  patientRecordsUrl,
  patientStatsUrl,
  procedureStatsUrl,
  proceduresUrl,
} from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import AddPatientModal from './components/modals/AddPatientModal';
import AddProceduresModal from './components/modals/AddProceduresModal';
import DeactivateModal from './components/modals/DeactivatePatientModal';
import PatientsTable from './components/tables/PatientsTable';
import ProceduresTable from './components/tables/ProceduresTable';
// import RotaMembersModal from './RotaMembersModal';
// import RotaModal from './RotaModal';

const RecordsPage = () => {
  const {
    isOpen: isOpenDeactivateModal,
    onOpen: onOpenDeactivateModal,
    onClose: onCloseDeactivateModal,
  } = useDisclosure();
  const {
    isOpen: isOpenPatientModal,
    onOpen: onOpenPatientModal,
    onClose: onClosePatientModal,
  } = useDisclosure();

  const {
    isOpen: isOpenProcedureModal,
    onOpen: onOpenProcedureModal,
    onClose: onClosePProcedureModal,
  } = useDisclosure();

  const [selectedPatient, setSelectedPatient] = useState({});
  const [isEdit, setEdit] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(false);

  // fetch patients
  const [patients, setPatients] = useState([]);

  async function fetchPatients() {
    // if (roles?.[organisation?.id]?.includes('Patients.list')) {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .get(patientRecordsUrl, {
        params: { branch_id: branch ?? '' },
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        setPatients(response.data.items);
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to fetch patient records.',
          fetchPatients,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
    // }
  }

  // fetch procedures
  const [procedures, setProcedures] = useState([]);
  const [proceduresLoading, setProceduresLoading] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState({});

  async function fetchProcedures() {
    // if (roles?.[organisation?.id]?.includes('Procedures.list')) {
    setProceduresLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .get(proceduresUrl, {
        params: { branch_id: branch ?? '' },
        headers: headers,
      })
      .then((response) => {
        setProceduresLoading(false);
        setProcedures(response.data.items);
      })
      .catch((err) => {
        setProceduresLoading(false);
        handleError(
          err,
          'Failed to fetch procedures records.',
          fetchProcedures,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
    // }
  }

  // fetch procedure stats
  const [procedureStats, setProcedureStats] = useState({});
  const [procedureStatsLoading, setProcedureStatsLoading] = useState(false);

  async function fetchProcedureStats() {
    // if (roles?.[organisation?.id]?.includes('Procedures.list')) {
    setProcedureStatsLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .get(`${procedureStatsUrl}${branch}`, {
        headers: headers,
      })
      .then((response) => {
        setProcedureStatsLoading(false);
        setProcedureStats(response.data);
      })
      .catch((err) => {
        setProcedureStatsLoading(false);
        handleError(
          err,
          'Failed to fetch procedures stats.',
          fetchProcedures,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
    // }
  }

  // fetch patient stats
  const [patientStats, setPatientStats] = useState({});
  const [patientStatsLoading, setPatientStatsLoading] = useState(false);

  async function fetchPatientStats() {
    // if (roles?.[organisation?.id]?.includes('Procedures.list')) {
    setPatientStatsLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .get(`${patientStatsUrl}${branch}`, {
        headers: headers,
      })
      .then((response) => {
        setPatientStatsLoading(false);
        setPatientStats(response.data);
      })
      .catch((err) => {
        setPatientStatsLoading(false);
        handleError(
          err,
          'Failed to fetch procedures stats.',
          fetchProcedures,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
    // }
  }

  useEffect(() => {
    // Trigger the fetch
    fetchProcedures();
    fetchPatients();
    fetchProcedureStats();
    fetchPatientStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPatientModal = (isEdit) => {
    setEdit(isEdit);
    onOpenPatientModal();
  };

  const openProceduresModal = (isEdit) => {
    setEdit(isEdit);
    onOpenProcedureModal();
  };

  return (
    <div>
      <Box ml={12} mt={8}>
        <Heading as='h4' size='md' mb={2}>
          Records
        </Heading>
        <Text fontSize='sm'>Add and review records</Text>
      </Box>

      {/*  tabs */}
      <Tabs
        variant='solid-rounded'
        defaultIndex={
          roles?.[organisation?.id]?.includes('Shifts.list') ? 0 : 1
        }
      >
        <TabList ml={12} mt={8}>
          <Tab
            _selected={{ color: 'white', bg: '#107e76' }}
            // isDisabled={!roles?.[organisation?.id]?.includes('Procedures.list')}
            // onClick={() => triggerFetch()}
          >
            Procedures
          </Tab>
          <Tab
            _selected={{ color: 'white', bg: '#107e76' }}
            // isDisabled={!roles?.[organisation?.id]?.includes('Patients.list')}
            // onClick={() => fetchRotas()}
          >
            Patients
          </Tab>
        </TabList>

        <TabPanels>
          {/* procedures */}

          <TabPanel>
            {/* procedure stats */}
            <Center m={8}>
              <Wrap spacing='60px'>
                <Center>
                  {/* no of procedures */}
                  <Card
                    variant={'outline'}
                    background='white'
                    w={220}
                    bgImg={statBgIconSvgPath}
                  >
                    <CardBody>
                      <Stat>
                        <StatLabel textColor='white'>
                          No of procedures
                        </StatLabel>
                        <StatNumber textColor='white'>
                          {procedureStats?.total_of_procedures ?? '-'}
                        </StatNumber>
                        {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
                      </Stat>
                    </CardBody>
                  </Card>
                </Center>

                {/* pending */}
                <Center>
                  <Card
                    variant={'outline'}
                    background='white'
                    w={220}
                    bgImg={statBgIconSvgPath}
                  >
                    <CardBody>
                      <Stat>
                        <StatLabel textColor='white'>
                          Pending procedures
                        </StatLabel>
                        <StatNumber textColor='white'>
                          {procedureStats?.pending_procedures ?? '-'}
                        </StatNumber>
                        {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
                      </Stat>
                    </CardBody>
                  </Card>
                </Center>

                {/* accepted */}
                <Center>
                  <Card
                    variant={'outline'}
                    background='white'
                    w={220}
                    bgImg={statBgIconSvgPath}
                  >
                    <CardBody>
                      <Stat>
                        <StatLabel textColor='white'>
                          Accepted procedures
                        </StatLabel>
                        <StatNumber textColor='white'>
                          {procedureStats?.accepted_procedures ?? '-'}
                        </StatNumber>
                        {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
                      </Stat>
                    </CardBody>
                  </Card>
                </Center>

                {/* rejected */}
                <Center>
                  <Card
                    variant={'outline'}
                    background='white'
                    w={220}
                    bgImg={statBgIconSvgPath}
                  >
                    <CardBody>
                      <Stat>
                        <StatLabel textColor='white'>
                          Rejected procedures
                        </StatLabel>
                        <StatNumber textColor='white'>
                          {procedureStats?.rejected_procedures ?? '-'}
                        </StatNumber>
                        {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
                      </Stat>
                    </CardBody>
                  </Card>
                </Center>
              </Wrap>
            </Center>

            {/* add procedure */}
            <Flex m={12} align='center'>
              <Spacer />
              <Button
                noOfLines={1}
                backgroundColor='#107e76'
                textColor='white'
                // isDisabled={
                //   !roles?.[organisation?.id]?.includes('Procedures.create')
                // }
                onClick={() => {
                  openProceduresModal(false);
                  setSelectedProcedure({});
                }}
              >
                Add procedure
              </Button>
            </Flex>

            {proceduresLoading ? (
              <Center>
                <BounceLoader
                  color={'#107e76'}
                  loading={proceduresLoading}
                  size={80}
                />
              </Center>
            ) : (
              <ProceduresTable
                procedures={procedures}
                openModal={openProceduresModal}
                setSelectedProcedure={setSelectedProcedure}
                onOpenDeactivateModal={onOpenDeactivateModal}
                setItems={setItems}
              />
            )}

            {/* add patient modal */}
            <AddProceduresModal
              fetchProcedures={fetchProcedures}
              fetchProcedureStats={fetchProcedureStats}
              selectedProcedure={selectedProcedure}
              isOpen={isOpenProcedureModal}
              onClose={onClosePProcedureModal}
              isEdit={isEdit}
              items={items}
              setItems={setItems}
            />
          </TabPanel>

          {/* patients */}
          <TabPanel>
            <Box>
              {/* patient stats */}
              <Center m={8}>
                <Wrap spacing='60px'>
                  <Center>
                    {/* no of patients */}
                    <Card
                      variant={'outline'}
                      background='white'
                      w={220}
                      bgImg={statBgIconSvgPath}
                    >
                      <CardBody>
                        <Stat>
                          <StatLabel textColor='white'>
                            No of patients
                          </StatLabel>
                          <StatNumber textColor='white'>
                            {patientStats?.total_of_patient_records ?? '-'}
                          </StatNumber>
                        </Stat>
                      </CardBody>
                    </Card>
                  </Center>

                  {/* pending */}
                  <Center>
                    <Card
                      variant={'outline'}
                      background='white'
                      w={220}
                      bgImg={statBgIconSvgPath}
                    >
                      <CardBody>
                        <Stat>
                          <StatLabel textColor='white'>
                            Pending patients
                          </StatLabel>
                          <StatNumber textColor='white'>
                            {patientStats?.pending_patient_records ?? '-'}
                          </StatNumber>
                        </Stat>
                      </CardBody>
                    </Card>
                  </Center>

                  {/* accepted */}
                  <Center>
                    <Card
                      variant={'outline'}
                      background='white'
                      w={220}
                      bgImg={statBgIconSvgPath}
                    >
                      <CardBody>
                        <Stat>
                          <StatLabel textColor='white'>
                            Accepted patients
                          </StatLabel>
                          <StatNumber textColor='white'>
                            {patientStats?.accepted_patient_records ?? '-'}
                          </StatNumber>
                        </Stat>
                      </CardBody>
                    </Card>
                  </Center>

                  {/* rejected */}
                  <Center>
                    <Card
                      variant={'outline'}
                      background='white'
                      w={220}
                      bgImg={statBgIconSvgPath}
                    >
                      <CardBody>
                        <Stat>
                          <StatLabel textColor='white'>
                            Rejected patients
                          </StatLabel>
                          <StatNumber textColor='white'>
                            {patientStats?.rejected_patient_records ?? '-'}
                          </StatNumber>
                        </Stat>
                      </CardBody>
                    </Card>
                  </Center>
                </Wrap>
              </Center>

              {/* add patient */}
              <Flex m={12} align='center'>
                <Spacer />
                <Button
                  noOfLines={1}
                  backgroundColor='#107e76'
                  textColor='white'
                  // isDisabled={
                  //   !roles?.[organisation?.id]?.includes('Patients.create')
                  // }
                  onClick={() => {
                    openPatientModal(false);
                    setSelectedPatient({});
                  }}
                >
                  Add patient
                </Button>
              </Flex>

              {loading ? (
                <Center>
                  <BounceLoader color={'#107e76'} loading={loading} size={80} />
                </Center>
              ) : (
                <PatientsTable
                  patients={patients}
                  openModal={openPatientModal}
                  setSelectedPatient={setSelectedPatient}
                  onOpenDeactivateModal={onOpenDeactivateModal}
                />
              )}

              {/* add patient modal */}
              <AddPatientModal
                fetchPatients={fetchPatients}
                fetchPatientStats={fetchPatientStats}
                selectedPatient={selectedPatient}
                isOpen={isOpenPatientModal}
                onClose={onClosePatientModal}
                isEdit={isEdit}
              />

              {/* deactivate modal */}
              <DeactivateModal
                selectedPatient={selectedPatient}
                setSelectedPatient={setSelectedPatient}
                isOpen={isOpenDeactivateModal}
                onClose={onCloseDeactivateModal}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default RecordsPage;
