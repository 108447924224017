import './Reports.scss';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import TopBar from '../../../core/TopBar/TopBar';
import ReportsTable from '../../../core/tables/reports_table/ReportsTable';

const Reports = () => {
  return (
    <div className='reports'>
      <TopBar />
      <div className='reportsWrapper'>
        <div className='reportsHeader'>
          <h4 className='reportsHeaderTitle'>
            <NavLink
              exact
              to='/settings'
              key='/settings'
              className='link'
              activeClassName='active'
            >
              Settings
            </NavLink>
            <IoIosArrowForward /> <span className='reportsText'> Reports</span>
          </h4>
        </div>
        <div className='reportsBody'>
          <ReportsTable />
        </div>
      </div>
    </div>
  );
};

export default Reports;
