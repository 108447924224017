import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Calendar } from 'react-date-range';

const CalendarModal = ({ isOpen, onClose, date, setDate, resetShifts }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box mt={4}>
            <Text as='b' fontSize='lg'>
              Select Date
            </Text>

            <Center mt={8}>
              <Calendar
                color='#107e76'
                onChange={(item) => {
                  setDate(item);
                  resetShifts();
                  onClose();
                }}
                date={date}
              />
            </Center>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CalendarModal;
