import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contactId: '',
  email: '',
};

export const userForgotPasswordSlice = createSlice({
  name: 'userForgotPassword',
  initialState,
  reducers: {
    addContactId: (state, action) => {
      state.contactId = action.payload;
    },
    addEmail: (state, action) => {
        state.email = action.payload;
      },
  },
});

// Action creators are generated for each case reducer function
export const { addEmail, addContactId } = userForgotPasswordSlice.actions;

export default userForgotPasswordSlice.reducer;
