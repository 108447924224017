import {
  Button,
  Container,
  Flex,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../../../application/services/http_service';
import axios from 'axios';
import {
  branchMemberUrl,
  patientRecordsUrl,
} from '../../../../application/services/http_endpoints';
import { MdAdd, MdClose } from 'react-icons/md';

const AddPatientModal = ({
  fetchPatients,
  fetchPatientStats,
  selectedPatient,
  isEdit,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);

  const [isLoading, setLoading] = useState(false);

  const [noOfPatients, setNoOfPatients] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [branchMembers, setBranchMembers] = useState([]);
  const [searchlabel, setSearchLabel] = useState('');
  const [error, setError] = useState('');

  async function clearState() {
    setNoOfPatients();
    setSelectedDoctor();
    setBranchMembers([]);
    setSearchLabel('');
    setError('');
  }

  const createPatient = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .post(
        patientRecordsUrl,
        {
          no_of_patients_seen: noOfPatients,
          doctor_id: selectedDoctor?.id, //   branch_id: branch,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);
        clearState();
        onClose();
        fetchPatients();
        fetchPatientStats();
        toast({
          position: 'bottom-left',
          title: 'Patient record created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to create patient record.',
          createPatient,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const updatePatient = async (payload) => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(`${patientRecordsUrl}${selectedPatient.id}/`, payload, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        clearState();
        onClose();
        fetchPatients();
        fetchPatientStats();
        toast({
          position: 'bottom-left',
          title: 'Patient record updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update patient record.',
          updatePatient,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  // search branch members
  const [searchLoading, setSearchLoading] = useState(false);
  const searchtRef = useRef(null);

  async function searchBranchMembers(name) {
    if (roles?.[organisation?.id]?.includes('BranchMembers.list')) {
      if (!name || name === '') {
        setBranchMembers([]);
        setSearchLoading(false);
        setSearchLabel('');
      } else {
        setSearchLabel('Searching...');
        setSearchLoading(true);

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
          'organisation-id': organisation?.id,
        };
        axios
          .get(branchMemberUrl, {
            params: { branch_id: branch ?? '', first_name: name },
            headers: headers,
          })
          .then((res) => {
            setSearchLoading(false);
            setBranchMembers(res.data.items);
            if (res.data.items?.length <= 0) {
              setSearchLabel(`${name} not found`);
            } else {
              setSearchLabel('');
            }
          })
          .catch((err) => {
            setSearchLoading(false);
            handleError(
              err,
              'Failed to search doctor.',
              searchBranchMembers,
              access_token,
              dispatch,
              toast,
              navigate
            );
          });
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEdit && !selectedDoctor?.id) {
      setError('Enter doctor on duty');
    } else {
      setError();
      isEdit
        ? updatePatient({
            no_of_patients_seen:
              noOfPatients ?? selectedPatient.no_of_patients_seen,
            doctor_id: selectedDoctor?.id ?? selectedPatient?.doctor_id,
            status: selectedPatient.status ?? 'PENDING',
          })
        : createPatient();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        clearState();
      }}
      size={'lg'}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Container mt={8}>
              {/* title */}
              <Text as='b' fontSize='lg'>
                {isEdit ? 'Edit patient record' : 'Add patient record'}
              </Text>

              {/* no of patient*/}
              <Flex mt={8}>
                <Text fontSize={'sm'}>No of patients</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  required={true}
                  type={'number'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  placeholder='Enter no of patients'
                  defaultValue={selectedPatient?.no_of_patients_seen}
                  onChange={(e) => setNoOfPatients(e.target.value)}
                />
              </InputGroup>

              {/* search doctor */}
              <Flex>
                <Text fontSize={'sm'}>Doctor on duty</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  type={'text'}
                  pr='4.5rem'
                  focusBorderColor='#107e76'
                  placeholder='Search by firstname'
                  defaultValue={
                    selectedPatient?.doctor?.org_member?.user?.first_name
                  }
                  ref={searchtRef}
                  onChange={(e) => searchBranchMembers(e.target.value)}
                />
              </InputGroup>

              {error && <div style={{ color: 'red' }}>{error}</div>}

              {searchLoading !== '' && (
                <Container mb={8}>
                  <Text fontSize='sm' as='i' textColor='#107e76'>
                    {searchlabel}
                  </Text>
                </Container>
              )}

              {branchMembers.map((item) => (
                <Tag
                  mr={3}
                  mb={8}
                  size={'lg'}
                  borderRadius='full'
                  variant={selectedDoctor?.id === item.id ? 'outline' : 'solid'}
                  colorScheme={
                    selectedDoctor?.id === item.id ? 'yellow' : 'teal'
                  }
                >
                  <TagLabel>{`${item?.org_member?.user?.first_name} ${item?.org_member?.user?.last_name} `}</TagLabel>
                  <TagRightIcon
                    as={selectedDoctor?.id === item.id ? MdClose : MdAdd}
                    onClick={() => {
                      if (selectedDoctor?.id === item.id) {
                        setBranchMembers(
                          branchMembers.filter((a) => a?.id !== item.id)
                        );
                        setSelectedDoctor({});
                      } else setSelectedDoctor(item);
                      searchtRef.current.value = '';
                    }}
                  />
                </Tag>
              ))}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onClose();
                clearState();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={'#107e76'}
              textColor='white'
              isLoading={isLoading}
              isDisabled={isEdit && !noOfPatients && !selectedDoctor}
              type='submit'
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddPatientModal;
