import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import './InputField.scss';
import PhoneInput from 'react-phone-input-2';

const InputField = ({
  isRequired,
  type,
  title,
  placeholder,
  pattern,
  error,
  name,
  onChange,
  max,
  value,
}) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  switch (type) {
    case 'phone':
      return (
        <Box className='inputField' mb={8}>
          <p>
            {title}
            {isRequired && <span>*</span>}
          </p>
          <PhoneInput
            inputStyle={{
              height: '45px',
              width: '100%',
              backgroundColor: 'transparent',
              borderColor: '#E2E8F0',
              fontFamily: 'Nunito',
              fontSize: '16px',
              borderRadius: 10,
            }}
            country={'ke'}
            value={value}
            onChange={onChange}
          />
        </Box>
      );

    case 'password':
      return (
        <div className='inputField'>
          <p>
            {title}
            {isRequired && <span>*</span>}
          </p>
          <InputGroup size='md'>
            <Input
              isRequired={isRequired}
              placeholder={placeholder}
              type={show ? 'text' : 'password'}
              onChange={onChange}
              focusBorderColor='#16ACA1'
              mb={8}
              autocomplete='new-password'
            />
            <InputRightElement width='4.5rem'>
              <Button
                variant='ghost'
                h='1.75rem'
                size='sm'
                onClick={handleClick}
              >
                {show ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>
      );

    case 'gender':
      return (
        <div className='inputField'>
          <p>
            {title}
            {isRequired && <span>*</span>}
          </p>
          <div className='inputContainer'>
            <Select
              size={'lg'}
              fontSize={'md'}
              focusBorderColor='#16ACA1'
              borderRadius={10}
              mb={8}
              // selected={gender}
              placeholder='Select gender'
              isRequired={true}
              onChange={onChange}
            >
              <option value='MALE'>MALE</option>
              <option value='FEMALE'>FEMALE</option>
              <option value='OTHER'>OTHER</option>
            </Select>
          </div>
          <p className='error'>{error}</p>
        </div>
      );

    default:
      return (
        <div className='inputField'>
          <p>
            {title}
            {isRequired && <span>*</span>}
          </p>
          <div className='inputContainer'>
            <Input
              t={type}
              name={name}
              placeholder={placeholder}
              type={type}
              required={isRequired}
              pattern={pattern}
              onChange={onChange}
              max={max}
              focusBorderColor='#16ACA1'
              mb={8}
              value={value}
            />
          </div>
          <p className='error'>{error}</p>
        </div>
      );
  }
};

export default InputField;
