import {
  Box,
  Card,
  CardBody,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Select,
  Spacer,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BounceLoader } from 'react-spinners';
import {
  employeesTimeSheetrUrl,
  timeSheetHoursrUrl,
} from '../../application/services/http_endpoints';
import useFetch from '../../application/services/http_service';
import TimeSheetTable from '../core/tables/timesheet_table/TimeSheetTable';

const TimeSheetPage = () => {
  const [isLoading, setLoading] = useState(false);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { access_token } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.roles);

  const url =
    branch === undefined || branch === ''
      ? timeSheetHoursrUrl
      : `${timeSheetHoursrUrl}${branch}/`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
    'organisation-id': organisation?.id,
  };

  // fetch timesheet Hours
  const {
    data: timeSheetHours,
    loading: loadingTimesheetHours,
    fetchDataByRole: fetchTimeSheetHours,
  } = useFetch({
    url: url,
    // params: params,
    headers: headers,
    role: 'TimeSheet.view_branch_timesheet',
    errMsg: 'Failed to fetch work hours.',
  });

  // fetch epmloyee timesheet Hours
  const {
    data: timesheets,
    loading: loadingEmployeeTimesheet,
    fetchDataByRole: fetchEmployeeTimesheet,
  } = useFetch({
    url: `${employeesTimeSheetrUrl}${branch}/`,
    params: { days: moment().day() },
    headers: headers,
    role: 'TimeSheet.view_branch_shift_hours',
    errMsg: 'Failed to fetch timesheet.',
  });

  useEffect(() => {
    // Trigger the fetch
    fetchTimeSheetHours();
    fetchEmployeeTimesheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadingTimesheetHours || loadingEmployeeTimesheet ? (
    <Center mt={8}>
      <BounceLoader
        color={'#107e76'}
        loading={loadingTimesheetHours || loadingEmployeeTimesheet}
        size={80}
      />
    </Center>
  ) : (
    <div>
      {roles?.[organisation?.id]?.includes(
        'TimeSheet.view_branch_timesheet'
      ) ? (
        <Flex m={12}>
          {/* week */}
          <Box w={400}>
            <Card variant={'outline'} background='white' mb={8} mr={8}>
              <CardBody>
                <HStack spacing='4px'>
                  <Text fontSize='sm' mr={0}>
                    Total time this
                  </Text>
                  <Text fontSize='sm' color='#107e76' as='b'>
                    week
                  </Text>
                </HStack>

                <HStack spacing='4px'>
                  <Heading noOfLines={1}>{timeSheetHours?.weekly}</Heading>
                  <Text fontSize='sm'>hours</Text>
                </HStack>
              </CardBody>
            </Card>
          </Box>
          <Spacer />
          {/* month */}
          <Box w={400}>
            <Card variant={'outline'} background='white' mb={8} mr={8}>
              <CardBody>
                <HStack spacing='4px'>
                  <Text fontSize='sm' mr={0}>
                    Total time this
                  </Text>
                  <Text fontSize='sm' color='#107e76' as='b'>
                    month
                  </Text>
                </HStack>

                <HStack spacing='4px'>
                  <Heading noOfLines={1}>{timeSheetHours?.monthly}</Heading>
                  <Text fontSize='sm'>hours</Text>
                </HStack>
              </CardBody>
            </Card>
          </Box>

          <Spacer />
          {/* year */}
          <Box w={400}>
            <Card variant={'outline'} background='white' mb={8} mr={8}>
              <CardBody>
                <HStack spacing='4px'>
                  <Text fontSize='sm' mr={0}>
                    Total time this
                  </Text>
                  <Text fontSize='sm' color='#107e76' as='b'>
                    year
                  </Text>
                </HStack>

                <HStack spacing='4px'>
                  <Heading noOfLines={1}>{timeSheetHours?.yearly}</Heading>
                  <Text fontSize='sm'>hours</Text>
                </HStack>
              </CardBody>
            </Card>
          </Box>
        </Flex>
      ) : null}

      {roles?.[organisation?.id]?.includes(
        'TimeSheet.view_branch_shift_hours'
      ) ? (
        <Box>
          <Flex m={12} align='center'>
            <Container>
              <Heading as='h4' size='md' mb={2}>
                Time sheet
              </Heading>
              <Text noOfLines={1} fontSize='sm'>
                Track employees working hours and shifts
              </Text>
            </Container>

            <Spacer />
            <Text noOfLines={1} fontSize='sm' mr={2}>
              Filter hours by:
            </Text>
            <Select variant='unstyled' placeholder='Week' size='sm' w={20} />
          </Flex>

          {/* employee table */}
          <TimeSheetTable data={timesheets} />
        </Box>
      ) : null}

      {roles?.[organisation?.id]?.includes(
        'TimeSheet.view_personal_timesheet'
      ) &&
      !timeSheetHours &&
      !timesheets ? (
        <Center>
          <Text mt={8}>Personal timesheet not available</Text>
        </Center>
      ) : null}
    </div>
  );
};

export default TimeSheetPage;
