import {
  Box,
  Button,
  Center,
  Flex,
  Select,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BounceLoader } from 'react-spinners';
import {
  branchReportsUrl,
  departmentReportsUrl,
  departmentsUrl,
  organisationReportsUrl,
} from '../../application/services/http_endpoints';
import useFetch from '../../application/services/http_service';
import AttendanceReport from './Dashboard/AttendanceReport';
import DepartmentReport from './Dashboard/DepartmentReport';
import Hours from './Dashboard/Hours';
import OrganisationReport from './Dashboard/OrganisationReport';
import RolesHours from './Dashboard/RolesHours';
import StackedHours from './Dashboard/StackedHours';

const CompanyDashboard = () => {
  const { organisation } = useSelector((state) => state.organisation);
  const { access_token } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.roles);

  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedBranch, setSelectedBranch] = useState();

  const [reportsFilter, setReportsFilter] = useState('organisation');

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
    'organisation-id': organisation?.id ?? '',
  };

  // fetch departments
  const {
    data: departments,
    loading: departmentsLoading,
    fetchDataByRole: fetchDepartments,
  } = useFetch({
    url: departmentsUrl,
    params: { org_id: organisation?.id },
    headers: headers,
    role: 'Departments.list',
    errMsg: 'Failed to fetch departments.',
  });

  // fetch branch report
  const {
    data: branchReports,
    loading: loadingBranchReports,
    fetchDataByRole: fetchBranchReport,
  } = useFetch({
    url: `${branchReportsUrl}${selectedBranch}/`,
    headers: headers,
    role: 'Reports.view_branch',
    errMsg: 'Failed to fetch branch reports.',
  });

  // fetch department report
  const {
    data: departmentReports,
    loading: loadingDepartmentReports,
    fetchDataByRole: fetchDepartmentReport,
  } = useFetch({
    url: `${departmentReportsUrl}${selectedDepartment}/`,
    params: { org_id: organisation?.id ?? '' },
    headers: headers,
    role: 'Reports.view_department',
    errMsg: 'Failed to fetch department reports.',
  });

  // fetch organistaion report
  const {
    data: organisationReports,
    loading: loadingOrganisationReports,
    fetchDataByRole: fetchOrganisationReport,
    setData,
  } = useFetch({
    url: `${organisationReportsUrl}${organisation?.id}/`,
    params: { org_id: organisation?.id ?? '' },
    headers: headers,
    role: 'Reports.view_organisation',
    errMsg: 'Failed to fetch organisation reports.',
  });

  useEffect(() => {
    // Trigger the fetch
    setData(undefined);
    if (selectedBranch) {
      fetchBranchReport();
    }
    if (selectedDepartment) {
      fetchDepartmentReport();
    }
    fetchDepartments();
    fetchOrganisationReport();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation?.id, roles, selectedBranch, selectedDepartment]);

  return loadingOrganisationReports ||
    loadingBranchReports ||
    loadingDepartmentReports ? (
    <Center mt={4}>
      <BounceLoader
        color={'#107e76'}
        loading={
          loadingOrganisationReports ||
          loadingBranchReports ||
          loadingDepartmentReports
        }
        size={80}
      />
    </Center>
  ) : organisationReports ? (
    <Box m={8}>
      <Flex align='center' mb={4}>
        {/* <Text ml={8} as='b' w={'80%'}>
          Wages
        </Text> */}
        <Spacer />
        {/* branch */}
        {organisation?.branches?.length > 0 ? (
          <Select
            focusBorderColor='#107e76'
            placeholder='Select branch'
            mr={4}
            w='20vw'
            value={selectedBranch}
            onChange={(e) => {
              setSelectedBranch(e.target.value);
              setReportsFilter('branch');
            }}
          >
            {organisation?.branches?.map((branch) => {
              return (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              );
            })}
          </Select>
        ) : null}

        {/* Department */}
        {departments?.items?.length > 0 ? (
          <Select
            mr={4}
            focusBorderColor='#107e76'
            placeholder='Select department'
            w='20vw'
            value={selectedDepartment}
            onChange={(e) => {
              setSelectedDepartment(e.target.value);
              setReportsFilter('department');
            }}
          >
            {departments?.items?.map((department) => {
              return (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              );
            })}
          </Select>
        ) : null}

        {selectedBranch || selectedDepartment ? (
          <Button
            variant='outline'
            textColor='red'
            onClick={() => {
              setSelectedBranch('');
              setSelectedDepartment('');
              setReportsFilter('organisation');
            }}
          >
            Clear
          </Button>
        ) : null}
      </Flex>

      {/* wages */}
      {/* <Wages /> */}

      {/* Branch/ Department */}
      {reportsFilter !== 'organisation' ? (
        <DepartmentReport
          branchReports={branchReports}
          departmentReports={departmentReports}
          filter={selectedDepartment ? 'department' : 'branch'}
        />
      ) : null}

      <Flex display={{ base: 'grid', md: 'flex' }} align='center'>
        {/* organisation */}
        {reportsFilter === 'organisation' ? (
          <OrganisationReport
            orgReport={organisationReports}
            departments={departments}
          />
        ) : null}

        {/* Attendance */}
        <AttendanceReport
          organisationReports={organisationReports}
          branchReports={branchReports}
          departmentReports={departmentReports}
          reportsFilter={reportsFilter}
        />
      </Flex>

      {/* Work Hours */}
      {reportsFilter === 'organisation' ? (
        <StackedHours
          organisationReports={organisationReports}
          branchReports={branchReports}
          departmentReports={departmentReports}
          reportsFilter={reportsFilter}
        />
      ) : (
        <Hours
          organisationReports={organisationReports}
          branchReports={branchReports}
          departmentReports={departmentReports}
          reportsFilter={reportsFilter}
        />
      )}

      {/* roles hours */}
      <RolesHours
        organisationReports={organisationReports}
        branchReports={branchReports}
        departmentReports={departmentReports}
        reportsFilter={reportsFilter}
      />

      <Box mt={8} />
    </Box>
  ) : (
    <Center mt={'30%'}>
      <Text>No reports to show</Text>
    </Center>
  );
};

export default CompanyDashboard;
