
export const roleColors = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const employees = [
  {
    employeeID: 1,
    userID: 1,
    roleID: 5,
    accessLevel: 'Admin',
  },
  {
    employeeID: 2,
    userID: 2,
    roleID: 1,
    accessLevel: 'Member',
  },
  {
    employeeID: 3,
    userID: 3,
    roleID: 2,
    accessLevel: 'Member',
  },
  {
    employeeID: 4,
    userID: 4,
    roleID: 3,
    accessLevel: 'Member',
  },
]

export const roles = [
  {
    id: 1,
    name: 'Doctor',
    roleColor: 1,
  },
  {
    id: 2,
    name: 'Nurse',
    roleColor: 8,
  },
  {
    id: 3,
    name: 'Pharmacist',
    roleColor: 2,
  },
  {
    id: 4,
    name: 'Security',
    roleColor: 9,
  },
  {
    id: 5,
    name: 'Genitor',
    roleColor: 5,
  },
]

export const shifts = [
  {
    id: 3,
    name: 'Theatre rota',
    members: employees,
    durationHours: 8.0,
    shiftType: 'FullDay',
    startTime: '2022-07-19 11:32:08',
    endTime: '2022-07-20 19:32:08',
    repeat: 'DAILY',
  },
  {
    id: 4,
    name: 'Theatre rota',
    members: employees,
    durationHours: 4.0,
    shiftType: 'HalfDay',
    startTime: '2010-03-29 11:32:08',
    endTime: '2010-03-29 15:32:08',
    repeat: 'WEEKLY',
  },
  {
    id: 2,
    name: 'Ward Rota',
    members: employees,
    durationHours: 4.0,
    shiftType: 'HalfDay',
    startTime: '2010-03-29 11:32:08',
    endTime: '2010-03-29 15:32:08',
    repeat: 'MONTHLY',
  },
  {
    id: 3,
    name: 'Theatre rota',
    members: employees,
    durationHours: 8.0,
    shiftType: 'FullDay',
    startTime: '2010-03-29 11:32:08',
    endTime: '2010-03-29 19:32:08',
    repeat: 'NONE',
  },
]

export const branches = [
  {
    id: 1,
    name: 'Hurlingham branch',
    location: 'Argwings Kodheck road',
    lat: '-1.235454566',
    long: '36.78654389',
    roles: roles,
    employees: employees,
    shifts: shifts,
  },
  {
    id: 2,
    name: 'Adams branch',
    location: 'Kilimani road, Dagoreti North',
    lat: '-1.2990584',
    long: '36.78190',
    roles: roles,
    employees: employees,
    shifts: shifts,
  }
]

export const Users = [
  {
    id: 1,
    fistName: "Kocaoglu",
    lastName: "Safak",
    primaryEmail: "Safak@example.com",
    phoneNumber: '+254712345678',
    organizations: [
      {
        id: 1,
        branches: [1, 2]
      },
    ],
  },
  {
    id: 2,
    fistName: "Janell",
    lastName: "Shrum",
    primaryEmail: "Janell@example.com",
    phoneNumber: '+254712345678',
    organizations: [
      {
        id: 1,
        branches: [1, 2]
      },
    ],
  },
  {
    id: 3,
    fistName: "Alex",
    lastName: "Durden",
    primaryEmail: "Alex@example.com",
    phoneNumber: '+254712345678',
    organizations: [
      {
        id: 1,
        branches: [1, 2]
      },
    ],
  },
  {
    id: 4,
    fistName: "Dora",
    lastName: "Hawks",
    primaryEmail: "Dora@example.com",
    phoneNumber: '+254712345678',
    organizations: [
      {
        id: 1,
        branches: [1, 2]
      },
    ],
  },
  {
    id: 5,
    fistName: "Thomas",
    lastName: "Holden",
    primaryEmail: "Thomas@example.com",
    phoneNumber: '+254712345678',
    organizations: [
      {
        id: 1,
        branches: [1, 2]
      },
    ],
  },
  {
    id: 6,
    fistName: "Shirley",
    lastName: "Beauchamp",
    primaryEmail: "Beauchamp@example.com",
    phoneNumber: '+254712345678',
    organizations: [
      {
        id: 1,
        branches: [1, 2]
      },
    ],
  },
  {
    id: 7,
    fistName: "Travis",
    lastName: "Bennett",
    primaryEmail: "Bennett@example.com",
    phoneNumber: '+254712345678',
    organizations: [
      {
        id: 1,
        branches: [1, 2]
      },
    ],
  },
];

export const orgs = [
  {
    id: 1,
    name: "NAirobi Women Hospital",
    branches: branches,
  },
]



export const shiftDays = [
  {
    date: '2022-07-18 00:00:00',
    shifts: shifts,
  },
  {
    date: '2022-07-19 00:00:00',
    shifts: shifts,
  },
  {
    date: '2022-07-20 00:00:00',
    shifts: shifts,
  },
  {
    date: '2022-07-21 00:00:00',
    shifts: shifts,
  },
  {
    date: '2022-07-22 00:00:00',
    shifts: shifts,
  },
  {
    date: '2022-07-23 00:00:00',
    shifts: shifts,
  },
  {
    date: '2022-07-24 00:00:00',
    shifts: shifts,
  },
  // {
  //   date: '2022-07-25 00:00:00',
  //   shifts: shifts,
  // },
  // {
  //   date: '2022-07-26 00:00:00',
  //   shifts: shifts,
  // },
]


export const UsersMembers = [
  {
    "id": 1,
    "first_name": "Emiline",
    "last_name": "McClune",
    "email": "emcclune0@xrea.com",
    "employee_role": "Marketing manager",
    "user_role": "Admin",
    "phone": "0722345678",
    "status": "Active",
  },
  {
    "id": 2,
    "first_name": "Felix",
    "last_name": "Ingleston",
    "email": "fingleston1@hibu.com",
    "employee_role": "Genitor",
    "user_role": "Member",
    "phone": "0732345678",
    "status": "Active",
  },
  {
    "id": 3,
    "first_name": "Travus",
    "last_name": "Bergstram",
    "email": "tbergstram2@pbs.org",
    "employee_role": "Marketing manager",
    "user_role": "Admin",
    "phone": "0712345678",
    "status": "Active",
  },
  {
    "id": 4,
    "first_name": "Holly-anne",
    "last_name": "Knighton",
    "email": "hknighton3@booking.com",
    "employee_role": "Genitor",
    "user_role": "Member",
    "phone": "0742345678",
    "status": "Active",
  },
  {
    "id": 5,
    "first_name": "Sollie",
    "last_name": "Naulty",
    "email": "snaulty4@hud.gov",
    "employee_role": "Content manager",
    "user_role": "Admin",
    "phone": "0752345678",
    "status": "Active",
  },
  {
    "id": 6,
    "first_name": "Annie",
    "last_name": "Cockayme",
    "email": "acockayme5@tuttocitta.it",
    "employee_role": "Engineering manager",
    "user_role": "Admin",
    "phone": "0762345678",
    "status": "Active",
  },
  {
    "id": 7,
    "first_name": "Emiline",
    "last_name": "McClune",
    "email": "emcclune0@xrea.com",
    "employee_role": "Marketing manager",
    "user_role": "Admin",
    "phone": "0722345678",
    "status": "Active",
  },
  {
    "id": 8,
    "first_name": "Felix",
    "last_name": "Ingleston",
    "email": "fingleston1@hibu.com",
    "employee_role": "Genitor",
    "user_role": "Member",
    "phone": "0732345678",
    "status": "Active",
  },
  {
    "id": 9,
    "first_name": "Travus",
    "last_name": "Bergstram",
    "email": "tbergstram2@pbs.org",
    "employee_role": "Marketing manager",
    "user_role": "Admin",
    "phone": "0712345678",
    "status": "Active",
  },
  {
    "id": 10,
    "first_name": "Holly-anne",
    "last_name": "Knighton",
    "email": "hknighton3@booking.com",
    "employee_role": "Genitor",
    "user_role": "Member",
    "phone": "0742345678",
    "status": "Active",
  },
  {
    "id": 11,
    "first_name": "Sollie",
    "last_name": "Naulty",
    "email": "snaulty4@hud.gov",
    "employee_role": "Content manager",
    "user_role": "Admin",
    "phone": "0752345678",
    "status": "Active",
  },
  {
    "id": 12,
    "first_name": "Annie",
    "last_name": "Cockayme",
    "email": "acockayme5@tuttocitta.it",
    "employee_role": "Engineering manager",
    "user_role": "Admin",
    "phone": "0762345678",
    "status": "Active",
  },
  // {
  //   "id": 7,
  //   "first_name": "Celinda",
  //   "last_name": "Sharvill",
  //   "email": "csharvill6@narod.ru",
  //   "employee_role": "Finance officer",
  //   "user_role": "Admin",
  //   "phone": "0762345678",
  // }
]

export const Requests = [
  {
    "id": 3,
    "first_name": "Travus",
    "last_name": "Bergstram",
    "email": "tbergstram2@pbs.org",
    "employee_role": "Marketing manager",
    "status": "Active",
    "phone": "0712345678",
  },
  {
    "id": 4,
    "first_name": "Holly-anne",
    "last_name": "Knighton",
    "email": "hknighton3@booking.com",
    "employee_role": "Genitor",
    "status": "Active",
    "phone": "0742345678",
  },
  {
    "id": 5,
    "first_name": "Sollie",
    "last_name": "Naulty",
    "email": "snaulty4@hud.gov",
    "employee_role": "Content manager",
    "status": "Invitation sent",
    "phone": "0752345678",
  },
  {
    "id": 6,
    "first_name": "Annie",
    "last_name": "Cockayme",
    "email": "acockayme5@tuttocitta.it",
    "employee_role": "Engineering manager",
    "status": "Invitation sent",
    "phone": "0762345678",
  },
  {
    "id": 7,
    "first_name": "Celinda",
    "last_name": "Sharvill",
    "email": "csharvill6@narod.ru",
    "employee_role": "Finance officer",
    "status": "Active",
    "phone": "0762345678",
  }
]