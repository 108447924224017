import { Button, Center, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  confirmPasswordString,
  createPasswordDescString,
  createPasswordString,
  passwordPlaceHolderString,
  resetPasswordString,
} from '../../application/services/app_strings';
import { resetPasswordUrl } from '../../application/services/http_endpoints';
import InputField from '../core/components/input_field/InputField';
import './ForgotPasswordPageOne.scss';
import { ratibaLogoGreenIconSvgPath } from '../../application/services/asset_strings';
import { stringValPatternValidation } from '../../application/services/utils';
const ForgotPasswordPageTwo = () => {
  const navigate = useNavigate();

  const toast = useToast();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const { email } = useSelector((state) => state.userForgotPassword);

  const resetPassword = async (email, password) => {
    setLoading(true);
    axios
      .post(resetPasswordUrl, {
        email: email,
        password: password,
      })
      .then((response) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'Password was reset successfully.',
          description: response.data.msg,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      })
      .catch((err) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'Failed to reset password.',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      stringValPatternValidation(password) ||
      stringValPatternValidation(confirmPassword)
    ) {
      setError('Password should not contain a white space.');
    } else if (
      password.trim().length < 6 ||
      confirmPassword.trim().length < 6
    ) {
      setError('Password should be at least 6 characters long.');
    } else if (password !== confirmPassword) {
      setLoading(false);
      toast({
        position: 'bottom-left',
        title: 'Passwords do not match.',
        description: 'Ensure that your passwords match.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setError('');
      resetPassword(email, password);
    }
  };

  return (
    <div className='forgot-password-container'>
      <img src={ratibaLogoGreenIconSvgPath} />
      <div className='forgot-password-header'>
        {/* Title */}
        <h2 className='title'>{resetPasswordString}</h2>

        {/* desc */}
        <p className='desc'>{createPasswordDescString}</p>
      </div>
      <form className='forgot-password-form' onSubmit={handleSubmit}>
        {/* Create Password */}
        <InputField
          title={createPasswordString}
          isRequired={true}
          placeholder={passwordPlaceHolderString}
          type='password'
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Confirm Password */}
        <InputField
          title={confirmPasswordString}
          isRequired={true}
          placeholder={passwordPlaceHolderString}
          type='password'
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {/* Continue */}
        <Button
          background='#107e76'
          textColor='white'
          type='submit'
          isLoading={isLoading}
        >
          {resetPasswordString}
        </Button>

        <Center>
          {/* Change */}
          <p className='link'>
            <NavLink exact to='/' key='login' className='link-btn'>
              <span>Back to login</span>
            </NavLink>
          </p>
        </Center>
      </form>
    </div>
  );
};

export default ForgotPasswordPageTwo;
