import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organisation: {
    branches: [],
  },
  branch: '',
};

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setOrganisation: (state, action) => {
      state.organisation = action.payload;
    },
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    resetOrg: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setOrganisation, setBranch, resetOrg } =
  organisationSlice.actions;

export default organisationSlice.reducer;
