import { useTour } from '@reactour/tour';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePath } from '../../redux/navigation/navigation';
import CompanyDashboard from './CompanyDashboard';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActivePath(''));
  const { setIsOpen } = useTour();
  const tour = JSON.parse(localStorage.getItem('tour'));
  // const { tour } = useSelector((state) => state.tour);
  if (tour === 'organisation') {
    setIsOpen(true);
    localStorage.setItem('tour', JSON.stringify(''));
  }
  const { inviteObj, isInvited } = useSelector((state) => state.invite);
  const { user } = useSelector((state) => state.user);

  const email =
    user?.user_contacts?.[0]?.contact?.contact_type === 'EMAIL'
      ? user?.user_contacts?.[0]?.contact?.contact_value
      : user?.user_contacts?.[1]?.contact?.contact_value;

  if (isInvited && inviteObj?.email === email) {
    navigate('/accept-invitation');
  }
  return <CompanyDashboard />;
};

export default HomePage;
