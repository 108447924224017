import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';

import {
  GoogleMap,
  MarkerF,
  StandaloneSearchBox,
  useLoadScript,
} from '@react-google-maps/api';
import axios from 'axios';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import {
  getBranchesUrl,
  registerOrganisationUrl,
} from '../../application/services/http_endpoints';
import {
  resetOrg,
  setBranch,
  setOrganisation,
} from '../../redux/organisation/organisation';
import {
  addLatitude,
  addLongitude,
  addViewLat,
  addViewLng,
  resetOrgReg,
  setIsOrgRegOpen,
  setIsViewMap,
} from '../../redux/organisation/organisationRegistration';
import { resetRoles } from '../../redux/roles/roles';
import { resetUser, setLoggedIn } from '../../redux/user/user';
import { handleError } from '../../application/services/http_service';

function MapContainer() {
  const {
    name,
    phone_number,
    email,
    cert_of_reg,
    registration_number,
    kra_number,
    industry_id,
    longitude,
    latitude,
    viewLat,
    viewLng,
    isViewMap,
    locId,
  } = useSelector((state) => state.organisationRegistration);

  console.log(name);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const { access_token } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const [isLoading, setLoading] = useState(false);

  const center =
    isViewMap === 'branch' || isViewMap === 'organisation'
      ? { lat: viewLat, lng: viewLng }
      : { lat: latitude, lng: longitude };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const [map, setMap] = useState(null);

  const inputRef = useRef();

  function clearRoute() {
    inputRef.current.value = '';
  }

  // location changed
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      if (isViewMap === 'branch' || isViewMap === 'organisation') {
        dispatch(addViewLat(place.geometry.location.lat()));
        dispatch(addViewLng(place.geometry.location.lng()));
      } else {
        dispatch(addLatitude(place.geometry.location.lat()));
        dispatch(addLongitude(place.geometry.location.lng()));
      }
    }
  };

  // register organisation
  const registerOrganisation = async () => {
    // if (longitude === undefined || latitude === undefined) {
    //   toast({
    //     position: 'bottom-left',
    //     title: 'Organisation location is required',
    //     status: 'error',
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // } else {
    setLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append('name', name);
    bodyFormData.append('phone_number', phone_number);
    bodyFormData.append('email', email);
    bodyFormData.append('industry_id', industry_id);
    bodyFormData.append('cert_of_reg', cert_of_reg);
    bodyFormData.append('registration_number', registration_number);
    bodyFormData.append('kra_number', kra_number);
    bodyFormData.append('longitude', longitude ?? 0);
    bodyFormData.append('latitude', latitude ?? 0);
    bodyFormData.append('tier', 'RTB-Free');

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .post(registerOrganisationUrl, bodyFormData, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);

        // start org tour
        localStorage.setItem('tour', JSON.stringify('organisation'));

        dispatch(setOrganisation(response.data));
        dispatch(setBranch(response.data?.branches[0]?.id));
        toast({
          position: 'bottom-left',
          title: 'Organisation registered successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // log out user to access new features
        dispatch(resetOrgReg());
        dispatch(setLoggedIn(false));
        dispatch(resetUser());
        dispatch(resetOrg());
        dispatch(resetRoles());
        dispatch(setIsOrgRegOpen(false));
        dispatch(setIsViewMap(''));

        navigate('/successPage');
      })
      .catch((err) => {
        setLoading(false);

        handleError(
          err,
          'Organisation registration failed.',
          registerOrganisation,
          access_token,
          dispatch,
          toast,
          navigate
        );

        // log out user
        dispatch(resetOrgReg());
        dispatch(setLoggedIn(false));
        dispatch(resetUser());
        dispatch(resetOrg());
        dispatch(resetRoles());
        dispatch(setIsOrgRegOpen(false));
        dispatch(setIsViewMap(''));
        navigate('/');
      });
    // }
  };

  if (!isLoaded) {
    return (
      <div className='loader'>
        <BounceLoader color={'#107e76'} loading={isLoaded} size={80} />
      </div>
    );
  }

  // update location
  const updateLocation = async () => {
    if (isViewMap === 'branch') {
      setLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .patch(
          `${getBranchesUrl}${locId}/`,
          {
            location: {
              longitude: viewLng,
              latitude: viewLat,
            },
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setLoading(false);
          toast({
            position: 'bottom-left',
            title: 'Branch location updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate(-1);
        })
        .catch((err) => {
          setLoading(false);
          handleError(
            err,
            'Failed to update branch location.',
            updateLocation,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  };

  return (
    <Flex
      position='relative'
      flexDirection='column'
      alignItems='center'
      h='100vh'
      // w='100vw'
    >
      <Box position='absolute' left={0} top={0} h='100%' w='100%'>
        {/* Google Map Box */}
        {(longitude !== undefined && latitude !== undefined) ||
        isViewMap === 'branch' ||
        isViewMap === 'organisation' ? (
          <GoogleMap
            center={center}
            zoom={19}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            <MarkerF position={center} />
          </GoogleMap>
        ) : (
          <Center mt='40%'>
            <Text>Select organisation location</Text>
          </Center>
        )}
      </Box>

      <Box
        p={8}
        borderRadius='lg'
        m={4}
        bgColor='white'
        shadow='base'
        minW={200}
        zIndex='1'
        align='center'
      >
        <HStack spacing={2} justifyContent='space-between'>
          <IconButton
            aria-label='center back'
            icon={<FaArrowLeft />}
            onClick={() => navigate(-1)}
          />
          <Box flexGrow={1}>
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              <Input
                type='text'
                placeholder='Search location'
                focusBorderColor='#107e76'
              />
            </StandaloneSearchBox>
          </Box>

          <Button
            mt={4}
            background='#107e76'
            textColor='white'
            // type='submit'
            isLoading={isLoading}
            // isDisabled={
            //   (longitude === undefined || latitude === undefined) &&
            //   isViewMap === ''
            // }
            onClick={() =>
              isViewMap === 'branch' || isViewMap === 'organisation'
                ? updateLocation()
                : registerOrganisation()
            }
          >
            {isViewMap === 'branch' || isViewMap === 'organisation'
              ? 'Update'
              : 'Register'}
          </Button>
        </HStack>
      </Box>
    </Flex>
  );
}

export default MapContainer;
