import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inviteObj: {},
  isInvited: false,
  // userExists: false,
  // userEmail: '',
  // token: '',
  // orgId: '',
  // orgName: '',
};

export const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    setInviteObj: (state, action) => {
      state.inviteObj = action.payload;
    },
    setInvited: (state, action) => {
      state.isInvited = action.payload;
    },
    resetInvite: () => initialState,
    // setUserExists: (state, action) => {
    //   state.userExists = action.payload;
    // },
    // setUserEmail: (state, action) => {
    //   state.userEmail = action.payload;
    // },
    // setToken: (state, action) => {
    //   state.token = action.payload;
    // },
    // setOrgId: (state, action) => {
    //   state.orgId = action.payload;
    // },
    // setOrgName: (state, action) => {
    //   state.orgName = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { setInviteObj, setInvited, resetInvite } = inviteSlice.actions;

export default inviteSlice.reducer;
