import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { shiftTypes } from '../../../../application/services/app_strings';
import { shiftHoursUrl } from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';

const ShiftHourModal = ({
  fetchWorkingHours,
  workHourItem,
  setWorkHourItem,
  isOpen,
  onClose,
  active,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);

  const [isLoading, setLoading] = useState(false);

  const [shiftType, setShiftType] = useState('');
  const [workingHour, setWorkingHour] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const createWorkingHour = async () => {
    if (!branch || branch === '') {
      toast({
        position: 'bottom-left',
        title: 'Branch missing',
        description: 'Create a branch to proceed',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setLoading(true);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .post(
          shiftHoursUrl,
          {
            name: workingHour,
            shift_type: shiftType,
            branch_id: branch,
            start_time: `${startTime}:00`,
            end_time: `${endTime}:00`,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setLoading(false);
          onClose();
          fetchWorkingHours();
          toast({
            position: 'bottom-left',
            title: 'Working hour created successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((err) => {
          setLoading(false);
          handleError(
            err,
            'Failed to create working hour.',
            createWorkingHour,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  };

  const updateWorkingHour = async () => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(
        `${shiftHoursUrl}${workHourItem.id}/`,
        {
          name: `${workingHour.length > 0 ? workingHour : workHourItem.name}`,
          shift_type: `${
            shiftType.length > 0 ? shiftType : workHourItem.shift_type
          }`,
          branch_id: branch,
          start_time: `${
            startTime.length > 0 ? `${startTime}:00` : workHourItem.start_time
          }`,
          end_time: `${
            endTime.length > 0 ? `${endTime}:00` : workHourItem.end_time
          }`,
          is_active:
            active !== workHourItem.is_active ? active : workHourItem.is_active,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);

        onClose();
        fetchWorkingHours();
        setWorkHourItem({});
        toast({
          position: 'bottom-left',
          title: 'Working hour updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update working hour.',
          updateWorkingHour,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (workHourItem?.name) {
      updateWorkingHour();
    } else {
      createWorkingHour();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setWorkHourItem({});
      }}
      size={'lg'}
    >
      <form onSubmit={handleSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Container mt={8}>
              {/* via email */}
              <Text as='b' fontSize='lg'>
                {workHourItem?.name ? (
                  <>Update shift hours</>
                ) : (
                  <>Create shift working hours</>
                )}
              </Text>
              <Text fontSize='sm' mb={8} mt={2}>
                {!workHourItem?.name && (
                  <>
                    Create your shift hours to assign and track your employee
                    working hours
                  </>
                )}
              </Text>

              {/* working hours title */}
              <Flex>
                <Text fontSize={'sm'}>Working hours title</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <InputGroup size='md' mb={8} mt={2}>
                <Input
                  isRequired={true}
                  type={'text'}
                  defaultValue={workHourItem?.name}
                  pr='4.5rem'
                  placeholder='Enter title'
                  focusBorderColor='#107e76'
                  onChange={(e) => setWorkingHour(e.target.value)}
                />
              </InputGroup>
              {/*  shift type */}
              <Flex>
                <Text fontSize={'sm'}>Type of shift</Text>
                <Text fontSize={'sm'} color='red'>
                  *
                </Text>
              </Flex>
              <Select
                isRequired={true}
                placeholder='Select shift type'
                defaultValue={workHourItem.shift_type}
                onChange={(e) => setShiftType(e.target.value)}
                mb={8}
                mt={2}
              >
                {shiftTypes?.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
              </Select>
              <Flex mb={8}>
                <Box w={200}>
                  <Flex>
                    <Text fontSize={'sm'}>Start Time</Text>
                    <Text fontSize={'sm'} color='red'>
                      *
                    </Text>
                  </Flex>
                  <Input
                    isRequired={true}
                    placeholder='Pick start time'
                    onChange={(e) => setStartTime(e.target.value)}
                    defaultValue={workHourItem?.start_time}
                    size='md'
                    type='time'
                    mt={2}
                    focusBorderColor='#107e76'
                  />
                </Box>
                <Spacer />
                <Box w={200}>
                  <Flex>
                    <Text fontSize={'sm'}>End Time</Text>
                    <Text fontSize={'sm'} color='red'>
                      *
                    </Text>
                  </Flex>
                  <Input
                    isRequired={true}
                    placeholder='Pick end time'
                    onChange={(e) => setEndTime(e.target.value)}
                    size='md'
                    type='time'
                    defaultValue={workHourItem?.end_time}
                    mt={2}
                    focusBorderColor='#107e76'
                  />
                </Box>
              </Flex>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                onClose();
                setWorkHourItem({});
              }}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              backgroundColor={'#107e76'}
              textColor='white'
              isLoading={isLoading}
              type='submit'
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ShiftHourModal;
