import './ContactItem.scss';

const ContactItem = ({ iconPath, title, description }) => {
  return (
    <div className='contact-item'>
      <img src={iconPath} />
      <div className='details'>
        <p className='title'>{title}</p>
        <p className='desc'>{description}</p>
      </div>
    </div>
  );
};

export default ContactItem;
