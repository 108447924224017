import {
  Button,
  Center,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './SchedulesTable.scss';

const SchedulesTable = ({
  rotas,
  openModal,
  onOpenMembersModal,
  setSelectedRota,
  setMembersInfo,
  onOpenDeactivateModal,
}) => {
  const { roles } = useSelector((state) => state.roles);
  const { organisation } = useSelector((state) => state.organisation);

  return (
    <TableContainer backgroundColor={'white'} m={12}>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Date</Th>
            {/* <Th>Shift type</Th> */}
            <Th>Status</Th>
            {/* <Th>Date created</Th> */}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rotas.length > 0 ? (
            rotas.map((item) => (
              <Tr key={item.id}>
                <Td className='scheduleName'>
                  <Text textColor='black'>{item.title}</Text>
                </Td>
                <Td>
                  {/* <Text fontSize='sm'>5:20 PM - 4:30 AM</Text> */}
                  <Text fontSize='sm'>
                    {`${moment(item.start_date).format(
                      'MMM DD, YYYY'
                    )} - ${moment(item.end_date).format('MMM DD, YYYY')}`}
                  </Text>
                </Td>
                {/* shift type */}
                {/* <Td>
                  <p
                    className={`status-badge ${item.shift_type?.replace(
                      /\s/g,
                      ''
                    )}`}
                  >
                    Night time, Full Day
                  </p>
                </Td> */}
                {/* status */}
                <Td>
                  <Center>
                    {item.is_active ? (
                      <Tag
                        borderRadius='full'
                        backgroundColor='#53A24C'
                        textColor='white'
                      >
                        Active
                      </Tag>
                    ) : (
                      <Tag
                        borderRadius='full'
                        backgroundColor='#C80024'
                        textColor='white'
                      >
                        Deactivated
                      </Tag>
                    )}
                  </Center>
                </Td>
                {/* date created */}
                {/* <Td>
                  <Text textColor='black' fontSize='sm'>
                    {moment(item.created_at).format('MMM DD, YYYY')}
                  </Text>
                 <Text fontSize='sm'>
                          {moment
                            .utc(item.created_at)
                            .local()
                            .format('HH:mm a')}
                        </Text>
                </Td> */}
                {/* actions */}
                <Td>
                  <Button
                    backgroundColor='#DAF2F0'
                    textColor='#107e76'
                    size='sm'
                    isDisabled={
                      !roles?.[organisation?.id]?.includes('Rota.update')
                    }
                    onClick={() => {
                      setSelectedRota(item);
                      openModal(true);
                    }}
                    mr={4}
                  >
                    Edit Rota
                  </Button>

                  <Button
                    backgroundColor='#DAF2F0'
                    textColor='#107e76'
                    size='sm'
                    isDisabled={
                      // organisation?.organisation_type === 'INDIVIDUAL' ||
                      !roles?.[organisation?.id]?.includes('Rota.update')
                    }
                    onClick={() => {
                      let fMembersInfo = [];

                      item.members.forEach((member) => {
                        fMembersInfo.push(member);
                      });

                      setMembersInfo(fMembersInfo);

                      setSelectedRota(item);
                      onOpenMembersModal();
                    }}
                    mr={4}
                  >
                    Edit Members
                  </Button>

                  <Button
                    backgroundColor={item?.is_active ? '#F8E0E5' : '#DAF2F0'}
                    textColor={item?.is_active ? '#C80024' : '#107e76'}
                    size='sm'
                    isDisabled={
                      !roles?.[organisation?.id]?.includes('Rota.update')
                    }
                    onClick={() => {
                      setSelectedRota(item);
                      onOpenDeactivateModal();
                    }}
                  >
                    {item?.is_active ? 'Deactivate' : 'Activate'}
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className='no-record'>
              <Td colSpan='5'>
                <Text fontSize='sm'>No records found</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SchedulesTable;
