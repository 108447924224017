import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Center,
  Container,
  Flex,
  Input,
  InputGroup,
  SimpleGrid,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { MdCheckCircle, MdOutlineCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  permissionsUrl,
  rolesUrl,
} from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';

const CreateRolePage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { branch } = useSelector((state) => state.organisation);
  const [roleName, setRoleName] = useState(location?.state?.name ?? '');
  const [isLoading, setLoading] = useState(false);

  const { access_token } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const { decodedRoles } = useSelector((state) => state.roles);

  const [permissions, setPermissions] = useState([]);
  const [isPermissionsLoading, setPermissionsLoading] = useState(false);

  const [selectedPermissions, setSelectedPermissions] = useState(
    location?.state?.permissions ?? ['Roles.view']
  );

  async function fetchPermissions() {
    setPermissionsLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(permissionsUrl, {
        params: { branch_id: branch ?? '' },
        headers: headers,
      })
      .then((response) => {
        setPermissionsLoading(false);
        setPermissions(response.data.permissions);
      })
      .catch((err) => {
        setPermissionsLoading(false);
        handleError(
          err,
          'Failed to fetch permissions.',
          fetchPermissions,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  useEffect(() => {
    // Trigger the fetch
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create role
  const createRole = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .post(
        rolesUrl,
        {
          name: roleName,
          permissions: selectedPermissions,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);
        setSelectedPermissions([]);
        setRoleName('');
        navigate('/settings');
        toast({
          position: 'bottom-left',
          title: 'Role created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to create role.',
          createRole,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  // update role
  const updateRole = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(
        `${rolesUrl}${location?.state?.id}/`,
        {
          name: roleName,
          permissions: selectedPermissions,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);
        setSelectedPermissions([]);
        setRoleName('');
        navigate('/settings');
        toast({
          position: 'bottom-left',
          title: 'Role updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update role.',
          updateRole,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  return (
    <div>
      <Breadcrumb ml={16} mt={12} fontWeight='medium' fontSize='sm'>
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => navigate(-1)}>Settings</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>Create Role</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Card m={12}>
        <CardBody backgroundColor='white' p={8}>
          <Box>
            <Flex>
              <Box>
                <Text as='b' fontSize='lg'>
                  {location?.state?.id ? 'Update Role' : 'Create role'}
                </Text>
                <Text fontSize='sm' mb={8} mt={2}>
                  {location?.state?.id
                    ? 'You can update this role and assign it new permissions'
                    : ' You can create a role and assign it permissions'}
                </Text>
              </Box>
              <Spacer />
              <Button
                variant='solid'
                backgroundColor={'#107e76'}
                textColor='white'
                isLoading={isLoading}
                isDisabled={
                  selectedPermissions?.length === 0 || roleName === ''
                }
                onClick={() => {
                  if (location?.state?.id) updateRole();
                  else createRole();
                }}
              >
                {location?.state?.id ? 'Update Role' : 'Create role'}
              </Button>
            </Flex>
            {/* role name */}
            <Flex>
              <Text fontSize={'sm'}>Role name</Text>
              <Text fontSize={'sm'} color='red'>
                *
              </Text>
            </Flex>
            <InputGroup size='md' mb={10} mt={2}>
              <Input
                style={{ 'text-transform': 'capitalize' }}
                type={'text'}
                pr='4.5rem'
                focusBorderColor='#107e76'
                placeholder='Enter role name'
                defaultValue={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </InputGroup>

            {/* permissions */}
            <Flex>
              <Box>
                <Text fontSize='lg' as='b' mt={2}>
                  Permissions for this role
                </Text>
                <Text fontSize='sm' mt={2}>
                  These permissions will allow give members roles different
                  access to Ratiba
                </Text>
              </Box>
            </Flex>

            {/* grid view */}

            <Center>
              <SimpleGrid
                columns={4}
                spacing={10}
                mt={8}
                display={{ base: 'wrap', md: 'inline-grid' }}
              >
                {/* permission group */}

                {permissions?.map((item) => (
                  <Box align='center' key={item.id}>
                    <Text as='b' fontSize='sm'>
                      {item.entity}
                    </Text>
                    {/* permission title */}
                    {item?.actions?.map((action, index) => (
                      <Container
                        key={action?.id}
                        border='2px'
                        borderRadius={10}
                        borderColor={
                          selectedPermissions?.includes(
                            `${item.entity}.${action}`
                          )
                            ? '#107e76'
                            : '#6666668A'
                        }
                        bg={
                          selectedPermissions?.includes(
                            `${item.entity}.${action}`
                          )
                            ? '#EFF9F8'
                            : 'white'
                        }
                        mt={2}
                        w={180}
                        onClick={() => {
                          if (
                            selectedPermissions?.includes(
                              `${item.entity}.${action}`
                            )
                          ) {
                            // remove permission
                            if (item.entity === 'Roles' && action === 'view') {
                              // prevent unselection of required perms
                              toast({
                                position: 'bottom-left',
                                title: 'This permission is required.',
                                status: 'warning',
                                duration: 5000,
                                isClosable: true,
                              });
                            } else if (
                              decodedRoles[organisation?.id] ===
                                location?.state?.id &&
                              item.entity === 'Roles' &&
                              (action === 'list' ||
                                action === 'view' ||
                                action === 'create' ||
                                action === 'update')
                            ) {
                              // prevent unselection of required perms
                              toast({
                                position: 'bottom-left',
                                title:
                                  'This permission is required for an admin.',
                                status: 'warning',
                                duration: 5000,
                                isClosable: true,
                              });
                            } else {
                              // remove selected perm
                              setSelectedPermissions(
                                selectedPermissions.filter(
                                  (a) => a !== `${item.entity}.${action}`
                                )
                              );
                            }
                          } else {
                            // add permission

                            if (action === 'create' || action === 'update') {
                              // add other perms
                              let currIndex = index;
                              let permsList = selectedPermissions.slice();
                              for (; currIndex >= 0; currIndex--) {
                                permsList.push(
                                  `${item.entity}.${item?.actions?.[currIndex]}`
                                );
                              }
                              setSelectedPermissions(permsList);
                            } else {
                              // add only selected perm
                              setSelectedPermissions([
                                ...selectedPermissions,
                                `${item.entity}.${action}`,
                              ]);
                            }
                          }
                        }}
                      >
                        <Box
                          bg={
                            selectedPermissions?.includes(
                              `${item.entity}.${action}`
                            )
                              ? '#EFF9F8'
                              : 'white'
                          }
                          borderRadius={6}
                        >
                          <Flex p={2} align='center'>
                            <Text
                              as={
                                selectedPermissions?.includes(
                                  `${item.entity}.${action}`
                                )
                                  ? 'b'
                                  : null
                              }
                              textColor={
                                selectedPermissions?.includes(
                                  `${item.entity}.${action}`
                                )
                                  ? '#107e76'
                                  : null
                              }
                              fontSize='sm'
                            >
                              {action}
                            </Text>
                            <Spacer />
                            {selectedPermissions?.includes(
                              `${item.entity}.${action}`
                            ) ? (
                              <MdCheckCircle size={18} color='#107e76' />
                            ) : (
                              <MdOutlineCircle size={18} color='#ACACAC' />
                            )}
                            {/* <Checkbox background='#107e76' textColor='white'  /> */}
                          </Flex>
                        </Box>
                      </Container>
                    ))}
                  </Box>
                ))}
              </SimpleGrid>
            </Center>
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateRolePage;
