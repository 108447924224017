import { Button, Flex, Input, Select, Spacer, Text } from '@chakra-ui/react';
import startsWith from 'lodash.startswith';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIndustriesUrl } from '../../application/services/http_endpoints';
import useFetch from '../../application/services/http_service';
import { validateEmail } from '../../application/services/utils';
import {
  addEmail,
  addIndustryId,
  addName,
  addPhone,
  resetOrgReg,
  setIsOrgRegOpen,
} from '../../redux/organisation/organisationRegistration';
import './org_registration.scss';

const OrganizationRegistrationPageOne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access_token } = useSelector((state) => state.user);
  // const { organisation } = useSelector((state) => state.organisation);

  const { name, phone_number, email, industry_id } = useSelector(
    (state) => state.organisationRegistration
  );

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
    // 'organisation-id': organisation?.id ?? '',
  };

  // fetch industries
  const { data: industries, fetchData } = useFetch({
    url: getIndustriesUrl,
    headers: headers,
    errMsg: 'Failed to fetch industries.',
  });

  useEffect(() => {
    // Trigger the fetch
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name.trim().length < 3) {
      setError('Business name should be at least 3 characters long.');
    } else if (phone_number.length < 8) {
      setError('Enter a valid phone number');
    } else if (!validateEmail(email)) {
      setError('Enter a valid email.');
    } else {
      setError('');
      navigate('/org-reg-two');
    }
  };

  return (
    <div className='org-container'>
      <div className='org-header'>
        <h2 className='title'>About your organization</h2>
        <p className='desc'>Complete the details to setup your organization</p>
      </div>
      {/* progress */}
      <div className='org-prog-indicator'>
        <div className='first active'></div>
        <div className='second'></div>
      </div>
      <form onSubmit={handleSubmit}>
        {/* industries */}
        <Flex>
          <Text fontSize={'sm'}>Industry</Text>
          <Text fontSize={'sm'} textColor={'red'}>
            *
          </Text>
        </Flex>
        <Select
          focusBorderColor='#107e76'
          placeholder={'Select industry'}
          value={industry_id}
          isRequired={true}
          onChange={(e) => dispatch(addIndustryId(e.target.value))}
        >
          {industries?.items?.map((industry) => {
            return (
              <option key={industry.id} value={industry.id}>
                {industry.name}
              </option>
            );
          })}
        </Select>
        {/* business name */}
        <Flex mt={6}>
          <Text fontSize={'sm'}>Business Name</Text>
          <Text fontSize={'sm'} color='red'>
            *
          </Text>
        </Flex>
        <Input
          focusBorderColor='#107e76'
          size={'lg'}
          fontSize={'md'}
          borderRadius={10}
          mb={6}
          isRequired={true}
          placeholder={'Enter business name'}
          type='text'
          value={name}
          onChange={(e) => dispatch(addName(e.target.value))}
        />

        {/* phone number */}
        <Flex>
          <Text fontSize={'sm'}>Phone Number</Text>
          <Text fontSize={'sm'} color='red'>
            *
          </Text>
        </Flex>
        <PhoneInput
          inputStyle={{
            height: '45px',
            width: '100%',
            backgroundColor: 'transparent',
            borderColor: '#E2E8F0',
            fontFamily: 'Nunito',
            fontSize: '16px',
            borderRadius: 10,
          }}
          country={'ke'}
          value={phone_number}
          isValid={(inputNumber, country, countries) => {
            return countries.some((country) => {
              return (
                startsWith(inputNumber, country.dialCode) ||
                startsWith(country.dialCode, inputNumber)
              );
            });
          }}
          onChange={(phone) => dispatch(addPhone(phone))}
        />

        {/* email */}
        <Flex mt={6}>
          <Text fontSize={'sm'}>Organization email</Text>
          <Text fontSize={'sm'} color='red'>
            *
          </Text>
        </Flex>
        <Input
          focusBorderColor='#107e76'
          size={'lg'}
          fontSize={'md'}
          borderRadius={10}
          mb={6}
          isRequired={true}
          placeholder={'Enter email'}
          type='email'
          value={email}
          onChange={(e) => dispatch(addEmail(e.target.value))}
        />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <Flex align='center'>
          <Button
            mt={4}
            variant='ghost'
            onClick={() => {
              dispatch(resetOrgReg());
              dispatch(setIsOrgRegOpen(false));
            }}
          >
            Cancel process
          </Button>

          <Spacer />

          <Button mt={4} background='#107e76' textColor='white' type='submit'>
            Next
          </Button>
        </Flex>
      </form>
    </div>
  );
};

export default OrganizationRegistrationPageOne;
