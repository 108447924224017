import {
  Box,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { specialitiesList } from '../../../application/services/utils';

const SpecialityModal = ({ speciality, setSpeciality, isOpen, onClose }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size={'lg'}
      >
        <form onSubmit={handleSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Container mt={8}>
                <Text as='b' fontSize='lg'>
                  Add Speciality
                </Text>

                <Box mt={8} />

                {/* specialities */}
                <Wrap>
                  {specialitiesList?.map((sp) => (
                    <Box
                      borderRadius={8}
                      bgColor={speciality === sp ? '#DEF3F2' : '#F2F2F2'}
                      p={2}
                      onClick={() => setSpeciality(sp)}
                    >
                      <Text textColor={speciality === sp ? '#16ACA1' : null}>
                        {sp}
                      </Text>
                    </Box>
                  ))}
                </Wrap>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='outline'
                mr={3}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant='solid'
                backgroundColor={'#107e76'}
                textColor='white'
                type='submit'
                isDisabled={!speciality}
                onClick={() => onClose()}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Box>
  );
};

export default SpecialityModal;
