import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useRef, useState } from 'react';
import { FiClock, FiMapPin } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { shiftMemberUrl } from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import ShiftAvatars from './ShiftAvatars';

const ShiftCard = ({ day, eventData, triggerFetch }) => {
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);
  const { access_token } = useSelector((state) => state.user);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const [shiftInfo, setShiftInfo] = useState({});

  const start = moment(
    eventData.shift_date + ' ' + eventData.shift_hour?.start_time
  ).format('HH:mm a');
  const end = moment(
    eventData.shift_date + ' ' + eventData.shift_hour?.end_time
  ).format('HH:mm a');

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState('');

  // book shift
  const bookShift = async (rotaMemberId) => {
    setLoading(rotaMemberId);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .post(
        shiftMemberUrl,
        {
          rota_member_id: rotaMemberId,
          shift_id: shiftInfo?.id,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading('');
        triggerFetch();
        toast({
          position: 'bottom-left',
          title: 'Shift booked successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading('');
        toast({
          position: 'bottom-left',
          title: 'Failed to book shift',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  // remove shift
  const updateShift = async (shiftMemberId, is_active) => {
    setLoading(shiftMemberId);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .patch(
        `${shiftMemberUrl}${shiftMemberId}/`,
        {
          is_active: is_active,
          drop_shift_reason: '',
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading('');
        triggerFetch();
        onClose();
        toast({
          position: 'bottom-left',
          title: `${
            is_active ? 'Reactivated to' : 'Removed from'
          } shift successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading('');
        handleError(
          err,
          'Failed to update shift.',
          updateShift,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  const shiftMembers = shiftInfo?.shift_members?.filter(
    (item) => item.is_active === true
  ).length;

  const shiftMemberIndex =
    shiftInfo?.shift_members
      ?.map(function (e) {
        return e.rota_member?.branch_member?.org_member.org_id;
      })
      .indexOf(organisation?.id) ?? -1;

  return eventData.shift_date === day ? (
    <Box>
      <Box
        align='top'
        p={1}
        onClick={() => {
          setShiftInfo(eventData);
          onOpen();
        }}
      >
        <Card
          borderRadius={8}
          mw={175}
          mt={2}
          size={'xs'}
          bg='white'
          key={eventData.id}
          pt={4}
          pb={4}
          pr={2}
          pl={2}
        >
          <CardBody>
            <Heading align={'left'} mb={4} size='xs' noOfLines={1}>
              {eventData.rota.title}
            </Heading>
            <Text align={'left'} mb={4} fontSize='xs'>
              {start} - {end}
            </Text>
            <Box align={'left'}>
              <Tag
                borderRadius='full'
                fontSize='xs'
                mb={4}
                background='#FDF7E6'
                textColor='#CA8A04'
                fontWeight='bold'
              >
                {eventData.shift_hour.shift_type}
              </Tag>
            </Box>
            <ShiftAvatars members={eventData.shift_members} />
          </CardBody>
        </Card>
      </Box>

      {/* shift info */}
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          {/* <DrawerHeader>Create your account</DrawerHeader> */}

          <DrawerBody>
            {/* department */}
            <Text textColor={'black'} mb={2} mt={4}>
              {shiftInfo?.rota?.title}
            </Text>

            {/* shift type */}
            <Tag
              borderRadius='full'
              fontSize='xs'
              mb={3}
              background='#FDF7E6'
              textColor='#CA8A04'
              fontWeight='bold'
            >
              {shiftInfo?.shift_hour?.shift_type}
            </Tag>

            {/* department info */}
            <Flex align={'center'} mt={4}>
              <IconButton variant={'unstyled'} icon={<FiMapPin />} />
              <Box>
                <Text textColor={'#107e76'} fontSize={'xs'} fontWeight='bold'>
                  Department
                </Text>
                <Text textColor={'black'} fontSize={'xs'}>
                  {shiftInfo?.rota?.department?.name}
                </Text>
              </Box>
            </Flex>

            {/* time & date info */}
            <Flex align={'center'} mt={4}>
              <IconButton variant={'unstyled'} icon={<FiClock />} />
              <Box>
                <Text textColor={'#107e76'} fontSize={'xs'} fontWeight='bold'>
                  {moment(
                    shiftInfo?.shift_date +
                      ' ' +
                      shiftInfo?.shift_hour?.start_time
                  ).format('HH:mm a')}{' '}
                  -
                  {moment(
                    shiftInfo?.shift_date +
                      ' ' +
                      shiftInfo?.shift_hour?.end_time
                  ).format('HH:mm a')}
                </Text>
                <Text textColor={'black'} fontSize={'xs'}>
                  {moment(shiftInfo?.shift_date).format('ddd Do, MMM YYYY')}
                </Text>
              </Box>
            </Flex>

            {/* members */}
            <Text
              textColor={'black'}
              fontSize={'sm'}
              fontWeight='bold'
              mt={8}
              mb={4}
            >
              {`${
                shiftInfo?.rota?.members?.length ?? '-'
              } members, ${shiftMembers} on shift`}
            </Text>

            {shiftInfo?.rota?.members
              ?.filter((member) => member.is_active === true)
              .map((member) => (
                <Flex align={'center'} mb={5}>
                  <Avatar
                    name={`${member.branch_member?.org_member?.user?.first_name} ${member.branch_member?.org_member?.user?.last_name}`}
                    size='xs'
                    mr={4}
                  />

                  <Text textColor={'black'} fontSize={'xs'} minW={120}>
                    {`${member.branch_member?.org_member?.user?.first_name} ${member.branch_member?.org_member?.user?.last_name}`}
                  </Text>

                  {/* remove/ reactivate shift */}
                  {shiftInfo?.shift_members?.filter(
                    (e) => e.rota_member.id === member.id
                  ).length > 0 ? (
                    <Button
                      size='xs'
                      backgroundColor={'#FF6868'}
                      textColor='white'
                      mr={3}
                      isLoading={
                        loading ===
                        shiftInfo?.shift_members?.filter(
                          (e) => e.rota_member.id === member.id
                        )[shiftMemberIndex]?.id
                      }
                      onClick={() =>
                        updateShift(
                          shiftInfo?.shift_members?.filter(
                            (e) => e.rota_member.id === member.id
                          )[shiftMemberIndex]?.id,
                          !shiftInfo?.shift_members?.filter(
                            (e) => e.rota_member.id === member.id
                          )[shiftMemberIndex]?.is_active
                        )
                      }
                    >
                      {shiftInfo?.shift_members?.filter(
                        (e) => e.rota_member.id === member.id
                      )[shiftMemberIndex]?.is_active
                        ? 'Remove'
                        : 'Reactivate'}
                    </Button>
                  ) : null}

                  {/* book shift */}
                  {shiftInfo?.shift_members?.filter(
                    (e) => e.rota_member.id === member.id
                  ).length <= 0 ? (
                    <Button
                      size='xs'
                      backgroundColor={'#107e76'}
                      textColor='white'
                      isLoading={loading === member.id}
                      onClick={() => bookShift(member.id)}
                      isDisabled={
                        !roles?.[organisation.id]?.includes('Shifts.update')
                      }
                    >
                      Book
                    </Button>
                  ) : null}
                </Flex>
              ))}
          </DrawerBody>

          <DrawerFooter>
            <Button mr={2} variant='outline' onClick={onClose}>
              Cancel
            </Button>

            {/* remove */}
            {/* {shiftMemberIndex !== -1 ? (
                <Button
                  backgroundColor={'#FF6868'}
                  textColor='white'
                  isLoading={
                    loading === shiftInfo?.shift_members[shiftMemberIndex]?.id
                  }
                  onClick={() => {
                    updateShift(
                      shiftInfo?.shift_members[shiftMemberIndex]?.id,
                      !shiftInfo?.shift_members[shiftMemberIndex]?.is_active
                    );
                  }}
                >
                  {shiftInfo?.shift_members[shiftMemberIndex]?.is_active
                    ? 'Remove'
                    : 'Reactivate'}
                </Button>
              ) : null} */}

            {/* book */}
            {/* {shiftMemberIndex === -1 ? (
                <Button
                  backgroundColor={'#107e76'}
                  textColor='white'
                  isLoading={
                    loading === user?.user_orgs[index].rota_member_id
                  }
                  onClick={() => {
                    onClose();
                    bookShift(user?.user_orgs[index].rota_member_id);
                  }}
                >
                  Book
                </Button>
              ) : null} */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  ) : (
    <div></div>
  );
};

export default ShiftCard;
