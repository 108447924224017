import {
  Button,
  Center,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  forgotPasswordUrl,
  verifyOtpUrl,
} from '../../application/services/http_endpoints';
import { addContactId } from '../../redux/user/userForgotPassword';
import './ForgotPasswordPageOne.scss';
import { ratibaLogoGreenIconSvgPath } from '../../application/services/asset_strings';

const OtpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const [otp, setOTP] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { contactId, email } = useSelector((state) => state.userForgotPassword);
  const [loadingResend, setLoadingResend] = useState(false);

  const verifyOTP = async () => {
    setLoading(true);
    axios
      .post(verifyOtpUrl, {
        contact_id: contactId,
        otp_token: otp,
      })
      .then((response) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'OTP verified successfully.',
          description: response.data.msg,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/reset-pass');
      })
      .catch((err) => {
        setLoading(false);
        toast({
          position: 'bottom-left',
          title: 'OTP verification failed.',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const sendOTP = async () => {
    setLoadingResend(true);
    axios
      .post(forgotPasswordUrl, {
        email: email,
      })
      .then((response) => {
        setLoadingResend(false);
        toast({
          position: 'bottom-left',
          title: 'OTP resent successfully.',
          description: response.data.msg,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        dispatch(addContactId(response.data.contact_id));
        navigate('/otp');
      })
      .catch((err) => {
        setLoadingResend(false);
        toast({
          position: 'bottom-left',
          title: 'Failed to resend OTP.',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    verifyOTP();
    // navigate('/reset-pass');
  };

  return (
    <div className='forgot-password-container'>
      <img src={ratibaLogoGreenIconSvgPath} />
      <div className='forgot-password-header'>
        {/* Title */}
        <h2 className='title'>Verify email</h2>

        {/* desc */}
        <p className='desc'>
          To reset your password check your email for a verification code and
          enter it below
        </p>
      </div>
      <form className='forgot-password-form' onSubmit={handleSubmit}>
        {/* Otp */}

        <HStack justifyContent='center'>
          <PinInput otp size='lg' onChange={(e) => setOTP(e)}>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>

        {/* verify */}
        <Button
          background='#107e76'
          textColor='white'
          type='submit'
          isLoading={isLoading}
        >
          Verify OTP and Proceed
        </Button>

        <Center>
          {/* Change */}
          <p className='link'>
            <NavLink exact to='/' key='login' className='link-btn'>
              <span>Back to login</span>
            </NavLink>
          </p>
        </Center>

        <Center>
          {/* Resend */}
          <Flex align='center'>
            <Text mr={2}>Didn't recieve OTP?</Text>
            <Button
              isLoading={loadingResend}
              variant={'unstyled'}
              textColor='#EF9920'
              onClick={() => sendOTP()}
            >
              Resend
            </Button>
          </Flex>
        </Center>
      </form>
    </div>
  );
};

export default OtpPage;
