import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roles: {},
  roleName: '',
  decodedRoles: {},
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setDecodedRoles: (state, action) => {
      state.decodedRoles = action.payload;
    },
    setRoles: (state, action) => {
      const { key, value } = action.payload;
      state.roles[key] = value;
    },
    setRoleName: (state, action) => {
      state.roleName = action.payload;
    },
    resetRoles: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setRoles, setRoleName, setDecodedRoles, resetRoles } =
  rolesSlice.actions;

export default rolesSlice.reducer;
