import { Button } from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  mailIconSvgPath,
  mobileIconSvgPath,
  successGifPath,
} from '../../application/services/asset_strings';
import { setIsOrgRegOpen } from '../../redux/organisation/organisationRegistration';
import ContactItem from '../core/components/contact_item/ContactItem';

const SuccessPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className='register-container'>
      <div className='success-header'>
        <img className='happy-gif' src={successGifPath} />
        <h2 className='title'>Request submitted</h2>
        <p className='desc'>
          We'll review your details and get back to you within{' '}
          <span>1 business day.</span> Log back in to access new features.
        </p>
      </div>
      <div className='contact-section'>
        <ContactItem
          iconPath={mobileIconSvgPath}
          title='Call us'
          description='0742270270'
          className='contact-item'
        />
        <ContactItem
          iconPath={mailIconSvgPath}
          title='Email us'
          description='hello@ratiba.co.ke'
          className='contact-item'
        />
      </div>

      {/* Continue */}
      <Button
        textColor='white'
        backgroundColor={'#107e76'}
        onClick={() => {
          navigate('/');
        }}
      >
        Continue
      </Button>
    </div>
  );
};

export default SuccessPage;
