import './EmployeeRolesTable.scss';
import { useRef } from 'react';
import DeleteWithPassModal from '../../modalSheets/approveShiftModal/deleteWithPassModal/DeleteWithPassModal';
import ProfileAvatar from '../../components/profile_avatar/ProfileAvatar';

const EmployeeRolesTable = () => {
  const createBranchModalRef = useRef();

  const deleteModalRef = useRef();
  return (
    <div className='employeeRolesTable'>
      <table>
        <tbody>
          <tr>
            <th className='user-name'>EMPLOYEE ROLES</th>
            <th className='deleteCell'>
              <button onClick={() => createBranchModalRef.current.open()}>
                Create role
              </button>
            </th>
          </tr>

          <tr>
            <td className='contentCell'>
              <ProfileAvatar colorCode='code1'>PD</ProfileAvatar>
              <p className='contentCellName'>Product Designer</p>
            </td>
            <td>
              <p
                className='delete'
                onClick={() => deleteModalRef.current.open()}
              >
                Delete
              </p>
            </td>
          </tr>
          <tr>
            <td className='contentCell'>
              <ProfileAvatar colorCode='code8'>SE</ProfileAvatar>
              <p className='contentCellName'>Software Engineer</p>
            </td>
            <td>
              <p
                className='delete'
                onClick={() => deleteModalRef.current.open()}
              >
                Delete
              </p>
            </td>
          </tr>
          <tr>
            <td className='contentCell'>
              <ProfileAvatar colorCode='code7'>PM</ProfileAvatar>
              <p className='contentCellName'>Project Manager</p>
            </td>
            <td>
              <p
                className='delete'
                onClick={() => deleteModalRef.current.open()}
              >
                Delete
              </p>
            </td>
          </tr>
          <tr>
            <td className='contentCell'>
              <ProfileAvatar colorCode='code6'>MM</ProfileAvatar>
              <p className='contentCellName'>Marketing Director</p>
            </td>
            <td>
              <p className='delete'>Delete</p>
            </td>
          </tr>
          <tr>
            <td className='contentCell'>
              <ProfileAvatar colorCode='code5'>PD</ProfileAvatar>
              <p className='contentCellName'>Finance Manager</p>
            </td>
            <td>
              <p
                className='delete'
                onClick={() => deleteModalRef.current.open()}
              >
                Delete
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <DeleteWithPassModal
        title='Delete Product Manager Role'
        desc='role'
        deleteWithPassModalRef={deleteModalRef}
      />
    </div>
  );
};

export default EmployeeRolesTable;
