import { createSlice } from '@reduxjs/toolkit';
import PaymentPlansPage from '../../presentation/payment_plans/PaymentPlansPage';

const initialState = {
  activePath: '',
  settingsPath: 0,
  settingsComp: <PaymentPlansPage />,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setActivePath: (state, action) => {
      state.activePath = action.payload;
    },
    setSettingsPath: (state, action) => {
      state.settingsPath = action.payload;
    },
    setSettingsComp: (state, action) => {
      state.settingsComp = action.payload;
    },
    resetNavigation: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setActivePath,
  settingsComp,
  setSettingsPath,
  setSettingsComp,
  resetNavigation,
} = navigationSlice.actions;

export default navigationSlice.reducer;
