import {
  Button,
  Container,
  Flex,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { departmentsUrl } from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';

const DepartmentModal = ({
  fetchDepartments,
  selectedDepartment,
  isEdit,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);

  const [isLoading, setLoading] = useState(false);

  const [department, setDepartment] = useState('');

  const createDepartment = async () => {
    if (!branch || branch === '') {
      toast({
        position: 'bottom-left',
        title: 'Branch missing',
        description: 'Create a branch to proceed',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .post(
          departmentsUrl,
          {
            name: department,
            branch_id: branch,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setLoading(false);
          onClose();
          fetchDepartments();
          toast({
            position: 'bottom-left',
            title: 'Department created successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((err) => {
          setLoading(false);
          handleError(
            err,
            'Failed to create department.',
            createDepartment,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  };

  const updateDepartment = async (payload) => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .patch(`${departmentsUrl}${selectedDepartment.id}/`, payload, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);

        setLoading(false);
        onClose();
        fetchDepartments();
        toast({
          position: 'bottom-left',
          title: 'Department updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          'Failed to update department.',
          updateDepartment,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Container mt={8}>
            {/* via email */}
            <Text as='b' fontSize='lg'>
              {isEdit ? 'Edit department' : 'Create department'}
            </Text>
            <Text fontSize='sm' mb={8} mt={2}>
              You can assign rotas and team members to departments for easier
              tracking
            </Text>
            {/* department name */}
            <Flex>
              <Text fontSize={'sm'}>Department name</Text>
              <Text fontSize={'sm'} color='red'>
                *
              </Text>
            </Flex>
            <InputGroup size='md' mb={8} mt={2}>
              <Input
                type={'text'}
                pr='4.5rem'
                focusBorderColor='#107e76'
                placeholder='Enter name'
                defaultValue={selectedDepartment?.name}
                onChange={(e) => setDepartment(e.target.value)}
              />
            </InputGroup>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='solid'
            backgroundColor={'#107e76'}
            textColor='white'
            isLoading={isLoading}
            isDisabled={department === ''}
            onClick={() => {
              isEdit
                ? updateDepartment({
                    name: department,
                    branch_id: branch,
                  })
                : createDepartment();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DepartmentModal;
