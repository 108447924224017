import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  loggedIn: false,
  expiry: new Date(),
  isSessionExpired: false,
  access_token: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    addExpiry: (state, action) => {
      state.expiry = action.payload;
    },
    setSessionExpired: (state, action) => {
      state.isSessionExpired = action.payload;
    },
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
    resetUser: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  addUser,
  setLoggedIn,
  addExpiry,
  setSessionExpired,
  refreshToken,
  setAccessToken,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;
