import {
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './UsersTable.css';

const MemberRequestsTable = ({ members, setSelectedMember, onEditOpen }) => {
  const { user } = useSelector((state) => state.user);

  return (
    <TableContainer backgroundColor={'white'}>
      <Table size='md'>
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Invited on</Th>
            {/* <Th>Account</Th> */}
            <Th>Status</Th>
            {/* <Th>Actions</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {members?.length > 0 ? (
            members.map((item) => (
              <Tr key={item.member?.user.id}>
                <Td>
                  {/* email */}
                  <Text textColor='black' fontSize='sm'>
                    {item?.email}
                  </Text>
                </Td>
                {/* created */}
                <Td>
                  <Text textColor='black' fontSize='sm'>
                    {moment(item.created_at).format('MMM DD, YYYY')}
                  </Text>
                  <Text fontSize='sm'>
                    {moment.utc(item.created_at).local().format('HH:mm a')}
                  </Text>
                </Td>
                {/* role */}
                {/* <Td>
                  <Text fontSize='sm'>
                    {item.member?_type.member?_type_name ?? 'Not assigned'}
                  </Text>
                </Td> */}
                {/* status */}
                {/* <Td>
                  {item.member?.user.is_active ? (
                    <Tag
                      borderRadius='full'
                      backgroundColor='#53A24C'
                      textColor='white'
                    >
                      Active
                    </Tag>
                  ) : (
                    <Tag
                      borderRadius='full'
                      backgroundColor='#C80024'
                      textColor='white'
                    >
                      Deactivated
                    </Tag>
                  )}
                </Td> */}
                {/* invite status */}
                <Td>
                  <Tag
                    borderRadius='full'
                    backgroundColor={
                      item.status !== 'ACCEPTED' ? '#EF99204D' : 'lightblue'
                    }
                    textColor={
                      item.status !== 'ACCEPTED' ? '#EF9920' : '#107e76'
                    }
                    fontWeight='semibold'
                  >
                    {item.status}
                  </Tag>
                </Td>
                {/* assign role */}
                {/* <Td>
                  <Button
                    size='sm'
                    mr={5}
                    backgroundColor='#DAF2F0'
                    textColor='#107e76'
                    // isDisabled={user.member?.user.id === item.member?.user.id}
                    onClick={() => {
                      setSelectedMember(item);
                      onEditOpen();
                    }}
                  >
                    Edit
                  </Button>
                </Td> */}
              </Tr>
            ))
          ) : (
            <Tr className='no-record'>
              <Td colSpan='5'>
                <Text fontSize='sm'>No records found</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default MemberRequestsTable;
