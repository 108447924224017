import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  addRotaMembersUrl,
  branchMemberUrl,
  deleteRotaMembersUrl,
} from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';

const RotaMembersModal = ({
  triggerFetch,
  fetchRotas,
  selectedRota,
  setSelectedRota,
  membersInfo,
  setMembersInfo,
  isOpen,
  onClose,
  isEdit,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access_token } = useSelector((state) => state.user);
  const { organisation, branch } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);

  const [searchMember, setSearchMember] = useState('');

  const [isRotaCreated, setRotaCreated] = useState(false);

  const [isMemberRemoved, setMemberRemoved] = useState('');

  const [newMembers, setNewMembers] = useState([]);
  const [newMembersInfo, setNewMembersInfo] = useState([]);

  const [filterMemberData, setFilterMemberData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  // add rota members
  const addRotaMembers = async () => {
    setRotaCreated(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .post(addRotaMembersUrl, newMembers, {
        headers: headers,
      })
      .then((response) => {
        setRotaCreated(false);

        setSearchMember('');
        setFilterMemberData([]);
        setNewMembers([]);
        setMembersInfo([]);
        setNewMembersInfo([]);
        fetchRotas();
        triggerFetch();
        onClose();
        toast({
          position: 'bottom-left',
          title: 'Rota members added successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setRotaCreated(false);
        handleError(
          err,
          'Failed to add rota members.',
          addRotaMembers,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  //   delete rota member
  const deleteRotaMember = async (branch_member_id) => {
    setMemberRemoved(branch_member_id);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id,
    };
    axios
      .delete(`${deleteRotaMembersUrl}${branch_member_id}/`, {
        headers: headers,
      })
      .then((response) => {
        setSearchMember('');
        setFilterMemberData([]);
        setMemberRemoved('');
        setNewMembers([]);
        setMembersInfo([]);
        fetchRotas();
        triggerFetch();
        onClose();
        toast({
          position: 'bottom-left',
          title: `Rota member removed successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setMemberRemoved('');
        toast({
          position: 'bottom-left',
          title: 'Failed to remove rota member.',
          description: err?.response?.data?.detail?.toString(),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addRotaMembers();
  };

  // const searchUser = () => {
  //   setSearchLoading(true);

  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${access_token}`,
  //     'organisation-id': organisation?.id,
  //   };
  //   axios
  //     .get(branchMemberUrl, {
  //       params: { branch_id: branch ?? '', email: searchMember },
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       setSearchLoading(false);
  //       setFilterMemberData(res.data.items);
  //     })
  //     .catch((err) => {
  //       setSearchLoading(false);
  //       handleError(
  //         err,
  //         'Failed to search user.',
  //         searchUser,
  //         access_token,
  //         dispatch,
  //         toast,
  //         navigate
  //       );
  //     });
  // };

  // fetch branch members
  const fetchBranchMembers = async () => {
    if (roles?.[organisation?.id]?.includes('BranchMembers.list')) {
      setSearchLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id,
      };
      axios
        .get(branchMemberUrl, {
          params: { branch_id: branch ?? '' },
          headers: headers,
        })
        .then((res) => {
          setSearchLoading(false);
          setFilterMemberData(res.data.items);
        })
        .catch((err) => {
          setSearchLoading(false);
          handleError(
            err,
            'Failed to fetch branch members.',
            fetchBranchMembers,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  };

  useEffect(() => {
    // Trigger the fetch
    fetchBranchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRota]);

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setNewMembers([]);
          setSelectedRota({});
          onClose();
        }}
        size={'lg'}
      >
        <form onSubmit={handleSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Container mt={8}>
                <Text as='b' fontSize='lg'>
                  Edit Rota Members
                </Text>
                <Text fontSize='sm' mb={8} mt={2}>
                  Add new or remove existing rota members
                </Text>

                {roles?.[organisation?.id]?.includes('Rota.update') ? (
                  <Box>
                    {/* members */}
                    <Text fontSize={'sm'}>Branch members</Text>
                    <InputGroup size='md' mb={8} mt={2}>
                      <Input
                        type={'text'}
                        pr='4.5rem'
                        placeholder='Search name'
                        focusBorderColor='#107e76'
                        value={searchMember}
                        onChange={(e) => setSearchMember(e.target.value)}
                      />
                      {/* <InputRightElement width='4.5rem'>
                        <Button
                          variant={'unstyled'}
                          textColor='#107e76'
                          size='md'
                          isLoading={searchLoading}
                          onClick={() => searchUser()}
                        >
                          Search
                        </Button>
                      </InputRightElement> */}
                    </InputGroup>
                    {searchMember !== '' &&
                      filterMemberData
                        .filter(
                          (member) =>
                            member?.org_member?.user.first_name
                              ?.toLowerCase()
                              .includes(searchMember?.toLowerCase()) ||
                            member?.org_member?.user.last_name?.includes(
                              searchMember?.toLowerCase()
                            )
                        )
                        .map((item) => (
                          <Flex key={item.id} mb={4} alignItems='center'>
                            <Avatar
                              size={'md'}
                              name={`${item.org_member.user.first_name} ${item.org_member.user.last_name}`}
                              backgroundColor='#C6DDDB'
                              textColor={'#107e76'}
                              fontWeight={'bold'}
                              mr={4}
                            />
                            <Text key={item.id}>
                              {item.org_member.user.first_name}{' '}
                              {item.org_member.user.last_name}
                            </Text>
                            <Spacer />

                            <IconButton
                              aria-label='Search database'
                              icon={<MdAdd />}
                              variant='unstyled'
                              isDisabled={selectedRota?.members?.find(
                                (obj) =>
                                  obj?.hasOwnProperty('branch_member') &&
                                  obj.branch_member?.id === item?.id &&
                                  obj?.hasOwnProperty('is_active') &&
                                  obj.is_active
                              )}
                              onClick={() => {
                                setSearchMember('');
                                setNewMembers([
                                  ...newMembers,
                                  {
                                    branch_member_id: `${item.id}`,
                                    rota_id: `${selectedRota.id}`,
                                  },
                                ]);
                                setNewMembersInfo([...newMembersInfo, item]);
                                // remove user from filter
                                setFilterMemberData(
                                  filterMemberData?.filter(
                                    (a) => a.id !== item.id
                                  )
                                );
                              }}
                            />
                          </Flex>
                        ))}
                    <Box mt={8}></Box>

                    {/* new members */}
                    {newMembersInfo.length > 0
                      ? newMembersInfo.map((item) => (
                          <Tag
                            mr={3}
                            mb={3}
                            size={'lg'}
                            borderRadius='full'
                            variant='solid'
                            background='#107e76'
                            textColor='white'
                          >
                            <TagLabel>
                              {item?.org_member?.user?.first_name}
                            </TagLabel>
                            <TagCloseButton
                              isLoading={isMemberRemoved === item.id}
                              onClick={() => {
                                setNewMembers(
                                  newMembers.filter(
                                    (a) => a?.branch_member_id !== item.id
                                  )
                                );
                                setNewMembersInfo(
                                  newMembersInfo.filter((a) => a.id !== item.id)
                                );
                                // add user back to filter
                                setFilterMemberData([
                                  ...filterMemberData,
                                  item,
                                ]);
                              }}
                            />
                          </Tag>
                        ))
                      : null}

                    {/* current members */}
                    {membersInfo.filter((member) => member.is_active === true)
                      .length > 0
                      ? membersInfo
                          .filter((member) => member.is_active === true)
                          .map((item) => (
                            <Tag
                              mr={3}
                              mb={3}
                              size={'lg'}
                              borderRadius='full'
                              variant='solid'
                              colorScheme='yellow'
                            >
                              <TagLabel>
                                {
                                  item?.branch_member?.org_member?.user
                                    ?.first_name
                                }
                              </TagLabel>
                              <TagCloseButton
                                isLoading={isMemberRemoved === item.id}
                                onClick={() => {
                                  deleteRotaMember(item.id);
                                  // setMembers(
                                  //   members.filter((a) => a.id !== item.id)
                                  // );
                                  // setMembersInfo(
                                  //   membersInfo.filter((a) => a.id !== item.id)
                                  // );
                                }}
                              />
                            </Tag>
                          ))
                      : null}
                  </Box>
                ) : null}
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='outline'
                mr={3}
                onClick={() => {
                  setSearchMember('');
                  setFilterMemberData([]);
                  setNewMembers([]);
                  setMembersInfo([]);
                  setNewMembersInfo([]);
                  setSelectedRota({});
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant='solid'
                backgroundColor={'#107e76'}
                textColor='white'
                isLoading={isRotaCreated}
                type='submit'
                isDisabled={
                  isEdit
                    ? !roles?.[organisation?.id]?.includes('Roles.update')
                    : !roles?.[organisation?.id]?.includes('Roles.create')
                }
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Box>
  );
};

export default RotaMembersModal;
