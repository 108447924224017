import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAccessToken } from '../../redux/user/user';
import { refreshTokenUrl } from './http_endpoints';
import { useLogout } from './http_service';
import { withRouter } from './with-router';

const AuthVerify = () => {
  const { user, access_token, expiry } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { logoutUser } = useLogout();

  useEffect(() => {
    if (user) {
      if (moment(expiry).isBefore(moment())) {
        // axios
        //   .post(
        //     refreshTokenUrl,
        //     {
        //       token: access_token,
        //     },
        //     {
        //       headers: {
        //         'Content-Type': 'application/json',
        //         Authorization: `Bearer ${access_token}`,
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     dispatch(setAccessToken(response?.token));
        //   })
        //   .catch((err) => {
        //     logoutUser();
        //   });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiry, navigate, user]);

  return <div></div>;
};

export default withRouter(AuthVerify);
