import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { rolesUrl } from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';

const RolesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { branch } = useSelector((state) => state.organisation);
  const [isRolesLoading, setRolesLoading] = useState(false);

  const { access_token } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const { decodedRoles, roles } = useSelector((state) => state.roles);

  const [rolesList, setRolesList] = useState([]);

  async function fetchRoles() {
    setRolesLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .get(rolesUrl, {
        params: { branch_id: branch ?? '' },
        headers: headers,
      })
      .then((response) => {
        setRolesLoading(false);
        setRolesList(response.data.items);
      })
      .catch((err) => {
        setRolesLoading(false);
        handleError(
          err,
          'Failed to fetch roles.',
          fetchRoles,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  useEffect(() => {
    // Trigger the fetch
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (isEdit) => {
    onOpen();
  };

  return (
    <div>
      <Flex m={8} align='center'>
        <Container>
          <Heading as='h4' size='md' mb={2}>
            Roles and permissions
          </Heading>
          <Text fontSize='sm'>
            Create and manage your employee roles and permissions
          </Text>
        </Container>

        <Spacer />
        <Button
          noOfLines={1}
          isDisabled={
            organisation?.id === undefined ||
            organisation?.id === '' ||
            !roles?.[organisation?.id]?.includes('Roles.create')
          }
          onClick={() => {
            navigate('/settings/create_role');
          }}
          backgroundColor='#107e76'
          textColor={'white'}
        >
          Create
        </Button>
      </Flex>

      {isRolesLoading ? (
        <Center>
          <BounceLoader color={'#107e76'} loading={isRolesLoading} size={80} />
        </Center>
      ) : (
        <div>
          <TableContainer m={8} backgroundColor={'white'}>
            <Table size='md'>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Date created</Th>
                  <Th>Permissions</Th>
                  {/* <Th>Actions</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {rolesList.length > 0 ? (
                  rolesList.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        <Box align='center'>
                          <Text
                            style={{ 'text-transform': 'capitalize' }}
                            textColor='black'
                            fontSize='sm'
                          >
                            {item.name}
                          </Text>
                          {decodedRoles[organisation?.id] === item.id ? (
                            <Tag
                              fontSize='xs'
                              fontWeight='bold'
                              borderRadius='full'
                              backgroundColor='#EF99204D'
                              textColor='#EF9920'
                              variant='solid'
                            >
                              Assigned
                            </Tag>
                          ) : null}
                        </Box>
                      </Td>
                      {/* created */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {moment(item.created_at).format('MMM DD, YYYY')}
                        </Text>
                        <Text fontSize='sm'>
                          {moment
                            .utc(item.created_at)
                            .local()
                            .format('HH:mm a')}
                        </Text>
                      </Td>
                      <Td>
                        <Flex align='center'>
                          <Text noOfLines={1}>
                            {item.user_facing_permissions.slice(0, 2).join()}
                          </Text>
                          <Button
                            variant='unstyled'
                            ml={1}
                            textColor='#107e76'
                            onClick={() => {
                              navigate('/settings/create_role', {
                                state: {
                                  id: item.id,
                                  name: item.name,
                                  permissions: item.user_facing_permissions,
                                },
                              });
                            }}
                          >
                            more
                          </Button>
                        </Flex>
                      </Td>
                      {/* assign role */}
                      <Td>
                        <Button
                          backgroundColor='#DAF2F0'
                          textColor='#107e76'
                          size='sm'
                          onClick={() => {
                            navigate('/settings/create_role', {
                              state: {
                                id: item.id,
                                name: item.name,
                                permissions: item.user_facing_permissions,
                              },
                            });
                          }}
                          mr={4}
                        >
                          Edit
                        </Button>
                        {/* <Button
                          mr={5}
                          textColor='#C80024'
                          variant='outline'
                          backgroundColor={'#F8E0E5'}
                          size='sm'
                          onClick={() => ''}
                        >
                          Deactivate
                        </Button> */}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr className='no-record'>
                    <Td colSpan='5'>
                      <Text fontSize='sm'>No records found</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default RolesPage;
