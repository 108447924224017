export const manageShiftsString = "Manage shifts";
export const manageShiftsDescString =
  "We provide a seamless flow to create and track employee shifts";

export const shiftSwapsString = "Shift swaps";
export const shiftSwapsDescString =
  "With swift swaps you can easily manage employee swaps";

export const analyticsString = "Analytics";
export const analyticsDescString =
  "Get valuable analytics on how employees book and swap shifts";

// login
export const logInString = "Log in";
export const logInDescString = "Log in to access your account";
export const emailLabelString = "Email address";
export const emailPlaceHoldeString = "Enter email address";
export const passwordLabelString = "Password";
export const passwordPlaceHolderString = "Enter your password";
export const continueString = "Continue";
export const forgotPasswordString = "Forgot password?";
export const registerString = "Register";

// Register page
export const registerPageOneTitleString = 'Create your Ratiba account';
export const registerPageOneDescriptionString =
  'Enter your details to create your Ratiba account';
  
  export const registerPageTwoTitleString = 'Welcome to Ratiba';
  export const registerPageTwoDescriptionString =
    'Your personal information is private and safe';

// forgot password
export const forgotPasswordPageOneTitleString = "Having trouble signing in?";
export const forgotPasswordPageOneDescString =
  "Enter your email to get started";
export const resetPasswordString = "Reset password";
export const createPasswordDescString = "Create your new password";
export const createPasswordString = "Create password";
export const confirmPasswordString = "Confirm password";

// Home
export const createOrganizationString = "Create organization";
export const createAnOrganizationString = "Create an organization";
export const createOrganizationDescriptionString = "Are you a business looking to manage your shifts, create an organization with us to add your employees and manage their shifts";
export const joinOrganizationString = "Join an Organization";
export const joinOrganizationDescriptionString = "As an employee, you can as your admin to add you your company to be able to book shifts";
export const trackYourShiftsString = "Track your shifts";
export const trackYourShiftsDescriptionString = "Avoid the paper hustle by moving your shifts to Ratiba. We'll keep you up to date on your incoming shifts";
export const createShiftString = "Create shifts";

// List of Shit Types
export const shiftTypes = ['Day Time Full Day', 'Night Time Full Day', 'Day Time Half Day', 'Night Time Half Day'];