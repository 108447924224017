export const baseUrl =
  process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:8000';

// login
export const loginUrl = `${baseUrl}/api/v1/auth/login/`;

// register
export const registerUrl = `${baseUrl}/api/v1/auth/register/`;

// forgot password
export const forgotPasswordUrl = `${baseUrl}/api/v1/auth/forgot_password/`;

// reset password
export const resetPasswordUrl = `${baseUrl}/api/v1/auth/reset_password/`;

// generate OTP
export const genearteOtpUrl = `${baseUrl}/api/v1/contacts/generate-otp/`;

// verify OTP
export const verifyOtpUrl = `${baseUrl}/api/v1/contacts/verify-otp/`;

// register organisation
export const registerOrganisationUrl = `${baseUrl}/api/v1/organisations/organisations/`;

// list organisations
export const getOrganisationsUrl = `${baseUrl}/api/v1/organisations/organisations/`;

// list industries
export const getIndustriesUrl = `${baseUrl}/api/v1/industries/`;

// access token
export const accessTokenUrl = `${baseUrl}/api/v1/auth/access-token/`;

// list branches
export const getBranchesUrl = `${baseUrl}/api/v1/branches/`;

// list members
export const getMembersUrl = `${baseUrl}/api/v1/organisations/member/`;

// users
export const usersUrl = `${baseUrl}/api/v1/users/`;

// departments
export const departmentsUrl = `${baseUrl}/api/v1/departments/`;

// departments
export const shiftHoursUrl = `${baseUrl}/api/v1/shift_hours/`;

// rotas
export const rotasUrl = `${baseUrl}/api/v1/rotas/`;

// rotas
export const createScheduleUrl = `${baseUrl}/api/v1/rotas/create_schedule/`;

// shifts
export const shiftsUrl = `${baseUrl}/api/v1/shifts/`;

// book shift
export const shiftMemberUrl = `${baseUrl}/api/v1/shift_members/`;

// branch members
export const branchMemberUrl = `${baseUrl}/api/v1/branch_member/`;

// employee timesheet
export const employeesTimeSheetrUrl = `${baseUrl}/api/v1/timesheet/employees_timesheet/`;

// timesheet hours
export const timeSheetHoursrUrl = `${baseUrl}/api/v1/timesheet/shift_hours/`;

// roles
export const rolesUrl = `${baseUrl}/api/v1/auth/roles/`;

// permissions
export const permissionsUrl = `${baseUrl}/api/v1/auth/permissions/grouped/`;

// member types
export const memberTypesUrl = `${baseUrl}/api/v1/organisations/member_types/`;

// billing_plans
export const billingPlansUrl = `${baseUrl}/api/v1/billing/plans/`;

// invite org member
export const sendInviteUrl = `${baseUrl}/api/v1/organisations/member/invites/send/`;

// accept invite
export const checkUserExistUrl = `${baseUrl}/api/v1/organisations/member/check_user_exists_by_token_id/`;

export const acceptInviteUrl = `${baseUrl}/api/v1/organisations/member/invites/`;

// bulk member invite
export const bulkInviteUrl = `${baseUrl}/api/v1/organisations/member/bulk_upload/`;

// add rota members
export const addRotaMembersUrl = `${baseUrl}/api/v1/rota_members/add_rota_members/`;

// delete members
export const deleteRotaMembersUrl = `${baseUrl}/api/v1/rota_members/`;

// reports
export const departmentReportsUrl = `${baseUrl}/api/v1/reports/department_summary/`;
export const branchReportsUrl = `${baseUrl}/api/v1/reports/branch_summary/`;
export const organisationReportsUrl = `${baseUrl}/api/v1/reports/organisation_summary/`;

// member logs
export const memberLogsUrl = `${baseUrl}/api/v1/events/`;

// refresh access token
export const refreshTokenUrl = `${baseUrl}/api/v1/auth/refresh-token/`;

// download template
export const templateUrl = `${baseUrl}/api/v1/organisations/member/invites/template/`;

// user organisations
export const userOrganisationsUrl = `${baseUrl}/api/v1/organisations/organisations/me/`;
// billing checkout
export const checkoutUrl = `${baseUrl}/api/v1/billing/payments/checkout/express`;

// employee type
export const employeeTypeUrl = `${baseUrl}/api/v1/organisations/employee_type/`;

// procedures
export const proceduresUrl = `${baseUrl}/api/v1/procedures/procedures/`;
export const procedureStatsUrl = `${baseUrl}/api/v1/procedures/procedures/procedure_statistics/`;

// procedure items
export const procedureItemsUrl = `${baseUrl}/api/v1/procedures/items/`;

// patient records
export const patientRecordsUrl = `${baseUrl}/api/v1/procedures/patient_records/`;
export const patientStatsUrl = `${baseUrl}/api/v1/procedures/patient_records/patient_record_statistics/`;
