import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { attachmentSvgPath } from '../../application/services/asset_strings';
import { getOrganisationsUrl } from '../../application/services/http_endpoints';
import { handleError } from '../../application/services/http_service';
import {
  addViewLat,
  addViewLng,
  setIsViewMap,
} from '../../redux/organisation/organisationRegistration';

const OrganizationDetailsPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { user, access_token } = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let { id } = useParams();
  const { organisation } = useSelector((state) => state.organisation);
  const { roles } = useSelector((state) => state.roles);
  const [organisationInfo, setOrganisationInfo] = useState({});

  const [isLoading, setLoading] = useState(false);

  // fetch organisation details
  async function fetchData() {
    if (roles?.[organisation?.id]?.includes('Organisations.list')) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'organisation-id': organisation?.id ?? '',
      };
      axios
        .get(`${getOrganisationsUrl}?id=${id}`, {
          headers: headers,
        })
        .then((response) => {
          setOrganisationInfo(response.data.items[0]);
        })
        .catch((err) => {
          handleError(
            err,
            'Failed to fetch organisation details.',
            fetchData,
            access_token,
            dispatch,
            toast,
            navigate
          );
        });
    }
  }

  // update organisations
  const updateOrganisation = async (status) => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .patch(
        `${getOrganisationsUrl}${organisationInfo?.id}/`,
        {
          status: status,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setLoading(false);
        fetchData();
        toast({
          position: 'bottom-left',
          title: `Organisation ${status.toLowerCase()} successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        handleError(
          err,
          `Failed to ${status.toLowerCase()} update organisation.`,
          updateOrganisation,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  };

  useEffect(() => {
    // Trigger the fetch
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const email =
    user?.user_contacts?.[0]?.contact?.contact_type === 'EMAIL'
      ? user?.user_contacts?.[0]?.contact?.contact_value
      : user?.user_contacts?.[1]?.contact?.contact_value;

  return (
    <div>
      <Box mt={12} />

      <Breadcrumb ml={16} fontWeight='medium' fontSize='sm'>
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => navigate(-1)}>
            Organizations
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>Details</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex m={12} align='center'>
        <Container>
          <Heading as='h4' size='md' mb={2}>
            Organization details
          </Heading>
          <Text noOfLines={1} fontSize='sm'>
            You organization details pending approval
          </Text>
        </Container>

        <Spacer />
        <Tag
          borderRadius='full'
          backgroundColor={
            organisationInfo?.status === 'VERIFIED' ? '#107e76' : '#EF9920'
          }
          variant='solid'
        >
          <Text noOfLines={1} textColor='white'>
            {organisationInfo.status ?? 'N/A'}
          </Text>
        </Tag>
      </Flex>

      <Card backgroundColor='white' m={12}>
        <CardBody>
          <Flex
            m={8}
            align='center'
            justifyContent='center'
            display={{ base: 'grid', md: 'flex' }}
          >
            {/* name */}
            <Container>
              <Text noOfLines={1} fontSize='sm'>
                Name
              </Text>
              <Text textColor='black' size='lg' mb={2}>
                {organisationInfo.name}
              </Text>
            </Container>

            {/* Registration number */}
            <Container>
              <Text noOfLines={1} fontSize='sm'>
                Registration number
              </Text>
              <Text textColor='black' size='lg' mb={2}>
                {organisationInfo.registration_number}
              </Text>
            </Container>

            {/* KRA PIN number */}
            <Container>
              <Text noOfLines={1} fontSize='sm'>
                KRA PIN number
              </Text>
              <Text textColor='black' size='lg' mb={2}>
                {organisationInfo.kra_number}
              </Text>
            </Container>

            {/* Industry */}
            <Container>
              <Text noOfLines={1} fontSize='sm'>
                Industry
              </Text>
              <Text textColor='black' size='lg' mb={2}>
                {organisationInfo.industry?.name}
              </Text>
            </Container>

            <Spacer />
          </Flex>
        </CardBody>
      </Card>

      <Card backgroundColor='white' margin={12} padding={8}>
        <CardBody>
          <Text noOfLines={1} fontSize='sm'>
            Attachments
          </Text>

          <Card
            variant='outline'
            size='sm'
            minW={200}
            mt={8}
            borderRadius={10}
            onClick={() => {
              if (organisationInfo.org_kyc_attachments?.[0]?.kyc_cert.doc_url)
                window.open(
                  organisationInfo.org_kyc_attachments?.[0]?.kyc_cert.doc_url
                );
              else
                toast({
                  position: 'bottom-left',
                  title: 'No attachment to preview',
                  status: 'warning',
                  duration: 5000,
                  isClosable: true,
                });
            }}
            shadow={0}
          >
            <CardBody>
              <Flex>
                <Box p='6' mr='8' borderRadius={10} backgroundColor='#107e761C'>
                  <Image
                    boxSize='18px'
                    src={attachmentSvgPath}
                    fallbackSrc='https://via.placeholder.com/150'
                  />
                </Box>
                <div>
                  <Text textColor='black' size='lg' mt={2}>
                    Certificate of registration
                  </Text>
                  <Text size='xs' mb={2}>
                    Tap to preview
                  </Text>
                </div>
              </Flex>
            </CardBody>
          </Card>
        </CardBody>
      </Card>

      <Card backgroundColor='white' margin={12} padding={8}>
        <CardBody>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Branch Name</Th>
                  <Th>Phone Number</Th>
                  <Th>Email</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {organisationInfo.branches?.length > 0 ? (
                  organisationInfo.branches.map((branch) => (
                    <Tr>
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {branch.name}
                        </Text>
                      </Td>
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {branch.contacts[0]?.contact.contact_type === 'PHONE'
                            ? branch.contacts[0]?.contact.contact_value ?? '-'
                            : branch.contacts[1]?.contact.contact_value ?? '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {branch.contacts[0]?.contact.contact_type === 'EMAIL'
                            ? branch.contacts[0]?.contact.contact_value ?? '-'
                            : branch.contacts[1]?.contact.contact_value ?? '-'}
                        </Text>
                      </Td>
                      <Td>
                        <Button
                          mr={5}
                          textColor='#107e76'
                          variant='outline'
                          backgroundColor={'#DAF2F0'}
                          size='sm'
                          onClick={() => {
                            dispatch(addViewLat(branch.latitude));
                            dispatch(addViewLng(branch.longitude));
                            dispatch(setIsViewMap('organisation'));
                            navigate('/maps');
                          }}
                        >
                          View Location
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Td colSpan='5'>
                    <Text fontSize='sm'>No records found</Text>
                  </Td>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>

      {/* approve/ reject organisation */}
      {roles?.[organisation?.id]?.includes('Admin.update_user') &&
      email === 'hello@ratiba.co.ke' ? (
        <Flex align='right' m={12}>
          <Spacer />
          <ButtonGroup variant='outline' spacing='6' ml={12} mb={12}>
            <Button
              isDisabled={
                organisationInfo?.status === 'REJECTED' ||
                organisationInfo?.status === 'SUSPENDED' ||
                !roles?.[organisation?.id]?.includes('Admin.update_user')
              }
              textColor='white'
              backgroundColor={
                organisationInfo?.status === 'VERIFIED' &&
                organisationInfo?.status !== 'REJECTED'
                  ? '#EF9920'
                  : '#C80024'
              }
              isLoading={isLoading}
              onClick={() =>
                updateOrganisation(
                  organisationInfo?.status === 'VERIFIED' &&
                    organisationInfo?.status !== 'REJECTED'
                    ? 'SUSPENDED'
                    : 'REJECTED'
                )
              }
            >
              {organisationInfo?.status === 'VERIFIED' &&
              organisationInfo?.status !== 'REJECTED'
                ? 'Suspend'
                : 'Reject'}
            </Button>
            <Button
              isDisabled={
                organisationInfo?.status === 'VERIFIED' ||
                !roles?.[organisation?.id]?.includes('Admin.update_user')
              }
              textColor='white'
              backgroundColor={'#107e76'}
              isLoading={isLoading}
              onClick={() => updateOrganisation('VERIFIED')}
            >
              Verify
            </Button>
          </ButtonGroup>
        </Flex>
      ) : null}

      {/* cert of reg */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Certifcate of Registration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Image
                src={
                  organisationInfo.org_kyc_attachments?.[0]?.kyc_cert.doc_url
                }
                alt='Certificate of Registration'
              />
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OrganizationDetailsPage;
