import { Box, Card, CardBody, CardHeader, Text } from '@chakra-ui/react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React from 'react';
import { Pie } from 'react-chartjs-2';

const AttendanceReport = ({
  organisationReports,
  branchReports,
  departmentReports,
  reportsFilter,
}) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  // const data = {
  //   labels: ['Completed', 'Dropped'],
  //   datasets: [
  //     {
  //       label: 'Number',
  //       data: [24, 5],
  //       backgroundColor: ['#107e76', '#F69520'],
  //       borderColor: ['#107e76', '#F69520'],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const dispData = (() => {
    if (reportsFilter === 'organisation')
      return [
        organisationReports?.organisation_attendance_summary?.completed,
        organisationReports?.organisation_attendance_summary?.pending,
        organisationReports?.organisation_attendance_summary?.dropped,
        organisationReports?.organisation_attendance_summary?.swapped,
      ];
    else if (reportsFilter === 'branch')
      return [
        branchReports?.branch_attendance_summary?.completed,
        branchReports?.branch_attendance_summary?.pending,
        branchReports?.branch_attendance_summary?.dropped,
        branchReports?.branch_attendance_summary?.swapped,
      ];
    else if (reportsFilter === 'department')
      return [
        departmentReports?.dept_attendance_summary?.completed,
        departmentReports?.dept_attendance_summary?.pending,
        departmentReports?.dept_attendance_summary?.dropped,
        departmentReports?.dept_attendance_summary?.swapped,
      ];
    else return [];
  })();

  const percentage = (() => {
    if (reportsFilter === 'organisation')
      return organisationReports?.organisation_attendance_summary?.attendance;
    else if (reportsFilter === 'branch')
      return branchReports?.branch_attendance_summary?.attendance;
    else if (reportsFilter === 'department')
      return departmentReports?.dept_attendance_summary?.attendance;
    else return '-';
  })();

  const data = {
    labels: ['Completed', 'Pending', 'Dropped', 'Swapped'],
    datasets: [
      {
        data: dispData,
        backgroundColor: ['#107e76', '#F69520', '#DC4731', '#0E86D4'],
        borderColor: ['#107e76', '#F69520', '#DC4731', '#0E86D4'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card m={4} w={'100%'}>
      <CardHeader>
        <Text as='b'>Attendance</Text>
      </CardHeader>
      <CardBody>
        <Box p={2} align='center' h={'38vh'}>
          <Pie data={data} />

          <Text align='left'>{`${percentage ?? '-'}%`}</Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default AttendanceReport;
