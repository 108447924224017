import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { employeeTypeUrl } from '../../../../application/services/http_endpoints';
import { handleError } from '../../../../application/services/http_service';
import EmploymentTypeModal from './components/modals/EmploymentTypeModal';

const EmploymentTypePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isRolesLoading, setRolesLoading] = useState(false);

  const { access_token } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);
  const { decodedRoles, roles } = useSelector((state) => state.roles);

  const [employeeTypeList, setEmployeeTypeList] = useState([]);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState({});
  const [isEdit, setEdit] = useState(false);

  async function fetchEmploymentType() {
    setRolesLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      'organisation-id': organisation?.id ?? '',
    };
    axios
      .get(employeeTypeUrl, {
        params: { org_id: organisation?.id ?? '' },
        headers: headers,
      })
      .then((response) => {
        setRolesLoading(false);
        setEmployeeTypeList(response.data.items);
      })
      .catch((err) => {
        setRolesLoading(false);
        handleError(
          err,
          'Failed to fetch employment type.',
          fetchEmploymentType,
          access_token,
          dispatch,
          toast,
          navigate
        );
      });
  }

  useEffect(() => {
    // Trigger the fetch
    fetchEmploymentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (isEdit) => {
    setEdit(isEdit);
    onOpen();
  };

  return (
    <div>
      <Flex m={8} align='center'>
        <Container>
          <Heading as='h4' size='md' mb={2}>
            Employment type
          </Heading>
          <Text fontSize='sm'>Add and review records from your team</Text>
        </Container>

        <Spacer />
        <Button
          noOfLines={1}
          isDisabled={
            organisation?.id === undefined ||
            organisation?.id === '' ||
            !roles?.[organisation?.id]?.includes('Roles.create')
          }
          onClick={() => {
            setSelectedEmploymentType({});
            openModal(false);
          }}
          backgroundColor='#107e76'
          textColor={'white'}
        >
          Create
        </Button>
      </Flex>

      {/* employment type modal */}
      <EmploymentTypeModal
        fetchEmploymentType={fetchEmploymentType}
        selectedEmploymentType={selectedEmploymentType}
        isEdit={isEdit}
        isOpen={isOpen}
        onClose={onClose}
      />

      {isRolesLoading ? (
        <Center>
          <BounceLoader color={'#107e76'} loading={isRolesLoading} size={80} />
        </Center>
      ) : (
        <div>
          <TableContainer m={8} backgroundColor={'white'}>
            <Table size='md'>
              <Thead>
                <Tr>
                  <Th>Category</Th>
                  <Th>Type</Th>
                  <Th>Work hrs/ month</Th>
                  <Th>Earning/ hr</Th>
                  <Th>Date Created</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {employeeTypeList?.length > 0 ? (
                  employeeTypeList?.map((item) => (
                    <Tr key={item.id}>
                      {/* category */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {item.employee_category}
                        </Text>
                      </Td>
                      {/* type */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {item.employee_type}
                        </Text>
                      </Td>
                      {/* work hours */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {item.expected_monthly_work_hours}
                        </Text>
                      </Td>
                      {/* hourly rate */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {item.hourly_rate.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'KES',
                          })}
                        </Text>
                      </Td>
                      {/* created */}
                      <Td>
                        <Text textColor='black' fontSize='sm'>
                          {moment(item.created_at).format('MMM DD, YYYY')}
                        </Text>
                        <Text fontSize='sm'>
                          {moment
                            .utc(item.created_at)
                            .local()
                            .format('HH:mm a')}
                        </Text>
                      </Td>

                      {/* actions */}
                      <Td>
                        <Button
                          backgroundColor='#DAF2F0'
                          textColor='#107e76'
                          size='sm'
                          onClick={() => {
                            setSelectedEmploymentType(item);
                            openModal(true);
                          }}
                          mr={4}
                        >
                          Edit
                        </Button>
                        {/* <Button
                          mr={5}
                          textColor='#C80024'
                          variant='outline'
                          backgroundColor={'#F8E0E5'}
                          size='sm'
                          onClick={() => ''}
                        >
                          Deactivate
                        </Button> */}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr className='no-record'>
                    <Td colSpan='5'>
                      <Text fontSize='sm'>No records found</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default EmploymentTypePage;
