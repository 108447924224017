import './ReportsTable.scss';

const ReportsTable = () => {
  return (
    <div className='reportsTable'>
      <table>
        <tbody>
          <tr>
            <th className='user-name'>REPORTS</th>
            <th></th>
          </tr>

          <tr>
            <td className='contentCell'>
              <div className='contentCellHeader'>
                <div className="contentCellHeaderAvatar">MS</div>
                <div className='contentCellHeaderDesc'>
                  <h4 className='name'>
                    Malia Sela <span>(Admin)</span>
                  </h4>
                  <p className='email'>msela@example.com</p>
                </div>
              </div>
              <h4 className='contentCellDesc'>
                Requested to join <span>Theater(FD)</span> rota on Tuesday 23rd
                April, 2022 that has 12 people, click to request.
              </h4>
            </td>
            <td className='dateCell'>12 April 2022</td>
          </tr>
          <tr>
            <td className='contentCell'>
              <div className='contentCellHeader'>
                <div className="contentCellHeaderAvatar">MS</div>
                <div className='contentCellHeaderDesc'>
                  <h4 className='name'>
                    Malia Sela <span>(Admin)</span>
                  </h4>
                  <p className='email'>msela@example.com</p>
                </div>
              </div>
              <h4 className='contentCellDesc'>
                Requested to join <span>Theater(FD)</span> rota on Tuesday 23rd
                April, 2022 that has 12 people, click to request.
              </h4>
            </td>
            <td className='dateCell'>12 April 2022</td>
          </tr>
          <tr>
            <td className='contentCell'>
              <div className='contentCellHeader'>
                <div className="contentCellHeaderAvatar">MS</div>
                <div className='contentCellHeaderDesc'>
                  <h4 className='name'>
                    Malia Sela <span>(Admin)</span>
                  </h4>
                  <p className='email'>msela@example.com</p>
                </div>
              </div>
              <h4 className='contentCellDesc'>
                Requested to join <span>Theater(FD)</span> rota on Tuesday 23rd
                April, 2022 that has 12 people, click to request.
              </h4>
            </td>
            <td className='dateCell'>12 April 2022</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReportsTable;
